<template>
	<form class="login-form" @submit="sendForm">
		<TextField
			class="login"
			:partof="
				state === FORM_STATES.LOGIN_INPUT || state === FORM_STATES.LOGIN_LOADING ? 'nothing' : 'top'"
			size="big"
			:placeholder="
				state === FORM_STATES.REGISTER_INPUT ? (
					secondaryMode === SECONDARY_MODES.LOGIN ? 'Email' : 'Логин'
				) : 'Логин или email'
			"
			type="text"
			v-model="login"
			:disabled="state === FORM_STATES.LOGIN_LOADING || state === FORM_STATES.PASSWORD_LOADING || state === FORM_STATES.REGISTER_LOADING"
			autofocus
			autocomplete="username"
			:hint="
				loginHint === HINTS.EMPTY ? 'Введите что-нибудь' :
				loginHint === HINTS.USER_EXISTS ? 'Пользователь найден' :
				loginHint === HINTS.REGISTER ? 'Такого пользователя ещё нет' :
				loginHint === HINTS.USE_GOOGLE ? 'Вы зарегистрированы через Google' :
				null
			"
			:hintDesign="
				loginHint === HINTS.EMPTY ? 'error' :
				loginHint === HINTS.USER_EXISTS ? 'action' :
				loginHint === HINTS.USE_GOOGLE ? 'action' :
				'default'
			"
			@input="state = FORM_STATES.LOGIN_INPUT"
			ref="firstField"
		/>

		<TextField
			v-if="state === FORM_STATES.REGISTER_INPUT || state === FORM_STATES.REGISTER_LOADING"
			class="secondary-field"
			partof="middle"
			size="big"
			:placeholder="secondaryMode === SECONDARY_MODES.LOGIN ? 'Логин' : 'Email'"
			type="text"
			v-model="secondary"
			:disabled="state === FORM_STATES.REGISTER_LOADING"
			:autocomplete="secondaryMode === SECONDARY_MODES.LOGIN ? 'username' : 'email'"
			:hint="
				secondaryHint === HINTS.EMPTY ? 'Введите что-нибудь' :
				secondaryHint === HINTS.WRONG ? 'Email неправильный' :
				secondaryHint === HINTS.USER_EXISTS ? 'Логин занят' :
				null
			"
			:hintDesign="
				secondaryHint === HINTS.EMPTY ? 'error' :
				secondaryHint === HINTS.WRONG ? 'error' :
				secondaryHint === HINTS.USER_EXISTS ? 'error' :
				'default'
			"
			@keydown="$event.which === 13 && $refs.passwordField && $refs.passwordField.focus()"
			ref="secondaryField"
		/>

		<TextField
			v-if="state === FORM_STATES.PASSWORD_INPUT || state === FORM_STATES.REGISTER_INPUT || state === FORM_STATES.PASSWORD_LOADING || state === FORM_STATES.REGISTER_LOADING"
			class="password"
			partof="bottom"
			size="big"
			placeholder="Пароль"
			type="password"
			v-model="password"
			:disabled="state === FORM_STATES.PASSWORD_LOADING || state === FORM_STATES.REGISTER_LOADING"
			autocomplete="current-password"
			:hint="
				passwordHint === HINTS.EMPTY ? 'Введите что-нибудь' :
				passwordHint === HINTS.WRONG ? 'Неправильный пароль' :
				null
			"
			:hintDesign="
				passwordHint === HINTS.EMPTY ? 'error' :
				passwordHint === HINTS.WRONG ? 'error' :
				'default'
			"
			@keydown="$event.which === 13 && sendForm()"
			ref="passwordField"
		/>

		<div style="width: 100%; height: 1px; background: #ececec; margin-top: 25px; margin-bottom: 15px" />

		<Button
			design="action"
			class="send-button"
			size="big"
			@click="sendForm"
			:showSpinner="
				state === FORM_STATES.LOGIN_INPUT ? false :
				state === FORM_STATES.LOGIN_LOADING ? true :
				state === FORM_STATES.PASSWORD_INPUT ? false :
				state === FORM_STATES.PASSWORD_LOADING ? true :
				state === FORM_STATES.REGISTER_INPUT ? false :
				state === FORM_STATES.REGISTER_LOADING ? true :
				false
			"
			:showContent="
				state === FORM_STATES.LOGIN_INPUT ? true :
				state === FORM_STATES.LOGIN_LOADING ? false :
				state === FORM_STATES.PASSWORD_INPUT ? true :
				state === FORM_STATES.PASSWORD_LOADING ? true :
				state === FORM_STATES.REGISTER_INPUT ? true :
				state === FORM_STATES.REGISTER_LOADING ? true :
				state === FORM_STATES.USE_GOOGLE ? true :
				false
			"
			:disabled="state === FORM_STATES.USE_GOOGLE"
		>
			{{
				state === FORM_STATES.LOGIN_INPUT ? 'Войти или зарегистрироваться' :
				state === FORM_STATES.LOGIN_LOADING ? '' :
				(state === FORM_STATES.PASSWORD_INPUT || state === FORM_STATES.PASSWORD_LOADING) ? 'Войти' :
				(state === FORM_STATES.REGISTER_INPUT || state === FORM_STATES.REGISTER_LOADING) ? 'Зарегистрироваться' :
				'Продолжить'
			}}
		</Button>
		<Button
			design="ghost"
			class="send-button"
			size="big"
			@click="$emit('cancel')"
		>Закрыть</Button>

		<!-- <Button
			design="link"
			class="try-button"
			@click="testUser"
		>
			Попробовать без регистрации
		</Button> -->
		<!-- <Button
			design="link"
			class="try-button"
			@click="testUser"
		>
			Авторизация с помощью Google
		</Button> -->
	</form>
</template>

<script>
import TextField from '@/components/TextField';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { isUserExist, login, register, isEmail } from '@/lib/api';

const FORM_STATES = {
	LOGIN_INPUT: 'login_input',
	LOGIN_LOADING: 'login_loading',
	PASSWORD_INPUT: 'password_input',
	PASSWORD_LOADING: 'authorization',
	REGISTER_INPUT: 'register_input',
	REGISTER_LOADING: 'registration',
	USE_GOOGLE: 'use_google'
};

const HINTS = {
	EMPTY: 'empty',
	USER_EXISTS: 'user_exists',
	REGISTER: 'register',
	WRONG: 'wrong',
	USE_GOOGLE: 'use_google'
};

const SECONDARY_MODES = {
	EMAIL: 'email',
	LOGIN: 'login'
};

export default {
	name: 'LoginForm',

	data() {
		return {
			FORM_STATES,
			HINTS,
			SECONDARY_MODES,

			state: FORM_STATES.LOGIN_INPUT,

			login: '',
			loginHint: null,

			password: '',
			passwordHint: null,

			secondary: '',
			secondaryMode: SECONDARY_MODES.EMAIL,
			secondaryHint: null
		};
	},

	inject: ['i18n'],

	methods: {
		sendForm: function(event) {
			event && event.preventDefault();
			if (this.state === FORM_STATES.LOGIN_INPUT) {
				this.sendLogin();
			} else if (this.state === FORM_STATES.PASSWORD_INPUT) {
				this.sendPassword();
			} else if (this.state === FORM_STATES.REGISTER_INPUT) {
				this.sendRegister();
			}
		},

		sendLogin: async function() {
			if (this.state === FORM_STATES.LOGIN_LOADING) {
				return;
			}

			this.loginHint = null;
			if (this.login === '') {
				this.loginHint = HINTS.EMPTY;
				return;
			}

			this.state = FORM_STATES.LOGIN_LOADING;
			const userProvider = await isUserExist(this.login);
			if (userProvider === 'google') {
				this.state = FORM_STATES.USE_GOOGLE;
				this.loginHint = HINTS.USE_GOOGLE;
			} else if (userProvider) {
				this.state = FORM_STATES.PASSWORD_INPUT;
				this.loginHint = HINTS.USER_EXISTS;
				this.focusPasswordField();
			} else {
				this.state = FORM_STATES.REGISTER_INPUT;
				this.loginHint = HINTS.REGISTER;
				if (isEmail(this.login)) {
					this.secondaryMode = SECONDARY_MODES.LOGIN;
				} else {
					this.secondaryMode = SECONDARY_MODES.EMAIL;
				}
				this.focusSecondaryField();
			}
		},

		sendPassword: async function() {
			if (this.state === FORM_STATES.PASSWORD_LOADING) {
				return;
			}

			this.passwordHint = null;
			if (this.password === '') {
				this.passwordHint = HINTS.EMPTY;
				this.focusPasswordField();
				return;
			}

			this.state = FORM_STATES.PASSWORD_LOADING;
			try {
				const user = await login(this.login, this.password);
				this.loginAs(user);
			} catch (e) {
				this.state = FORM_STATES.PASSWORD_INPUT;
				this.passwordHint = HINTS.WRONG;
			}
		},

		sendRegister: async function() {
			if (this.state === FORM_STATES.REGISTER_LOADING) {
				return;
			}

			let valid = true;
			this.secondaryHint = null;
			this.passwordHint = null;
			// todo: добавить валидацию имейла
			if (this.secondary === '') {
				this.secondaryHint = HINTS.EMPTY;
				this.focusSecondaryField();
				valid = false;
			} else if (this.secondaryMode === SECONDARY_MODES.EMAIL && !isEmail(this.secondary)) {
				this.secondaryHint = HINTS.WRONG;
				this.focusSecondaryField();
				valid = false;
			}
			if (this.password === '') {
				this.passwordHint = HINTS.EMPTY;
				valid && this.focusPasswordField();
				valid = false;
			}
			if (this.secondaryMode === SECONDARY_MODES.LOGIN && this.secondary !== '') {
				const checkIfUserExists = await isUserExist(this.secondary);
				if (checkIfUserExists) {
					this.secondaryHint = HINTS.USER_EXISTS;
					valid && this.focusSecondaryField();
					valid = false;
				}
			}
			if (!valid) {
				return;
			}

			this.state = FORM_STATES.REGISTER_LOADING;
			try {
				const username = this.secondaryMode === SECONDARY_MODES.LOGIN ? this.secondary : this.login;
				const email = this.secondaryMode === SECONDARY_MODES.EMAIL ? this.secondary : this.login;
				const user = await register(username, email, this.password);
				this.loginAs(user);
			} catch (e) {
				this.state = FORM_STATES.REGISTER_INPUT;
				// note: may be email error
				this.passwordHint = HINTS.WRONG;
			}
		},

		focusFirstField: function() {
			this.$nextTick(() => {
				this.$refs.firstField && this.$refs.firstField.focus();
			});
		},

		focusPasswordField: function() {
			this.$nextTick(() => this.$refs.passwordField.focus());
		},

		focusSecondaryField: function() {
			this.$nextTick(() => this.$refs.secondaryField.focus());
		},

		loginAs: function(user) {
			location.href = location.href;
			this.$store.commit('user/setUser', user);
		},

		testUser: function() {
			// location.href = '/course/3/training';
			location.href = '/api/auth/google';
			// this.loginAs(user);
		/*	this.$store.commit('user/setUser', {
				id: -1,
				login: 'test',
				username: 'Test user'
			}); */
		}
	},

	components: {
		TextField,
		Button,
		Modal,
	},
};
</script>

<style lang="stylus">
.login-form
	display inline-grid
	grid-template-rows auto auto auto auto auto
	width 300px
	.send-button
		margin-top 12px
	.try-button
		margin-top 5px

.register-modal-content
	padding 40px
	p
		margin 10px 0
	.register-modal-content-buttons
		margin-top 25px
		.button
			display block
		.button:first-child
			margin-right 5px
</style>
