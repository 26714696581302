<template>
	<div :class="[
		'page',
		// zeroPadding && 'zero-padding',
		mode === 'with-sidebar' && 'with-sidebar',
		mode === 'with-header' && 'with-header',
		center && 'center',
		loading && 'loading',
		useDocumentHeight && 'useDocumentHeight'
	]">
		<div>
			<!-- <div class="page-mascot-container" v-if="useMascot">
				<Mascot />
			</div> -->
			<div class="page-container" v-if="mode === 'with-header'">
				<Header mode="white">
					<Logo />
				</Header>

				<div class="page-with-header-content">
					<Spinner v-if="loading" size="big" />
					<slot v-else />
				</div>
			</div>
			<div class="page-container" v-else>
				<Sidebar :selectedItem="selectedItem" />
				<HorizontalMenu :selectedItem="selectedItem" />

				<div class="page-with-sidebar-content">
					<Header mode="embed">
						<h1 class="page-name">{{ pageName }}</h1>
					</Header>
					<div class="page-with-sidebar-content-container">
						<Spinner v-if="loading" size="big" />
						<slot v-else />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/Header';
import Spinner from '@/components/Spinner';
import Mascot from '@/views/Mascot';
import HorizontalMenu from '@/components/HorizontalMenu.vue';
import Sidebar from '@/components/Sidebar.vue';
import Logo from '@/components/Logo.vue';

export default {
	name: 'Page',

	props: {
		mode: {
			validator: val => ['with-header', 'with-sidebar'].includes(val),
			default: 'with-header',
		},
        selectedItem: {
            type: String,
        },
		pageName: String,
		zeroPadding: {
			type: Boolean,
			default: false,
		},
		center: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		useMascot: {
			type: Boolean,
			default: true
		},
		useDocumentHeight: {
			type: Boolean,
			default: false
		}
	},

	components: {
		Logo,
		Header,
		Spinner,
		Mascot,
		HorizontalMenu,
		Sidebar
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

// .page-mascot-container
// 	position absolute
// 	z-index 1

.page.with-header
	.page-with-header-content
		padding-top 75px

.page-container
	width 100%

.page.loading .page-with-sidebar-content-container,
.page.loading .page-with-header-content
	text-align center
	padding-top 100px

.page.center
	height 100vh
	display grid
	justify-content center
	align-items center

.page.useDocumentHeight .page-container
	height calc(100% - 75px)

@media screen and (orientation: landscape) {
	.page.with-sidebar {
		& >>> .horizontal-menu {
			display none
		}
		.page-with-sidebar-content {
			padding-left 200px
		}
		.page-name {
			font-size 24px
			line-height 55px
			margin-left 32px
		}
	}
}

@media screen and (orientation: portrait) {
	.page.with-sidebar {
		& >>> .sidebar {
			display none
		}
		.page-name {
			font-size 24px
			line-height 55px
			margin-left 32px
		}
	}
}
</style>
