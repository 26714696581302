import Vue from 'vue';
import VueRouter from 'vue-router';
import {
	MainPage,
	// UserPage,
	// PageLearnedWords,
	MyWords,
	CourseListAllPage,
	CourseTreePage,
	CourseShowPage,
	CourseTrainingPage,
	CourseEditPage,
	FavoritesPage,
	AdminPage,
} from '@/views';

Vue.use(VueRouter);

const routes = [
	{
		path: '/admin',
		name: 'AdminPage',
		component: AdminPage,
	},
	{
		path: '/',
		name: 'Main',
		component: MainPage,
	},
	/*
	{
		path: '/user/:username',
		name: 'UserPage',
		component: UserPage
	}, */
	{
		path: '/my-words',
		name: 'MyWords',
		component: MyWords
	},
	{
		path: '/favorites',
		name: 'Favorites',
		component: FavoritesPage,
	},
	{
		path: '/course/',
		name: 'CourseListAllPage',
		component: CourseListAllPage,
	},

	{
		path: '/course/create',
		name: 'CourseEditPage',
		component: CourseEditPage,
	},
	// {
	// 	path: '/course/:courseName',
	// 	name: 'CourseShowPage',
	// 	component: CourseShowPage,
	// },
	{
		path: '/course/:courseId',
		name: 'CourseTreePage',
		component: CourseTreePage,
	},
	{
		path: '/course/:courseName/training',
		name: 'CourseTraining',
		component: CourseTrainingPage,
	},
	{
		path: '/course/:courseName/edit',
		name: 'CourseEditPage',
		component: CourseEditPage,
	},
	{
		path: '/:category/:topic',
		name: 'MainCategoryAll',
		component: MainPage,
	},
	{
		path: '/:category',
		name: 'MainCategory',
		component: MainPage,
	},
];

// 	{
// 	path: '/about',
// 	name: 'About',
// 	// route level code-splitting
// 	// this generates a separate chunk (about.[hash].js) for this route
// 	// which is lazy-loaded when the route is visited.
// 	component: () =>
// 		import(/* webpackChunkName: "about" */ '../views/LoginPage.vue'),
// }

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
