import axios from 'axios';

// todo: if I get 'must be logged in' error to api, I remove cookies and reload the page

// const backend = 'http://localhost:3000';
const backend = location.href.includes('localhost') ? 'http://localhost:3000' : location.href.split('/').slice(0, 3).join('/');

const get = async url => {
	try {
		const response = await axios.get(`${backend}${url}`, {
			withCredentials: true,
		});

		console.log(`GET ${url} -> (${response.status})`, response.data);

		if (response.status === 401) {
			location.reload();
		}
		if (response.status === 200) {
			return response.data;
		}
		throw response.data;
	} catch (e) {
		console.error(`GET ${url} -> `, e);
		throw e;
	}
};

const post = async (url, body) => {
	try {
		const response = await axios.post(`${backend}${url}`, body, {
			withCredentials: true,
		});

		console.log(
			`POST ${url} (${JSON.stringify(body)}) -> (${response.status})`,
			response.data
		);

		if (response.status === 401) {
			location.reload();
		}
		if (response.status === 200 || response.status === 201) {
			return response.data;
		}
		throw response.data;
	} catch (e) {
		console.log(`POST ${url} (${JSON.stringify(body)}) -> `, e);
		throw e;
	}
};

const put = async (url, body) => {
	try {
		const response = await axios.put(`${backend}${url}`, body, {
			withCredentials: true,
		});

		console.log(
			`PUT ${url} (${JSON.stringify(body)}) -> (${response.status})`,
			response.data
		);

		if (response.status === 401) {
			location.reload();
		}
		if (response.status === 200) {
			return response.data;
		}
		throw response.data;
	} catch (e) {
		console.log(`PUT ${url} (${JSON.stringify(body)}) -> `, e);
		throw e;
	}
};
const objectToQueryString = params => {
	return Object.keys(params)
		.filter(key => params[key] !== undefined)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
		.join('&');
};

// users
export const login = (username, password) =>
	post(`/api/auth/login`, { username, password });

export const register = (username, email, password) =>
	post(`/api/auth/register`, { username, email, password });

export const isUserExist = login => get(`/api/auth/is-user-exist?login=${login}`);

export const isEmail = email => email.includes('@');

export const logout = (username, password) =>
	post(`/api/auth/logout`, { username, password });

// words
const topicIds = {
	topic: 0,
	content: 1,
	compare: 2
};

export const getUserCourses = async ([category, topic, level, showCommunityCourses]) => {
	const query = {
		langSource: 0,
		langDest: 1,
		topicId: topicIds[topic],
		levelId: level === -1 ? undefined : level,
		showCommunityCourses,
	};
	const result = await get(`/api/course/list/${category}?${objectToQueryString(query)}`);
	// todo: удалить когда будет понятно, что новая ручка работает хорошо
	/* if (category === 'favorite') {
		const coursesData = {};
		// тут обеспечивается сохранение порядка, в котором они пришли с бэка
		const courses = [];
		result.forEach(course => {
			if (!coursesData[course.id]) {
				coursesData[course.id] = course;
				coursesData[course.id].unknownWordsCount = 0;
				coursesData[course.id].knownWordsCount = 0;
				courses.push(course);
			}
			if (course.unknown) {
				coursesData[course.id].unknownWordsCount = course.count;
			} else {
				coursesData[course.id].knownWordsCount = course.count;
			}
		});
		return courses;
	} */
	return result;
};

export const addCourseToFavorite = async (courseId, value) => put(`/api/course/favorite/`, {courseId, value});
export const getCourseById = async (id) => get(`/api/course/${id}`);
export const getCourse = async () => {};
export const createCourse = async data => {
	if (data.topic) {
		data.categoryId = topicIds[data.topic];
		delete data.topic;
	}
	return post('/api/course/', data);
}
export const editCourse = async (id, data) => {
	if (data.topic) {
		data.categoryId = topicIds[data.topic];
		delete data.topic;
	}
	return put(`/api/course/${id}/`, data);
}
export const editCourseWords = async (id, data) => put(`/api/course/${id}/words`, data);

const sortWords = (words, q) => {
	return words.sort((a, b) => {
		if (a.text === q && b.text === q) {
			return a.sentencesCount - b.sentencesCount;
		}
		if (a.text === q) {
			return -1;
		}
		if (b.text === q) {
			return 1;
		}
		if (a.description && !b.description) {
			return -1;
		}
		if (b.description && !a.description) {
			return 1;
		}
		return a.text.length - b.text.length || a.sentencesCount - b.sentencesCount;
	});
};

// export const getCourseWithWords = async id => get(`/api/word/get-by-course/${id}`);
export const searchWords = async (q, langPair, hiddenPass) => {
	const langSource = langPair ? langPair.langSource : 0;
	const langDest = langPair ? langPair.langDest : 1;
	const words = !hiddenPass ?
		await post(`/api/word/search?q=${q.trim()}&langSource=${langSource}&langDest=${langDest}`) :
		await post(`/api/admin/search-words?q=${q.trim()}&langSource=${langSource}&langDest=${langDest}`, {
			id: hiddenPass
		});
	return sortWords(words, q);
};
export const searchManyWords = async (q) => post('/api/word/search-many', { q, langSource: 0, langDest: 1 });
export const searchWordsGuest = async (q) => sortWords(await post(`/api/guest/search-words?q=${q.trim()}`));
export const updateWordLastDate = async (wordId, complete) => put(`/api/training/repeat-date/`, { wordId, complete })
export const courseGet10Training = async courseId => post(`/api/training/get-training-words/${courseId}`);
export const courseGet10TrainingPackageScenario = async (courseId, modifier) => post(`/api/training/get-training-words/scenarios/package/${courseId}?modifier=${modifier}`);

export const getTrainingWordsGuest = async courseId => post(`/api/training/get-training-words-guest/${courseId}`);
export const complain = async (complainData) => post(`/api/complaint/`, complainData);
export const getUserTrainingInfo = async () => get(`/api/training/get-user-info/`);
export const getLearnedWords = async () => get(`/api/training/get-learned-words/`);
export const getContextExplanation = async (wordId, sentenceId, inputWord) => post(`/api/training/get-explanation`, { type: 0, wordId, sentenceId, inputWord: inputWord === '' ? undefined : inputWord });
export const getOriginsExplanation = async (wordId) => post(`/api/training/get-explanation`, { type: 1, wordId });
export const createWord = async (q) => {
	return await post(`/api/word/create-word`, { q, langSource: 0, langDest: 1 });
}

export const getFavorites = async () => get('/api/bookmarks/list');
export const addFavoriteCourse = async (courseId) => post('/api/bookmarks/course', { courseId, value: true });
export const removeFavoriteCourse = async (courseId) => post('/api/bookmarks/course', { courseId, value: false });
export const addFavoriteExplanation = async (explanationId) => post('/api/bookmarks/explanation', { explanationId, value: true });
export const removeFavoriteExplanation = async (explanationId) => post('/api/bookmarks/explanation', { explanationId, value: false });

// export const addSecondsToLearning = async (secondsToAdd) => post(`/api/training/user-statistics`, secondsToAdd)
export const addSecondsToLearning = async (secondsToAdd) => navigator.sendBeacon(`${backend}/api/training/user-statistics`, secondsToAdd)

export const pocGetTrainingAi = async (words, languageNative, languageTarget) => post('/api/training/poc-get-training-words-ai', { words, languageNative, languageTarget })

export const adminCreateSentence = async (data) => post(`/api/admin/create-sentence`, data);
export const adminCreateWord = async (data) => post(`/api/admin/create-word`, data);
export const adminUpdateWord = async (data) => post(`/api/admin/update-word/`, data);
export const adminCreateWordSentenceLink = async (data) => post(`/api/admin/create-sentence-word-link`, data);
export const adminUpdateWordLinks = async (data) => post(`/api/admin/update-word-links`, data);
export const adminRecalcWordsCounts = async (data) => post(`/api/admin/recalc-words-sc`, data);
export const adminSearchSentences = async (data) => post(`/api/admin/search-sentences`, data);
export const adminFindLinks = async (data) => post(`/api/admin/find-links`, data);
export const adminReverso = async (data) => post(`/api/admin/reverso`, data);

export const getWordComments = async (wordId) => get(`/api/comment/${wordId}`);
export const addWordComment = async (data) => post(`/api/comment/`, data);
