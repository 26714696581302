import * as XLSX from 'xlsx';
// import JSZip from 'jszip';

export async function readFileData(file) {
  const fileType = getFileType(file);
  
  switch (fileType) {
    case 'xlsx':
    case 'csv':
      return await readExcelOrCSV(file);
    // case 'numbers':
    //   return await readNumbers(file);
    default:
      throw new Error('Unsupported file type');
  }
}

function getFileType(file) {
  const extension = file.name.split('.').pop().toLowerCase();
  return extension;
}

async function readExcelOrCSV(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      resolve(jsonData);
    };
    
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}
