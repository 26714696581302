<template>
	<Button
		:style="style"
		:size="size"
		:design="design"
		:showSpinner="showSpinner"
		:showContent="showContent"
		:disabled="disabled"
		@click="$emit('click', $event)"
	>
		<slot />
	</Button>
</template>

<script>
import Button from '@/components/Button';

export default {
	name: 'ButtonProgress',
	props: {
		progress: {
			type: Number,
			required: true,
		},

		size: {
			validator: val => ['medium', 'big'].includes(val),
			default: 'medium',
		},
		design: {
			validator: val => ['default', 'action'].includes(val),
			default: 'default',
		},
		showSpinner: {
			type: Boolean,
			default: false,
		},
		showContent: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		style: function() {
			const progress = Math.floor(this.progress * 100);
			const color1 = this.design === 'action' ? '#5FAA44' : '';
			const color2 = this.design === 'action' ? 'rgb(125, 200, 98)' : '';
			return {
				backgroundImage: `linear-gradient(90deg, ${color1} ${progress -
					1}%, ${color2} ${progress}%)`,
			};
		},
	},

	components: {
		Button,
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";
</style>
