<template>
	<Page :loading="loading">
		<div class="page-container">
			<h1>{{ data.title }}</h1>
			<TableCards :data="words" />
		</div>
	</Page>
</template>

<script>
import { mapState } from 'vuex';

import Page from '@/components/Page';
import TableCards from '@/components/TableCards';

function getExample(sentence, word) {
	const index1 = sentence.toLowerCase().indexOf(word.toLowerCase());
	const index2 = index1 + word.length;
	return [
		sentence.slice(0, index1),
		sentence.slice(index1, index2),
		sentence.slice(index2),
	];
}

export default {
	name: 'CourseShowPage',

	computed: mapState({
		data: state => state.courseShow.data || {},
		loading: state => state.courseShow.loading,

		words: state => {
			return (state.courseShow.data?.Words || []).map(word => ({
				id: word.id,
				question: word.word,
				answer: word.description,
				example: getExample(word.sentence.sentence, word.word),
				memesCount: 0,
			}));
		},
	}),

	mounted: async function() {
		const courseName = this.$route.params.courseName;
		const user = this.$store.state.user;
		await this.$store.dispatch('courseShow/load', {
			authorId: user.id,
			courseId: courseName,
		});
	},

	components: {
		Page,
		TableCards,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.page-container
	text-align center
</style>
