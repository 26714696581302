var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
	'text-field-container',
	'partof_' + _vm.partof,
	'size_' + _vm.size,
	'design_' + _vm.design,
	'placeholder_' + _vm.placeholderStyle,
	_vm.rightIcon && 'right-icon_' + _vm.rightIcon
]},[_c('input',{ref:"input",class:['text-field', _vm.textFieldClass],attrs:{"placeholder":_vm.placeholder,"type":_vm.type,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"autocomplete":_vm.autocomplete,"autocapitalize":_vm.autocapitalize,"maxlength":_vm.maxlength},domProps:{"value":_vm.value},on:{"input":function($event){_vm.$emit('input', _vm.modifiersProcess($event.target.value))},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)}}}),(_vm.hint)?_c('div',{class:['text-field-hint', 'design_' + _vm.hintDesign]},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e(),(_vm.$slots.righticon)?_c('div',{staticClass:"slot-righticon"},[_vm._t("righticon")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }