<template>
	<Button
		design="google-auth"
		size="big"
		@click="click"
	>
		{{ i18n('login', 'authorizeGoogle') }}
	</Button>
</template>

<script>
import Button from '@/components/Button';

export default {
	name: 'GoogleSignInButton',
	inject: ['i18n'],

	methods: {
		click: function() {
			location.href = '/api/auth/google';
		}
	},

	components: {
		Button,
	}
};
</script>

<style lang="stylus" scoped>
</style>
