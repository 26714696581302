export const debounceAfter = function(fn, delay) {
	let timeoutId = null;
	return function() {
		return new Promise((resolve, reject) => {
			if (timeoutId !== null) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				timeoutId = null;
				fn.apply(this, arguments).then(resolve, reject);
			}, delay);
		});
	};
};

export function unique(arr) {
	return [...new Set(arr)];
}

export function casify(str, mask) {
	let newStr = '';
	for (let i = 0; i < str.length; i++) {
		if (mask[i].toUpperCase() === mask[i]) {
			newStr += str[i].toUpperCase();
		} else {
			newStr += str[i].toUpperCase();
		}
	}
	return newStr;
}

export const second = 1000;
export const minute = 60 * second;
export const hour = 60 * minute;
export const day = 24 * hour;

export function rand(min, max) {
	return Math.floor(Math.random() * (max - min)) + min;
}

export function randPick(array) {
	return array[Math.floor(Math.random() * array.length)];
}