<template>
	<span v-if="content">
		<span
			v-for="badge in badges"
			:key="badge"
			:class="['part-of-speech-badge', 'word-type', badge, 'size_' + size]"
		>{{ badge }}</span>
	</span>
</template>

<script>
export default {
	name: 'PartOfSpeechBadge',
	props: {
		content: {
			type: String,
			required: true
		},
		size: {
			type: String,
			default: 'medium',
			validator: v => ['medium', 'big'].includes(v)
		}
	},
	computed: {
		badges: function() {
			return this.content.split('|');
		}
	}
};
</script>

<style lang="stylus" scoped>
noun-color = #FC9213
verb-color = #E4341E
adjective-color = #5FAA44
adverb-color = #9579d1

.word-type
	line-height 20px
	display inline-block
	border solid 1px black
	color black
	font-size 12px
	font-weight 400
	padding 0 8px
	border-radius 6px

/*
noun-color = #fe7968
verb-color = #024f94
adjective-color = #4a7f2f
adverb-color = #9579d1

.word-type
	font-size 7pt
	color black
	border solid 1px black
	padding 2px
	display inline-block
	border-radius 3px
	line-height 7pt
	vertical-align top
	&.verb
		color verb-color
		border-color verb-color
	&.noun
		color noun-color
		border-color noun-color
	&.adjective
		color adjective-color
		border-color adjective-color
	&.adverb
		color adverb-color
		border-color adverb-color */

.word-type.verb
	color verb-color
	border-color verb-color
.word-type.noun
	color noun-color
	border-color noun-color
.word-type.adjective
	color adjective-color
	border-color adjective-color
.word-type.adverb
	color adverb-color
	border-color adverb-color

.word-type + .word-type
	margin-left 5px

.word-type.size_big
	font-size 14px
	line-height 120%
	padding 3px 12px
</style>
