<template>
	<!-- <div
		:class="['course-block', `design_${design}`, avatarUrl ? 'with-avatar' : 'without-avatar', 'v1']"
		@click="$emit('click', $event)"
	>
		<div class="corner-icon" v-if="mode === 'author'" @click="$emit('edit'); $event.stopPropagation()">
			<img :src="icons.edit" />
		</div>
		<div class="corner-icon" v-else @click="$emit('show'); $event.stopPropagation()">
			<img :src="icons.show" />
		</div>
		<img src="../assets/add-icon.svg" v-if="design === 'create-new'" class="avatar" />
		<div class="avatar" :style='`background-image: url("${avatarUrl}")`' v-else />
		<div class="title">{{title}}</div>
		<div class="subtitle">{{subtitle}}</div>
		<div class="description">{{description}}</div>
	</div> -->

	<div
		:class="[
			'course-block',
			`design_${design}`,
			avatarUrl ? 'with-avatar' : 'without-avatar',
			'v2',
		]"
		@click="$emit('click', $event)"
	>
		<div
			class="corner-icon"
			v-if="mode === 'author'"
			@click="
				$emit('edit');
				$event.stopPropagation();
			"
		>
			<img :src="icons.edit" />
		</div>
		<div
			class="corner-icon"
			v-else-if="mode === 'user'"
			@click="
				$emit('show');
				$event.stopPropagation();
			"
		>
			<img :src="icons.show" />
		</div>

		<div class="slot-content" v-if="$slots.default"><slot /></div>
		<div class="content">
			<div class="title">{{ title }}</div>
			<!-- <div class="subtitle">
				{{
					'Learn phrasal verbs with a clear guide, including simple explanations and practical examples'
				}}
			</div> -->
			<div class="words-count">
				{{subtitle}}
				<!-- 143 words / <span class="new-words-count">32 new</span> -->
			</div>
		</div>
		<!-- <div class="footer"></div> -->
		<!-- <div class="avatar" :style='`background-image: url("${avatarUrl}")`' />
		<div class="title">{{title}}</div>
		<div class="subtitle">{{subtitle}}</div>
		<div class="description">{{description}}</div> -->
	</div>
</template>

<script>
import editIcon from '@/assets/edit.svg';
import showIcon from '@/assets/list.svg';
export default {
	name: 'CourseBlock',
	data: () => ({
		icons: {
			edit: editIcon,
			show: showIcon,
		},
	}),
	computed: {
		avatarUrl: function() {
			return this.avatar
				? 'http://res.cloudinary.com/hjwascew9/image/upload/' + this.avatar
				: null;
			// const x = [].reduce.call(this.title, (acc, char) => acc + char.charCodeAt(0), 0);
			// const style = ['marble', 'beam', 'bauhaus'][x % 3];
			// return this.avatar || `https://source.boringavatars.com/${style}/120/${this.title}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51&square=true`;
		},
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		avatar: {
			type: String,
		},
		author: {
			type: String,
		},
		mode: {
			type: String,
			validator: val => ['user', 'author', 'no-button'].includes(val),
		},
		subtitle: {
			type: String,
		},
		description: {
			type: String,
		},
		design: {
			type: String,
			validator: val =>
				['default', 'default-disabled', 'create-new'].includes(val),
			default: 'default',
		},
	},
	components: {},
};
</script>

<style lang="stylus" scoped>
@import "../../components/style.styl";

corners-size = 16px
/*

.course-block.v1
	display inline-block
	width course-block-width
	// height 152px
	cursor pointer
	display grid
	box-sizing border-box
	position relative
	overflow hidden

	&.with-avatar
		grid-template-columns 108px auto
		grid-template-rows auto 17px 1fr
		grid-template-areas "avatar title" "avatar subtitle" "avatar description"

	&.without-avatar
		grid-template-columns 1fr
		grid-template-rows auto 17px 1fr
		grid-template-areas "title" "subtitle" "description"

	&.design_default, &.design_default-disabled
		background white
		border-radius corners-size
		&:active
			background #fafafa

	&.design_default-disabled
		opacity 0.5
		cursor default
		border solid 1px #aaa

	&.design_create-new
		border-radius corners-size
		color #999
		border dashed 2px #bfbfbf
		grid-template-areas "avatar title"
		grid-template-columns 1fr 1fr
		transition 0.3s
		.corner-icon
			display none
		.avatar
			margin-top 10px
			margin-left 12px
			width 120px
			opacity 0.2
			transition 0.3s
		.title
			padding-top 33px
			overflow visible
		&:hover
			color #666
			border-color #999
		&:active
			background rgba(0, 0, 0, 0.05)

	&.design_create-new:hover .avatar
			opacity 0.35

	.avatar
		grid-area avatar
		background-repeat no-repeat
		background-size auto 100%
		border-top-left-radius corners-size
		border-bottom-left-radius corners-size

	.title
		grid-area title
		margin-top 16px
		margin-bottom 4px
		margin-left 12px
		margin-right 35px
		font-weight 600
		font-size 16px
		line-height 140%
		overflow hidden
		text-overflow ellipsis

	.subtitle
		grid-area subtitle
		color #6B6B6B
		font-size 13px
		font-weight 400
		margin-left 12px
		white-space nowrap
		overflow hidden
		text-overflow ellipsis

	.description
		grid-area description
		margin-left 13.2px
		font-size 14px
		padding-top 6px
		padding-right 10px

	.corner-icon
		position absolute
		right 4px
		top 6px
		user-select none
		width 32px
		height 32px
		border-radius 100%
		display flex
		justify-content center
		transition 0.3s background
		img
			opacity 0.3
			width 16px
		&:hover
			background rgba(0, 0, 0, 0.1)
		&:active
			background rgba(0, 0, 0, 0.2) */

.course-block.v2
	cursor pointer
	background white
	border-radius corners-size
	// width 376px
	min-height 152px
	display flex
	flex-direction column
	max-width 450px
	// max-height 140px
	&:active
		background #fafafa
	.footer
		margin-top 20px
	.slot-content
		border-bottom solid 1px #DCDCDC
	.content
		display grid
		row-gap 8px
		padding 20px 24px
		.title
			font-weight 600
			color text-primary
		.words-count
			font-weight 400
			font-size 14px
			color text-secondary
		.new-words-count
			color main-color
		.subtitle
			font-weight 400
			color text-primary
			font-size 14px

	position relative
	.corner-icon
		position absolute
		right 4px
		top 6px
		user-select none
		width 32px
		height 32px
		border-radius 100%
		display flex
		justify-content center
		transition 0.3s background
		img
			opacity 0.3
			width 16px
		&:hover
			background rgba(0, 0, 0, 0.1)
		&:active
			background rgba(0, 0, 0, 0.2)
</style>
