import { getUserCourses } from '@/lib/api';

import { createLoadableModel } from './loadable-model';
export default createLoadableModel(async ([topic, level, showCommunityCourses]) => {
	return getUserCourses(['all', topic, level, showCommunityCourses]);
});

/*
const $ = (a, b) => Object.assign(a, b);

export default {
	namespaced: true,

	state: () => ({
        status: 'initial',
		data: null,
        error: null,
	}),

	actions: {
		load: async function({ state, commit }, category) {
            commit('loadingStart');
            try {
			    const data = await getUserCourses(category);
                // data.forEach(item => {
                // 	item.loading = false;
                // })
                commit('loadingSuccess', data);
            } catch (error) {
                commit('loadingError', error);
            }
		},

        clear: ({ commit }) => commit('clear'),

		// addCourseToFavorite: async function({ state, commit }, { course, value }) {
		// 	if (state.useTestUser) {
		// 		commit('setCourseFavorite', {
		// 			course,
		// 			value: !course.favorite
		// 		});
		// 		return;
		// 	}
		// 	commit('startLoadingCourse', course);
		// 	await addCourseToFavorite(course.id, !course.favorite);
		// 	commit('endLoadingCourse', course);
		// 	commit('setCourseFavorite', {
		// 		course,
		// 		value: !course.favorite,
		// 		number: !course.favorite ? course.favoriteCount + 1 : course.favoriteCount - 1
		// 	});
		// }
	},

	mutations: {
        loadingStart: state =>
            $(state, { status: 'loading', data: null, error: null }),
        loadingSuccess: (state, data) =>
            $(state, { status: 'success', data, error: null }),
        loadingError: (state, error) =>
            $(state, { status: 'error', data: null, error }),
        clear: state =>
            $(state, { status: 'initial', data: null, error: null }),
    },
}; */
