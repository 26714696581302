<template>
	<Page :loading="loading">
		<div class="edit-props">
			<TextField
				size="big"
				class="title-input"
				placeholder="Название"
				:value="title"
				@input="$store.commit('courseEdit/setTitle', $event)"
			/>
			<TextField
				v-if="mode === 'create'"
				class="name-input"
				placeholder="Внутреннее имя"
				:value="name"
				@input="$store.commit('courseEdit/setName', $event)"
			/>
			<div v-else class="name-div">
				{{ name }}
			</div>
		</div>
		<div class="course-edit-page">
			<div class="list-panel">
				<table>
					<tr>
						<th style="width: 129px">Question</th>
						<th>Answer</th>
					</tr>
					<tr v-if="chosenWords.length === 0">
						<td colspan="2" class="empty">
							Выберите слова из поиска, чтобы добавить их в изучение.
						</td>
					</tr>
					<tr v-for="item in chosenWords" :key="item.id">
						<td>
							<div class="question">{{ item.word }}</div>
						</td>
						<td>
							<div class="answer">{{ item.description }}</div>
							<div
								class="example"
								@click="$store.dispatch('courseEdit/updateSentence', item.id)"
							>
								<span v-if="item.sentence">
									<!-- {{item.sentence.split(item.word)[0]}}<b>{{item.word}}</b>{{item.sentence.split(item.word)[1]}} -->
									<!-- нужно делать toLowerCase, искать индекс и заменять по нему -->
									{{ item.sentence }}
								</span>
								<Spinner size="small" v-else />
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div class="add-panel">
				<div class="add-panel-field">
					<TextField
						autofocus
						placeholder="Поиск"
						:value="wordQ"
						@input="setWordQ"
					/>
					<Button
						design="action"
						@click="$store.dispatch('courseEdit/addSelectedWords')"
					>
						Add
					</Button>
				</div>
				<div class="add-panel-items" v-if="wordQ === ''">
					<i>Введите слово для поиска.</i>
				</div>
				<div class="add-panel-items" v-else-if="wordListLoading || !wordList">
					<Spinner size="medium" />
				</div>
				<div class="add-panel-items" v-else>
					<div
						v-for="item in wordList"
						:key="item.id"
						:class="['item', wordListChosen.includes(item.id) && 'selected']"
						@click="$store.commit('courseEdit/selectWord', item.id)"
					>
						<svg
							width="13"
							height="13"
							viewBox="0 0 13 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10.8192 5.14396C10.8192 4.98771 10.769 4.85936 10.6685 4.75891L9.90681 4.00556C9.80078 3.89954 9.67522 3.84652 9.53013 3.84652C9.38504 3.84652 9.25949 3.89954 9.15346 4.00556L5.73828 7.41237L3.84654 5.52063C3.74051 5.4146 3.61495 5.36159 3.46986 5.36159C3.32477 5.36159 3.19922 5.4146 3.09319 5.52063L2.33147 6.27398C2.23102 6.37442 2.1808 6.50277 2.1808 6.65902C2.1808 6.80969 2.23102 6.93525 2.33147 7.0357L5.36161 10.0658C5.46763 10.1719 5.59319 10.2249 5.73828 10.2249C5.88895 10.2249 6.0173 10.1719 6.12332 10.0658L10.6685 5.52063C10.769 5.42018 10.8192 5.29462 10.8192 5.14396ZM12.9286 6.49998C12.9286 7.66628 12.6412 8.74189 12.0664 9.72682C11.4916 10.7118 10.7118 11.4916 9.72684 12.0664C8.74191 12.6412 7.66629 12.9286 6.5 12.9286C5.3337 12.9286 4.25809 12.6412 3.27316 12.0664C2.28822 11.4916 1.50837 10.7118 0.933592 9.72682C0.358815 8.74189 0.0714264 7.66628 0.0714264 6.49998C0.0714264 5.33369 0.358815 4.25807 0.933592 3.27314C1.50837 2.28821 2.28822 1.50835 3.27316 0.933576C4.25809 0.3588 5.3337 0.0714111 6.5 0.0714111C7.66629 0.0714111 8.74191 0.3588 9.72684 0.933576C10.7118 1.50835 11.4916 2.28821 12.0664 3.27314C12.6412 4.25807 12.9286 5.33369 12.9286 6.49998Z"
								fill="currentColor"
							/>
						</svg>
						{{ item.word }} - {{ item.description }}
					</div>
					<div class="item create-item">
						<svg
							width="15"
							height="15"
							viewBox="0 0 15 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.25 8.03571V6.96428C11.25 6.81919 11.197 6.69364 11.091 6.58761C10.9849 6.48158 10.8594 6.42857 10.7143 6.42857H8.57144V4.28571C8.57144 4.14062 8.51843 4.01506 8.4124 3.90904C8.30638 3.80301 8.18082 3.75 8.03573 3.75H6.9643C6.81921 3.75 6.69365 3.80301 6.58763 3.90904C6.4816 4.01506 6.42859 4.14062 6.42859 4.28571V6.42857H4.28573C4.14064 6.42857 4.01508 6.48158 3.90905 6.58761C3.80303 6.69364 3.75001 6.81919 3.75001 6.96428V8.03571C3.75001 8.1808 3.80303 8.30636 3.90905 8.41239C4.01508 8.51841 4.14064 8.57143 4.28573 8.57143H6.42859V10.7143C6.42859 10.8594 6.4816 10.9849 6.58763 11.091C6.69365 11.197 6.81921 11.25 6.9643 11.25H8.03573C8.18082 11.25 8.30638 11.197 8.4124 11.091C8.51843 10.9849 8.57144 10.8594 8.57144 10.7143V8.57143H10.7143C10.8594 8.57143 10.9849 8.51841 11.091 8.41239C11.197 8.30636 11.25 8.1808 11.25 8.03571ZM13.9286 7.5C13.9286 8.66629 13.6412 9.74191 13.0664 10.7268C12.4916 11.7118 11.7118 12.4916 10.7269 13.0664C9.74192 13.6412 8.66631 13.9286 7.50001 13.9286C6.33372 13.9286 5.2581 13.6412 4.27317 13.0664C3.28824 12.4916 2.50838 11.7118 1.93361 10.7268C1.35883 9.74191 1.07144 8.66629 1.07144 7.5C1.07144 6.3337 1.35883 5.25809 1.93361 4.27316C2.50838 3.28822 3.28824 2.50837 4.27317 1.93359C5.2581 1.35881 6.33372 1.07143 7.50001 1.07143C8.66631 1.07143 9.74192 1.35881 10.7269 1.93359C11.7118 2.50837 12.4916 3.28822 13.0664 4.27316C13.6412 5.25809 13.9286 6.3337 13.9286 7.5Z"
								fill="currentColor"
							/>
						</svg>
						create new
					</div>
				</div>
			</div>
		</div>

		<div class="buttons">
			<Button
				v-if="mode === 'edit'"
				design="ghost"
				size="big"
				class="del-button"
				@click="delModalOpen = true"
			>
				Удалить
			</Button>
			<Button
				design="action"
				size="big"
				class="send-button"
				@click="saveCourse"
			>
				Отправить
			</Button>
		</div>

		<Modal :open="delModalOpen" :width="600">
			<div class="del-modal-content">
				<p>
					Чтобы удалить курс {{ title }}, введите его внутреннее имя (<b>{{
						name
					}}</b
					>):
				</p>
				<TextField />
				<div class="del-modal-content-buttons">
					<Button design="link" @click="delModalOpen = false"
						>Не удалять</Button
					>
					<Button design="danger" @click="delCourse">Удалить</Button>
				</div>
			</div>
		</Modal>
	</Page>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import Page from '@/components/Page';
import Spinner from '@/components/Spinner';
import TextField from '@/components/TextField';
import { debounceAfter } from '@/lib/utils';

export default {
	name: 'CourseEditPage',

	data: () => ({
		updateWordListDebounced: null,

		delModalOpen: false,
	}),

	computed: {
		...mapState({
			title: state => state.courseEdit.title,
			name: state => state.courseEdit.name,
			loading: state => state.courseEdit.loading,

			wordQ: state => state.courseEdit.wordQ,
			wordListLoading: state => state.courseEdit.wordListLoading,
			wordList: state => state.courseEdit.wordList,
			wordListChosen: state => state.courseEdit.wordListChosen,

			chosenWords: state => state.courseEdit.chosenWords,
		}),

		mode: function() {
			return this.$route.path === '/course/create' ? 'create' : 'edit';
		},
	},

	methods: {
		setWordQ(value) {
			this.$store.commit('courseEdit/setWordQ', value);
			this.updateWordListDebounced();
		},

		async updateWordList() {
			this.$store.dispatch('courseEdit/loadWordList');
		},

		delCourse() {},

		async saveCourse() {
			await this.$store.dispatch('courseEdit/save', this.mode);
			this.$router.push(`/course/${this.name}`);
		},
	},

	mounted: function() {
		this.updateWordListDebounced = debounceAfter(this.updateWordList, 500);

		if (this.mode === 'create') {
			this.$store.dispatch('courseEdit/create');
		} else {
			const courseName = this.$route.params.courseName;
			this.$store.dispatch('courseEdit/edit', {
				courseName,
			});
		}
	},

	components: {
		Button,
		Modal,
		Page,
		Spinner,
		TextField,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.page
	padding-left 100px
	padding-right 100px
	min-height 100vh
	box-sizing border-box
	display grid
	grid-template-rows 83px auto 83px

.edit-props
	display grid
	justify-content center
	margin-bottom 10px
	.title-input
		margin-bottom 5px
	.name-div
		line-height 35px
		color #777

.course-edit-page
	display grid
	grid-template-columns 3fr 4fr

.list-panel
	display grid
	& > table
		font-size 16px
		th
			padding 28px
			font-weight 500
			text-align left
			border-bottom solid 1px rgba(0, 0, 0, 0.1)
		td
			padding 22px 28px
			border-bottom solid 1px rgba(0, 0, 0, 0.1)
			vertical-align top
			font-weight 400
			b
				font-weight 400
			.example
				font-weight 300
				color #6C6C6C
				padding-top 30px
				cursor pointer
		.empty
			text-align center
			font-style italic

.add-panel
	padding 28px 100px
	text-align center
	.add-panel-field
		display grid
		grid-template-columns 1fr auto
		grid-column-gap 20px
		.button
			width 113px
	.add-panel-items
		display inline-block
		min-width 400px
		margin-top 36px
		.item
			font-weight 400
			cursor pointer
			padding 12px 0
			text-align left
			color #444
			svg
				color #D7D7D7
				transform scale(1.2)
				margin-right 10px
				vertical-align middle
		.item.selected
			font-weight 600
			svg
				color main-color
		.item.create-item
			font-style italic
			font-weight 300
			svg
				color #B6B6B6

.buttons
	text-align right
	margin 20px 0
	.send-button
		margin-left 15px
		min-width 130px

.del-modal-content
	padding 40px
	p
		margin-bottom 20px
	.del-modal-content-buttons
		text-align right
		margin-top 25px
		.button:first-child
			margin-right 5px
</style>
