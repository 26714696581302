<template>
	<Page class="admin-page" :useMascot="false" :useDocumentHeight="true">
		<Modal
			v-if="password === null"
			:open="true"
			:maximumWidth="600"
			:widthPageMargin="30"
			@enterPressed="setPassword"
		>
			<ModalMarkup
				:buttons="markup.okButton"
				@buttonOk="setPassword"
			>
				<TextField
					v-model="passwordInput"
					placeholder="Введите пароль"
					ref="passwordField"
					style="margin-bottom: 10px;"
				/>
				<TextField
					v-model="openaiKeyInput"
					placeholder="OpenAI key"
				/>
			</ModalMarkup>
		</Modal>

		<Modal
			v-if="openaiModalOpen"
			:open="true"
			:maximumWidth="600"
			:widthPageMargin="30"
			@enterPressed="openaiModalSave"
		>
			<ModalMarkup
				:buttons="markup.saveAndCloseAndRequestButtons"
				@buttonRequest="openaiModalRequest"
				@buttonSave="openaiModalSave"
				@buttonClose="openaiModalOpen = false"
			>
				<div style="margin-bottom: 5px">Language: {{langPair}}</div>
				<TextField
					v-model="openaiModalWord"
					ref="openaiModalInput"
					style="margin-bottom: 10px"
				/>
				<textarea
					v-model="openaiModalPromptText"
					class="openai-request"
				/>
				<select @change="openaiModalPromptText = $event.target.value">
					<option
						v-for="config, name in prompts"
						:key="name"
						:value="config"
					>
							{{name}}
					</option>
				</select>
					
				<div v-if="openaiModalLoading" style="padding: 20px; text-align: center">Loading</div>
				<div class="openai-sentences" v-if="openaiModalResponse" style="max-height: 30vh; overflow: auto">
					<div
						v-for="form in openaiModalResponse"
						:key="form.form"
						style="margin-top: 10px"
					>
						<h3 style="margin-bottom: 10px">{{form.form}}</h3>
						<label
							v-for="item in form.sentences"
							:key="item.sentence"
							:class="['openai-sentence', item.active ? 'active' : '']"
							@contextmenu="openaiUpdateFormFor(item)"
						>
							<div class="input">
								<input type="checkbox" v-model="item.active" />
							</div>
							<div class="openai-text">
								<span v-if="item.sentence.toLowerCase().includes(item.word.toLowerCase())">
									{{
										item.sentence.substring(0, item.sentence.toLowerCase().indexOf(item.word.toLowerCase()))
									}}<u>{{
										item.sentence.substr(item.sentence.toLowerCase().indexOf(item.word.toLowerCase()), item.word.length)
									}}</u>{{
										item.sentence.substring(item.sentence.toLowerCase().indexOf(item.word.toLowerCase()) + item.word.length)
									}}
								</span>
								<span v-else>{{item.sentence}}</span>
							</div>
							<div class="openai-translation">{{item.translation}}</div>
						</label>
					</div>
				</div>
			</ModalMarkup>
		</Modal>

		<Modal
			v-if="reversoModalOpen"
			:open="true"
			:maximumWidth="600"
			:widthPageMargin="30"
			@enterPressed="reversoModalSend"
		>
			<ModalMarkup
				:buttons="markup.saveAndCloseButtons"
				@buttonSave="reversoModalSend"
				@buttonClose="reversoModalOpen = false"
			>
				<TextField
					v-model="reversoModalText"
					ref="reversoModalText"
				/>
				<div style="background: #eee; padding: 20px; margin-top: 10px; max-height: 50vh; overflow: auto">{{reversoModalLoading ? 'Loading' : reversoModalResponse}}</div>
			</ModalMarkup>
		</Modal>

		<Modal
			v-if="langsModalOpen"
			:open="true"
			:maximumWidth="600"
			:widthPageMargin="30"
			@enterPressed="langsModalOpen = false"
		>
			<ModalMarkup
				:buttons="markup.okButton"
				@buttonOk="langsModalOpen = false"
			>
				Source:
				<TextField
					v-model="langSource"
					style="width: 70px; display: inline-block; margin-right: 30px"
				/>
				Dest:
				<TextField
					v-model="langDest"
					style="width: 70px; display: inline-block"
				/>
				<br/><br/>
				<select :value="langPair" @change="setLangPair($event.target.value)">
					<option value="ru-en">English-Russian</option>
					<option value="en-an_grc">Ancient Greek-English</option>
					<option value="en-old_en">Old English-English</option>
					<option value="en-ch">Chinese-English</option>
					<option value="en-fr">French-English</option>
					<option value="en-sp">Spanish-English</option>
					<option value="en-it">Italian-English</option>
					<option value="en-lat">Latin-English</option>
					<option value="en-prt">Portuguese-English</option>
					<option value="en-grm">German-English</option>
					<option value="en-cz">Czech-English</option>
					<option value="en-pl">Polish-English</option>
					<option value="en-jap">Japanese-English</option>
					<option value="en-kor">Korean-English</option>
					<option value="en-sw">Swedish-English</option>
					<option value="en-trk">Turkish-English</option>
					<option value="ru-custom">Custom-Russian</option>
				</select>
			</ModalMarkup>
		</Modal>

		<Modal
			v-if="openModalCreateWord"
			:open="true"
			:maximumWidth="600"
			:widthPageMargin="30"
			:loading="createWordData.loading"
		>
			<ModalMarkup
				v-if="createWordData.answer !== null"
				:buttons="markup.closeButtons"
				@buttonOk="openModalCreateWord = false"
			>
				ID: {{ createWordData.answer }}
				<br />Подождите загрузки слова.
			</ModalMarkup>
			<ModalMarkup
				v-else
				class="create-word-markup"
				:buttons="markup.saveAndCancelButtons"
				@buttonSave="sendCreateWord"
				@buttonCancel="openModalCreateWord = false"
			>
				<span>все поля с маленькой буквы</span>
				<span>word:</span>
				<TextField
					v-model="createWordData.text"
					placeholder="word"
					ref="createWordInput"
					:modifiers="langPairModificators"
					@keydown.enter="$refs.createWordTranslation.focus()"
				/>
				<span>translation:</span>
				<TextField
					v-model="createWordData.description"
					ref="createWordTranslation"
					placeholder="transcription | translation"
					@keydown.enter="$refs.createWordLemma.focus()"
				/>
				<span>lemma:</span>
				<TextField
					v-model="createWordData.lemma"
					ref="createWordLemma"
					placeholder="lemma"
					:modifiers="langPairModificators"
					@keydown.enter="$refs.createWordPos.focus()"
				/>
				<span>pos:</span>
				<TextField
					ref="createWordPos"
					v-model="createWordData.partOfSpeech"
					placeholder="partOfSpeech"
					@keydown.enter="sendCreateWord"
				/>
				<select @change="createWordData.partOfSpeech = setPos(createWordData.partOfSpeech, $event.target.value)">
					<option
						v-for="(name, value) in partsOfSpeech"
						:key="value"
						:selected="createWordData.partOfSpeech.split('|')[0] === value"
						:value="value"
					>{{ name }}</option>
				</select>
				<div>
					<div v-for="row in langPairLabels" :key="row.toString()" style="margin-top: 20px; display: flex; column-gap: 10px">
						<label v-for="label in row" :key="label">
							<input
								type="checkbox"
								:checked="checkLabel(createWordData.partOfSpeech, label)"
								@change="createWordData.partOfSpeech = toggleLabel(createWordData.partOfSpeech, label)"
							/>
							{{label}}
						</label>
					</div>
				</div>
				<div style="margin-top: 20px">
					<i v-if="langPair === 'ru-en'">
						Английский через русский.
					</i>
					<i v-else-if="langPair === 'an-grc-en'">
						Древнегреческий через английский.
					</i>
				</div>
				<label style="display: block; margin-top: 20px">
					<input
						type="checkbox"
						:checked="createWordData.shared"
						@change="createWordData.shared = $event.target.checked"
					/>
					Shared
				</label>
			</ModalMarkup>
		</Modal>

		<Modal
			v-if="openModalEditWord"
			:open="true"
			:maximumWidth="600"
			:widthPageMargin="30"
			:loading="editWordData.loading"
		>
			<ModalMarkup
				v-if="editWordData.answer !== null"
				:buttons="markup.closeButtons"
				@buttonOk="openModalEditWord = false"
			>
				ID: {{ editWordData.answer }}
				<br/>Подождите загрузки слова.
			</ModalMarkup>
			<ModalMarkup
				v-else
				class="create-word-markup"
				:buttons="markup.saveAndCancelButtons"
				@buttonSave="sendEditWord"
				@buttonCancel="openModalEditWord = false"
			>
				<span>все поля с маленькой буквы</span>
				<span>word:</span>
				<TextField
					v-model="editWordData.text"
					placeholder="word"
					ref="editWordInput"
					:modifiers="langPairModificators"
					@keydown.enter="$refs.editWordTranslation.focus()"
				/>
				<span>translation:</span>
				<TextField
					v-model="editWordData.description"
					placeholder="transcription | translation"
					ref="editWordTranslation"
					@keydown.enter="$refs.editWordLemma.focus()"
				/>
				<span>lemma:</span>
				<TextField
					v-model="editWordData.lemma"
					placeholder="lemma"
					:modifiers="langPairModificators"
					ref="editWordLemma"
					@keydown.enter="$refs.editWordRef.focus()"
				/>
				<span>pos:</span>
				<TextField
					v-model="editWordData.partOfSpeech"
					placeholder="partOfSpeech"
					ref="editWordRef"
					@keydown.enter="sendEditWord"
				/>
				<select @change="editWordData.partOfSpeech = setPos(editWordData.partOfSpeech, $event.target.value)">
					<option
						v-for="(name, value) in partsOfSpeech"
						:key="value"
						:selected="editWordData.partOfSpeech.split('|')[0] === value"
						:value="value"
					>{{ name }}</option>
				</select>
				<div>
					<div v-for="row in langPairLabels" :key="row.toString()" style="margin-top: 20px; display: flex; column-gap: 10px">
						<label v-for="label in row" :key="label">
							<input
								type="checkbox"
								:checked="checkLabel(editWordData.partOfSpeech, label)"
								@change="editWordData.partOfSpeech = toggleLabel(editWordData.partOfSpeech, label)"
							/>
							{{label}}
						</label>
					</div>
				</div>
				<label style="display: block; margin-top: 20px; padding-top: 20px; border-top: solid 1px #ddd">
					<input
						type="checkbox"
						:checked="editWordData.shared"
						@change="editWordData.shared = $event.target.checked"
					/>
					Shared
				</label>
			</ModalMarkup>
		</Modal>

		<Modal
			v-if="openModalCreateSentence"
			:open="true"
			:maximumWidth="600"
			:widthPageMargin="30"
			:loading="createSentenceData.loading"
		>
			<ModalMarkup
				v-if="createSentenceData.answer !== null"
				:buttons="markup.closeButtons"
				@buttonOk="openModalCreateSentence = false"
			>
				ID: {{ createSentenceData.answer }}
				<br/>Подождите загрузки предложения.
			</ModalMarkup>
			<ModalMarkup
				v-else
				class="create-sentence-markup"
				:buttons="markup.saveAndCancelButtons"
				@buttonSave="sendCreateSentence"
				@buttonCancel="openModalCreateSentence = false"
			>
				<span>text:</span>
				<TextField
					v-model="createSentenceData.text"
					placeholder="text"
					ref="createSentenceInput"
					:modifiers="langPairModificators"
				/>
				<span>translation:</span>
				<TextField
					v-model="createSentenceData.translation"
					placeholder="translation"
				/>
			</ModalMarkup>
		</Modal>

		<div class="admin-page-content">
			<div class="admin-page-words">
				<div class="words-search">
					<TextField
						v-model="searchWords.q"
						placeholder="Поиск слов"
						:modifiers="langPairModificators"
					/>
				</div>
				<div class="words">
					<div v-if="searchWords.loading">Loading</div>
					<WordsList
						v-else
						:words="searchWords.answer"
						:isSelected="wordId => selectedWordId === wordId"
						@wordClick="word => selectedWordId = word.id"
						:selectPart="searchWords.q"
					/>
				</div>
				<div class="words-create-button">
					<Button
						design="white-button"
						style="width: 100%"
						:disabled="!selectedWordId"
						@click="createWordEditModal"
					>
						Редактировать слово
					</Button>
					<Button
						design="white-button"
						style="width: 100%; margin-top: 10px"
						@click="createWordOpenModal"
					>
						Создать слово
					</Button>
					<Button
						design="ghost"
						style="width: 100%; margin-top: 10px"
						@click="langsModalOpen = true"
					>
						Язык: {{ langPair }}
					</Button>
					<Button
						design="ghost"
						style="width: 100%; margin-top: 10px"
						@click="openaiModalOpen = true; openaiModalPromptText = prompts['English noun']; $nextTick(() => $refs.openaiModalInput.focus())"
					>
						OpenAI 
					</Button>
					<Button
						design="ghost"
						style="width: 100%; margin-top: 10px"
						@click="reversoModalOpen = true; $nextTick(() => $refs.reversoModalText.focus())"
					>
						Reverso
					</Button>
				</div>
			</div>

			<div class="admin-page-sentences">
				<div class="sentences-search">
					<div>
						<TextField
							placeholder="Поиск предложений"
							v-model="searchSentencesData.text"
							@keydown.enter="sendSearchSentences"
							:modifiers="langPairModificators"
						/>
						<div class="additional-controls">
							<div><label>
								<input
									name="sentenceSearchType"
									type="radio"
									:checked="searchSentencesData.type === 'text'"
									@change="searchSentencesData.type = 'text'"
								/>
								Text
							</label>
							<label>
								<input
									name="sentenceSearchType"
									type="radio"
									:checked="searchSentencesData.type === 'translation'"
									@change="searchSentencesData.type = 'translation'"
								/>
								Translation
							</label></div><div>
							<label>
								<input
									type="checkbox"
									:checked="searchSentencesData.like"
									@change="searchSentencesData.like = $event.target.checked"
								/>
								User Regexp
							</label></div><div />
							<div>
								<Button
									v-if="updateWordLinks.loading"
									design="action"
									style="width: 150px; margin-right: 15px"
									:disabled="true"
								>Loading</Button>
								<Button
									v-else
									design="action"
									style="width: 150px; margin-right: 15px"
									:disabled="!sentencesChanged"
									@click="saveWordLinks"
								>Сохранить</Button>
								<Button
									design="ghost"
									style="width: 150px;"
									@click="sendSearchSentences"
								>Поиск</Button>
							</div>
						</div>
					</div>
				</div>
				<div style="height: calc(100vh - 220px); overflow: auto">
					<h3 :style="{'margin-top': '20px', 'color': hideSearchSentences ? 'gray' : 'black'}" @click="hideSearchSentences = !hideSearchSentences">
						Предложения из поиска {{searchSentencesData_formatted && `(${searchSentencesData_formatted.length})`}}
						<input
							type="checkbox"
							@click="$event.stopPropagation()"
							style="margin-left: 10px; cursor: pointer"
							@change="searchSentencesData_formatted.forEach(sent => changeLinkFor(sent.id, sent.text))"
						/>
					</h3>
					<div class="sentences sentences-from-search" v-if="!hideSearchSentences">
						<div v-if="searchSentencesData.loading">Loading</div>
						<div v-else>
							<label
								v-for="sentence in searchSentencesData_formatted"
								:key="sentence.id"
								:class="sentencesSelectedIds && sentencesSelectedIds.includes(sentence.id) ? 'checked' : ''"
							>
								<div class="id">
									<input
										type="checkbox"
										:checked="sentencesSelectedIds && sentencesSelectedIds.includes(sentence.id)"
										:disabled="!sentencesSelectedIds"
										@change="changeLinkFor(sentence.id, sentence.text)"
									/><br/>
									{{sentence.id}}
								</div>
								<div class="text" v-if="sentencesSelectedIds && sentencesSelectedIds.includes(sentence.id)">
									<span v-if="sentence.wordForm && sentence.text.includes(sentence.wordForm)">
										{{sentence.text.split(sentence.wordForm)[0]}}<u>{{sentence.wordForm}}</u>{{sentence.text.split(sentence.wordForm).slice(1).join(sentence.wordForm)}}
									</span>
									<span v-else style="color: #f00">{{sentence.text}}</span>
								</div>
								<div class="text" v-else>
									{{sentence.text}}
								</div>
								<div class="translation">
									{{sentence.translation}}
								</div>
								<div class="input" v-if="sentencesSelectedIds && sentencesSelectedIds.includes(sentence.id)">
									<TextField
										v-model="sentencesWordForms[sentence.id]"
										placeholder="word form"
										size="small"
									/>
								</div>
							</label>
						</div>
					</div>

					<h3 :style="{'margin-top': '20px', 'color': hideWordSentences ? 'gray' : 'black'}" @click="hideWordSentences = !hideWordSentences">
						Предложения к слову {{findLinksData_formatted && `(${findLinksData_formatted.length})`}}
						<input
							type="checkbox"
							@click="$event.stopPropagation()"
							style="margin-left: 10px; cursor: pointer"
							@change="findLinksData_formatted.forEach(sent => changeLinkFor(sent.id, sent.text))"
						/>
					</h3>
					<div class="sentences sentences-from-word" v-if="selectedWordId && !hideWordSentences">
						<div v-if="findLinksData.loading">Loading</div>
						<label
							v-else
							v-for="sentence in findLinksData_formatted"
							:key="sentence.id"
							:class="sentencesSelectedIds.includes(sentence.id) ? 'checked' : ''"
						>
							<div class="id">
								<input
									type="checkbox"
									:checked="sentencesSelectedIds.includes(sentence.id)"
									@change="changeLinkFor(sentence.id, sentence.text)"
								/><br/>
								{{sentence.id}}
							</div>
							<div class="text" v-if="sentencesSelectedIds.includes(sentence.id)">
								<span v-if="sentence.text.includes(sentence.wordForm)">
									{{sentence.text.split(sentence.wordForm)[0]}}<u>{{sentence.wordForm}}</u>{{sentence.text.split(sentence.wordForm).slice(1).join(sentence.wordForm)}}
								</span>
								<span v-else style="color: #f00">{{sentence.text}}</span>
							</div>
							<div class="text" v-else>
								{{sentence.text}}
							</div>
							<div class="translation">
								{{sentence.translation}}
							</div>
							<div class="input" v-if="sentencesSelectedIds && sentencesSelectedIds.includes(sentence.id)">
								<TextField
									v-model="sentencesWordForms[sentence.id]"
									placeholder="word form"
									size="small"
								/>
							</div>
						</label>
					</div>
				</div>
				<div style="margin: 10px 0">
					<Button
						design="ghost"
						style="width: 30%"
						@click="createSentenceOpenModal"
					>
						Создать предложение
					</Button>
					<Button
						design="danger"
						style="margin-left: 10px"
						@click="sendRecalcWords"
						:disabled="recalcWordsCounts.loading"
					>
						{{ recalcWordsCounts.loading ? 'Loading...' : 'Recalc words counts' }}
					</Button>
					<TextField
						placeholder="Форма слова"
						style="display: inline-block; width: 200px; margin-left: 10px"
						v-model="wordForm"
						:modifiers="langPairModificators"
					/>
				</div>
			</div>
		</div>
	</Page>
</template>

<script>
import Page from '@/components/Page';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import ModalMarkup from '@/components/ModalMarkup';
import TextField from '@/components/TextField';
import WordsList from '@/components/WordsList';

import {
	adminCreateSentence,
	adminCreateWord,
	adminCreateWordSentenceLink,
	adminSearchSentences,
	adminRecalcWordsCounts,
	adminFindLinks,
	searchWords,
	adminUpdateWordLinks,
	adminUpdateWord,
	adminReverso,
} from '@/lib/api';
import { debounceAfter } from '@/lib/utils';
import normalizeGreekWord from '@/lib/poly-greek-normalize';

const searchWordsDebounced = debounceAfter(searchWords, 500);

const langs = [
	'en',
	'ru',
	'an_grc',
	'old_en',
	'ch', // mandarin
	'fr',
	'sp',
	'it',
	'lat',
	'prt',
	'grm',
	'cz',
	'pl',
	'jap',
	'kor',
	'sw',
	'trk',
	...new Array(51 - 18).fill(null),
	'custom'
];

export default {
	name: 'AdminPage',

	data: () => ({
		// password input
		passwordInput: '',
		password: null,
		openaiKeyInput: '',
		openaiKey: null,

		selectedWordId: null,
		searchWords: {
			loading: false,
			q: '',
			answer: null,
		},
		sentencesSelectedIds: null,
		sentencesWordForms: null,
		openModalCreateWord: false,
		openModalEditWord: false,
		openModalCreateSentence: false,
		hideSearchSentences: false,
		hideWordSentences: false,
		
		langSource: '0',
		langDest: '1',
		langsModalOpen: false,

		reversoModalOpen: false,
		reversoModalText: '',
		reversoModalResponse: '',
		reversoModalLoading: false,

		openaiModalOpen: false,
		openaiModalWord: '',
		openaiModalPromptText: '',
		openaiModalLoading: false,
		openaiModalResponse: null,

		wordForm: '',

		createWordData: {
			loading: false,
			text: '',
			description: '',
			lemma: '',
			partOfSpeech: 'noun',
			shared: true,
			answer: null,
		},
		editWordData: {
			loading: false,
			text: '',
			description: '',
			lemma: '',
			partOfSpeech: 'noun',
			shared: true,
			answer: null,
		},
		createSentenceData: {
			loading: false,
			text: '',
			translation: '',
			source: 1,
			meta: 1,
			answer: null,
		},
		createLinkData: {
			loading: false,
			SentenceId: '',
			WordId: '',
			WordForm: '',
			answer: null,
		},
		searchSentencesData: {
			loading: false,
			text: '',
			type: 'text',
			like: false,
			answer: null
		},
		findLinksData: {
			loading: false,
			type: 'word',
			id: '',
			answer: null,
		},
		recalcWordsCounts: {
			loading: false
		},
		updateWordLinks: {
			loading: false,
		},

		// markup
		markup: {
			okButton: [{
				id: 'ok',
				design: 'action',
				text: 'OK'
			}],

			closeButtons: [{
				id: 'ok',
				design: 'ghost',
				text: 'Закрыть'
			}],

			saveAndCancelButtons: [{
				id: 'save',
				design: 'action',
				text: 'Save'
			}, {
				id: 'cancel',
				design: 'link',
				text: 'Cancel'
			}],

			saveAndCloseButtons: [{
				id: 'save',
				design: 'action',
				text: 'Save'
			}, {
				id: 'close',
				design: 'link',
				text: 'Close'
			}],

			saveAndCloseAndRequestButtons: [{
				id: 'request',
				design: 'white-button',
				text: 'Request'
			}, {
				id: 'save',
				design: 'action',
				text: 'Save'
			}, {
				id: 'close',
				design: 'link',
				text: 'Close'
			}]
		},

		prompts: {
			/*
#01 You're a journalist from Britain. You have to conjugate the noun in the input and provide illustrating sentences for every used form.

#02 Include all possible forms of this noun. Modify the input noun itself, don't add or remove words. Never make up new forms that don't exist in English. Don't include the form if it's almost never used.
Example of the answer if the input is "cat": "cat, cats".

#03 For every conjugated form provide unique sentences containing the conjugated form of the word. It's necessary to illustrate how this form is used in real life.

#04 You can use quotations from movies, poems and literature, lines from lyrics, famous news titles, names of pictures and other cultural context for sentences. Choose the most interesting ones.

#05 Make the sentences REALLY diverse. Be creative.

#06 You must provide good grammatically correct sentences that are natural in native speakers speech.

#07 The sentences you provide must only contain the input word as a noun. Don't include it as verb or adjective.

#08 Provide at least 5 sentences for each form.

#09 For every sentence provide its translation to Russian language.

#10 The translation must be done by a professional literary translator from English to Russian.

#11 You MUST always answer with JSON only.

---------------------------------------------

Please conjugate the noun "cat" and provide context examples.

Output format example:
{
  "formsOrder": ["cat", "cats"],
  "forms": {
    "cat": [ /* sentences with the form "cat" * / ],
    "cats": [ /* sentences with the form "cats" * / ]
  }
}

Sentences JSON format:
{
  "sentence": "Sentence text",
  "translation: "Текст предложения",
  "source": "Example sentence"
}
*/
			'English noun': `You're a journalist from Britain.

Input contains the noun that you are asked to conjugate and provide context.
Your answer must start with all possible forms of this noun (don't add any other words, only conjugate the original one), separated by comma. Never make up new forms that don't exist in English. Don't include the form it's almost never used.
Example of the answer if the input is "cat": "cat, cats".

For every conjugated form provide unique sentences containing the conjugated form of the word. It's necessary to illustrate how this form is used in real life. Use quotations from movies, poems and literature, lines from lyrics, famous news titles, names of pictures and other cultural context. Choose most interesting ones.

Provide at least 5 sentences for each form.

Output format example:
Forms: cat, cats
# form: cat
- Example sentence one. [source: ...]
- example sentence two
...

# form: cats
- example sentence one...

---------------------------------------------

Please conjugate the noun "{{word}}" and provide context examples

---------------------------------------------

You're a professional literary translator from English to Russian. Please translate the provided sentences.

Output format:
1. // First sentence translated to Russian
2.`,

			'English verb': `You're a journalist from Britain.

Input contains the verb that you are asked to conjugate and provide context.
Your answer must start with all possible forms of this verb (don't add any other words, only conjugate the original one), separated by comma. Never make up new forms that don't exist in English.
Example of the answer if the input is "cat": "cat, cats".

For every conjugated form provide unique sentences containing the conjugated form of the word. It's necessary to illustrate how this form is used in real life. Use quotations from movies, poems and literature, lines from lyrics, famous news titles, names of pictures and other cultural context. Choose most interesting ones.

Provide at least 5 sentences for each form.

Output format example:
Forms: cat, cats
# form: cat
- example sentence one. [source]
- example sentence two
...

# form: cats
- example sentence one...

---------------------------------------------

Please conjugate the verb "{{word}}" and provide context examples

---------------------------------------------

You're a professional literary translator from English to Russian. Please translate the provided sentences.

Output format:
1. First sentence translated to Russian
2.`,

			'English idiom': `You're a journalist from Britain.

Input contains the idiom that you are asked to conjugate and provide context. Idiom is conjugated by
1. If it's verb-based, conjugate the verb in it. If it's noun-based, do the same. Do it ONLY if that's how people use this idiom.
2. Replace placeholder words (such as "one's") with its options ("my", "yours", etc). Do it ONLY if that's how people use this idiom.

Your answer must start with possible forms of this idiom (don't add any other words, only conjugate the original idiom), separated by comma. Never make up new forms that don't exist in English. Don't include the form it's almost never used.
Example of the answer if the input is "sink like a stone": "sink like a stone, sank like a stone, sunk like a stone, sinking like a stone".
Example of the answer if the input is "domino effect": "domino effect", "domino effects".

For every conjugated form provide unique sentences containing the conjugated form of the word. It's necessary to illustrate how this form is used in real life. Use quotations from movies, poems and literature, lines from lyrics, famous news titles, names of pictures and other cultural context. Choose most interesting ones.

Provide at least 5 sentences for each form. Don't make them too long and don't mention the source.

Output format example:
Forms: domino effect, domino effects
# form: domino effect
- It would cause domino effect.
- I'm afraid of domino effect because...
...

# form: domino effects
- Domino effects are inevitable when...

---------------------------------------------

Please conjugate the idiom "{{word}}" and provide context examples

---------------------------------------------

You're a professional literary translator from English to Russian. Please translate the provided sentences.

Сделай перевод литературным. Не переводи идиому дословно, если она не соответствует другой идиоме, вместо этого найди аналогичные по значению.

Output format:
1. // First sentence translated to Russian
2.`,

			'German noun': `Du bist ein Journalist aus Deutschland.

Der Input enthält das Substantiv, das du konjugieren und kontextualisieren sollst.
Deine Antwort muss mit allen möglichen Formen dieses Substantivs in verschiedenen Fällen beginnen (füge keine anderen Wörter hinzu, konjugiere nur das ursprüngliche Wort mit dem Artikel), getrennt durch Kommata. Erfinde keine neuen Formen, die im Deutschen nicht existieren. Schließe die Form aus, die fast nie verwendet wird.
Beispiel für die Antwort, wenn das Eingabewort "Katze" ist: "die Katze, der Katze, den Katze, die Katzen, der Katzen, den Katzen".

Für jede konjugierte Form stelle einzigartige Sätze bereit, die die konjugierte Form des Wortes enthalten. Es ist notwendig zu veranschaulichen, wie diese Form im wirklichen Leben verwendet wird. Verwende Zitate aus Filmen, Gedichten und Literatur, Zeilen aus Liedtexten, berühmte Nachrichtentitel, Namen von Bildern und anderen kulturellen Kontext. Wähle die interessantesten aus.
Stelle mindestens 5 Sätze für jede Form bereit.

Ausgabeformat Beispiel:
Formen: die Katze, der Katze, den Katze, die Katzen, der Katzen, den Katzen
# Form: die Katze
- Beispiel Satz eins. [Quelle: ...]
- Beispiel Satz zwei
...

# Form: der Katze
- Beispiel Satz eins...

---------------------------------------------

Bitte konjugiere das Substantiv "{{word}}" und stelle Kontextbeispiele bereit

---------------------------------------------

Du bist ein professioneller literarischer Übersetzer vom Deutschen ins Russische. Bitte übersetze die bereitgestellten Sätze.

Ausgabeformat:
1. Erster Satz übersetzt ins Russische
2.`,

			'French noun': `Tu es un journaliste de France.

L'entrée contient le nom que tu dois conjuguer et fournir du contexte.
Ta réponse doit commencer par toutes les formes possibles de ce nom dans différents cas (ne rajoute pas d'autres mots, conjugue uniquement le mot original), séparées par des virgules. N'invente pas de nouvelles formes qui n'existent pas en français. N'inclus pas la forme qui est presque jamais utilisée. N'incluez pas l'article.
Exemple de la réponse si l'entrée est "chat" : "chat, chats".

Pour chaque forme conjuguée, fournis des phrases uniques contenant la forme conjuguée du mot. Il est nécessaire d'illustrer comment cette forme est utilisée dans la vie réelle. Utilise des citations de films, poèmes et littérature, des paroles de chansons, des titres de nouvelles célèbres, des noms de tableaux et d'autres contextes culturels. Choisis les plus intéressants.

Fournis au moins 5 phrases pour chaque forme.

Format de sortie exemple :
Formes : chat, chats
# Forme : chat
- Exemple de phrase un. [source : ...]
- Exemple de phrase deux
...

# Forme : chats
- Exemple de phrase un...

...

---------------------------------------------

Merci de conjuguer le nom "{{word}}" dans différents cas et de fournir des exemples de contexte

---------------------------------------------

Tu es un traducteur littéraire professionnel du français vers le russe. Merci de traduire les phrases fournies.

Format de sortie :
1. Première phrase traduite en russe
2.`,

			'Ancient Greek noun': `Σὺ εἶ δημοσιογράφος ἐκ τῆς Ἑλλάδος.

Ἡ εἴσοδος περιέχει τὸ οὐσιαστικόν, ὃ δεῖ σε συζυγνύναι καὶ παράσχειν συμφραζόμενα.
Ἡ ἀπάντησίς σου πρέπει νὰ ἀρχίζῃ μὲ πᾶσαις ταῖς δυναταῖς μορφαῖς τοῦ οὐσιαστικοῦ τούτου ἐν διαφόροις πτώσεσιν (μὴ προσθέτῃς ἄλλα ῥήματα, συζύγνυε μόνον τὴν ἀρχικὴν λέξιν μετὰ τοῦ ἄρθρου), χωριζομένας διὰ κομμάτων. Μὴ ἐφεύρισκε νέας μορφάς αἳ οὐκ εἰσὶν ἐν τῇ Κοινῇ Ἑλληνικῇ. Μὴ περιέλαβε τὴν μορφήν, ἥτις σχεδὸν οὐδέποτε χρῆται.
Παράδειγμα τῆς ἀπαντήσεως, ἐὰν ἡ εἴσοδος εἴη "γάτα" (μὴ φεὺρε νέαν λέξιν): "ἡ γάτα, τῆς γάτης, τῇ γάτῃ, τὴν γάταν, αἱ γάται, τῶν γατῶν, ταῖς γάταις, τὰς γάτας".

Πρὸς ἑκάστην μορφήν συζυγμένην, παράσχες ἰδιαζούσας προτάσεις περιέχουσας τὴν μορφήν τοῦ ὀνόματος. Ἀναγκαῖόν ἐστιν ἐκδῆλόν ποιεῖν πῶς αὕτη ἡ μορφὴ χρῆται ἐν τῷ πραγματικῷ βίῳ. Χρῆσαι ἀποσπάσμασιν ἐκ τῶν δραμάτων, ποιημάτων καὶ λογοτεχνίας, στίχοις ἐκ τῶν μελῶν, διασήμοις τίτλοις εἰδήσεων, ὀνόμασιν εἰκόνων καὶ ἄλλοις πολιτιστικοῖς συμφραζομένοις. Ἐπίλεξον τὰ ἐνδιαφέροντα.

Παράσχες τουλάχιστον 5 προτάσεις πρὸς ἑκάστην μορφήν.

Μορφὴ παραδείγματος ἀπαντήσεως:
Μορφαί: ἡ γάτα, τῆς γάτης, τῇ γάτῃ, τὴν γάταν, αἱ γάται, τῶν γατῶν, ταῖς γάταις, τὰς γάτας
# Μορφή: ἡ γάτα
- Παράδειγμα προτάσεως ἕν. [πηγή: ...]
- Παράδειγμα προτάσεως δύο
...

# Μορφή: τῆς γάτης
- Παράδειγμα προτάσεως ἕν...

...

---------------------------------------------

Παρακαλῶ συζύγνυε τὸ οὐσιαστικὸν "{{word}}" ἐν διαφόροις πτώσεσι καὶ παράσχες παραδείγματα συμφραζομένων.

---------------------------------------------

Σὺ εἶ ἐπαγγελματίας λογοτεχνικὸς μεταφραστὴς ἐκ τῆς Κοινῆς Ἑλληνικῆς εἰς τὴν Ἀγγλικήν. Παρακαλῶ μετάφρασον τὰς παρασχεθεῖσας προτάσεις.

Μορφὴ παραδείγματος ἀπαντήσεως:
1. // Πρώτη πρότασις μεταφρασθεῖσα εἰς τὴν Ἀγγλικήν
2.`
		}
	}),

	computed: {
		langPair: function() {
			return langs[Number(this.langDest)] + '-' + langs[Number(this.langSource)];
		},

		langPairModificators: function() {
			if (this.langPair === 'en-an_grc') {
				return ['poly-greek'];
			}
			return [];
		},

		langPairLabels: function() {
			if (this.langPair === 'ru-en') {
				return [
					['Archaic'],
					['British', 'American', 'Australian']
				];
			}
			if (this.langPair === 'en-an_grc') {
				return [
					['active', 'middle', 'passive'],
					['Masculine', 'Feminine', 'Neuter'],
					['Epic', 'Ionic']
				];
			}
			return [];
		},

		partsOfSpeech: function() {
			return {
				noun: 'Noun',
				'noun|idiom': 'Noun (idiom)',
				adjective: 'Adjective',
				verb: 'Verb',
				'verb|idiom': 'Verb (idiom)',
				adverb: 'Adverb',
				particle: 'Particle',
				preposition: 'Preposition',
				pronoun: 'Pronoun',
				conjunction: 'Conjunction',
				determiner: 'Determiner',
				numeral: 'Numeral',
				phrase: 'Phrase',
				collocation: 'Collocation',
				interjection: 'Interjection',
				other: 'Other',
			};
		},

		searchSentencesData_formatted: function() {
			return this.searchSentencesData.answer?.map(sentence => {
				const link = this.findLinksData.answer && this.findLinksData.answer.find(link => link.SentenceId === sentence.id);
				return {
					id: sentence.id,
					text: sentence.text,
					translation: sentence.translation,
					checked: !!link,
					wordForm: this.sentencesWordForms && this.sentencesWordForms[sentence.id] //link && link.WordForm
				};
			});
		},

		findLinksData_formatted: function() {
			return this.findLinksData.answer?.map(link => ({
				id: link.SentenceId,
				checked: true,
				text: link.SentenceText,
				translation: link.SentenceTranslation,
				wordForm: this.sentencesWordForms[link.SentenceId]
			}));
		},

		sentencesChanged: function() {
			const selected = this.sentencesSelectedIds;
			const original = this.findLinksData.answer;
			return this.selectedWordId && original && selected && (
				original.length !== selected.length ||
				original.map(x => x.SentenceId).sort().toString() !== selected.sort().toString() ||
				original.some(x => x.WordForm !== this.sentencesWordForms[x.SentenceId])
			);
		},

		wordData: function() {
			return this.searchWords.answer && this.searchWords.answer.find(word => word.id === this.selectedWordId);
		}
	},

	mounted: function() {
		const pass = localStorage.getItem('adminpass');
		if (pass) {
			this.password = pass;
			this.openaiKey = localStorage.getItem('openai_key');
			return;
		}
		this.$nextTick(() => {
			this.$refs.passwordField.focus();
		});
	},

	methods: {
		setPassword: function() {
			this.password = this.passwordInput;
			this.openaiKey = this.openaiKeyInput;
			localStorage.setItem('adminpass', this.passwordInput);
			localStorage.setItem('openai_key', this.openaiKeyInput);
		},

		checkLabel: function(text, label) {
			return new Set(text.split('|')).has(label);
		},

		setPos: function(text, pos) {
			const parts = text.split('|');
			parts[0] = pos;
			return parts.join('|');
		},

		toggleLabel: function(text, label, value) {
			const set = new Set(text.split('|'));
			if (value == null) {
				value = !set.has(label);
			}

			if (value) {
				set.add(label);
			} else {
				set.delete(label);
			}
			return [...set].join('|');
		},

		setLangPair: function(pair) {
			const [dest, source] = pair.split('-');
			this.langDest = String(langs.indexOf(dest));
			this.langSource = String(langs.indexOf(source));
		},

		reversoModalSend: async function() {
			this.reversoModalLoading = true;
			this.reversoModalResponse = JSON.stringify(
				await adminReverso({
					text: this.reversoModalText,
					langSource: Number(this.langSource),
					langDest: Number(this.langDest)
				})
			);
			this.reversoModalLoading = false;
			this.reversoModalOpen = false;
			this.searchSentencesData = {
				text: this.reversoModalText,
				type: 'text',
				like: false,
				loading: false,
				answer: null,
			};
			this.wordForm = this.reversoModalText;
			this.sendSearchSentences();
		},

		sendCreateWord: async function() {
			const { text, description, lemma, partOfSpeech, shared } = this.createWordData;

			if (text === '' || description === '' || lemma === '') {
				return;
			}

			if (text.length > 255 || description.length > 255 || lemma.length > 255) {
				alert(`Text and description and lemma must not be longer than 255 symbols. They are: ${text.length}, ${description.length}, ${lemma.length}.`);
				return;
			}

			this.createWordData.loading = true;
			const res = await adminCreateWord({
				id: this.password,
				data: [{
					text, description, shared,
					lemma: this.langPair === 'en-an_grc' ? normalizeGreekWord(lemma) : lemma,
					partOfSpeech: partOfSpeech,
					langDest: Number(this.langDest),
					langSource: Number(this.langSource)
				}]
			});
			this.createWordData.loading = false;
			this.createWordData.answer = res[0]; // 0 потому что слово создаём одно
			this.searchWords.q = text;
			this.$nextTick(() => {
				// изменение searchWords.q обнуляет его
				this.selectedWordId = res[0];
			});
		},

		sendEditWord: async function() {
			const { text, description, lemma, partOfSpeech, archaic, dialect, gender, voice, shared } = this.editWordData;

			if (text === '' || description === '' || lemma === '') {
				return;
			}

			if (text.length > 255 || description.length > 255 || lemma.length > 255) {
				alert(`Text and description and lemma must not be longer than 255 symbols. They are: ${text.length}, ${description.length}, ${lemma.length}.`);
				return;
			}

			this.editWordData.loading = true;
			const res = await adminUpdateWord({
				id: this.password,
				data: {
					id: this.selectedWordId,
					text, description, shared,
					lemma: this.langPair === 'en-an_grc' ? normalizeGreekWord(lemma) : lemma,
					partOfSpeech
				}
			});
			this.editWordData.loading = false;
			this.editWordData.answer = this.selectedWordId;
			this.searchWords.q = '';
			this.$nextTick(() => {
				this.searchWords.q = text;
				this.$nextTick(() => {
					// изменение searchWords.q обнуляет его
					this.selectedWordId = this.selectedWordId;
				});
			});
		},

		sendCreateSentence: async function() {
			const { text, translation, source, meta } = this.createSentenceData;
			console.log(text, translation, source, meta);

			if (text === '' || translation === '') {
				return;
			}

			if (text.length > 255 || translation.length > 255) {
				alert(`Text and translation must not be longer than 255 symbols. They are: ${text.length}, ${translation.length}.`);
				return;
			}

			this.createSentenceData.loading = true;
			const res = await adminCreateSentence({
				id: this.password,
				data: [{
					text, translation, source, meta,
					langDest: Number(this.langDest),
					langSource: Number(this.langSource)
				}]
			});
			this.createSentenceData.loading = false;
			this.createSentenceData.answer = res[0]; // 0 потому что предложение создаём одно

			this.searchSentencesData = {
				loading: false,
				text,
				type: 'text',
				like: false,
				answer: null
			};
			this.sendSearchSentences();
		},

		sendLink: async function() {
			const { WordId, WordForm, SentenceId } = this.createLinkData;

			if (WordId === '' || WordForm === '' || SentenceId === '') {
				return;
			}

			this.createLinkData.answer = 'Загрузка...';
			const res = await adminCreateWordSentenceLink({
				id: this.password,
				data: [{
					WordId: Number(WordId),
					WordForm,
					SentenceId: Number(SentenceId)
				}]
			});
			this.createLinkData.answer = 'Done';
		},

		sendSearchSentences: async function() {
			const { text, type, like } = this.searchSentencesData;
			if (text === '') {
				this.searchSentencesData.answer = null;
				return;
			}

			this.searchSentencesData.loading = true;
			const res = await adminSearchSentences({
				id: this.password,
				data: {
					text, type, like,
					langDest: Number(this.langDest),
					langSource: Number(this.langSource),
				}
			});
			this.searchSentencesData.loading = false;
			this.searchSentencesData.answer = res;
		},

		sendFindLinks: async function() {
			const { id, type } = this.findLinksData;

			const res = await adminFindLinks({
				id: this.password,
				data: {
					id, type
				}
			});
			this.findLinksData.answer = res;
		},

		sendRecalcWords: async function() {
			this.recalcWordsCounts.loading = true;
			await adminRecalcWordsCounts({
				id: this.password
			});
			this.recalcWordsCounts.loading = false;
		},

		changeLinkFor: function(sentenceId, sentenceText) {
			if (!this.sentencesSelectedIds) {
				console.error('Word isnt selected');
				return;
			}
			if (this.sentencesSelectedIds.includes(sentenceId)) {
				this.sentencesSelectedIds.splice(this.sentencesSelectedIds.indexOf(sentenceId), 1);
			} else {
				this.sentencesSelectedIds.push(sentenceId);
				if (this.wordData && !this.sentencesWordForms[sentenceId] && sentenceText.includes(this.wordForm)) {
					// this.sentencesWordForms[sentenceId] = this.wordData.text;
					this.$set(this.sentencesWordForms, sentenceId, this.wordForm);
				}
			}
		},

		createWordOpenModal: function() {
			this.openModalCreateWord = true;
			this.createWordData.answer = null;
			this.$nextTick(() => this.$refs.createWordInput.focus());
		},

		createWordEditModal: function() {
			this.openModalEditWord = true;
			this.editWordData.answer = null;
			this.editWordData.text = this.wordData.text;
			this.editWordData.description = this.wordData.description;
			this.editWordData.lemma = this.wordData.lemma;
			this.editWordData.shared = this.wordData.shared;
			this.editWordData.partOfSpeech = this.wordData.partOfSpeech;
			this.$nextTick(() => this.$refs.editWordInput.focus());
		},

		createSentenceOpenModal: function() {
			this.openModalCreateSentence = true;
			this.createSentenceData.answer = null;
			this.$nextTick(() => this.$refs.createSentenceInput.focus());
		},

		searchSentencesKeydown: function(event) {
			if (event.which === 13) {
				this.sendSearchSentences();
			}
		},

		saveWordLinks: async function() {
			this.updateWordLinks.loading = true;
			await adminUpdateWordLinks({
				id: this.password,
				data: {
					wordId: this.selectedWordId,
					links: this.sentencesSelectedIds.map(id => ({
						sentenceId: id,
						wordForm: this.sentencesWordForms[id]
					}))
				}
			});
			this.updateWordLinks.loading = false;
			this.updateCurrentWord();
		},

		updateCurrentWord: function() {
			const wordId = this.selectedWordId;
			this.selectedWordId = null;
			this.$nextTick(() => {
				this.selectedWordId = wordId;
			});
		},

		openaiModalRequest: async function() {
			this.openaiModalLoading = true;
			const model = 'gpt-4o-mini';
			const translateModel = 'gpt-4o';
			const [systemPrompt, userPrompt, translationSystemPrompt] = this.openaiModalPromptText
				.split('---------------------------------------------')
				.map(x => x.trim());
			const content = await fetchOpenAIResponse(model, systemPrompt, userPrompt.split('{{word}}').join(this.openaiModalWord));
			const parsed = parseResponse(content);
			console.log('Response parsed into ', parsed)

			// need \n here for 1. to be found as \n1.
			const sentencesToTranslate = parsed.formsOrder.flatMap(form => parsed.forms[form]).map((sentence, i) => `${i + 1}. ${sentence}`).join('\n');
			if (sentencesToTranslate === '') {
				console.log('Nothing sent to translator as nothing found');
				return;
			}
			const translations = await fetchOpenAIResponse(translateModel, translationSystemPrompt, sentencesToTranslate);
			console.log('Translations parsed into', { translations });

			const sentencesTranslated = parseTranslationResponse(translations);

			let globalIndex = 0;
			this.openaiModalResponse = parsed.formsOrder.map(form => {
				if (!parsed.forms[form]) {
					console.error('The form ' + form + ' isnt found among ' + Object.keys(parsed.forms));
					return {
						form,
						sentences: [],
					};
				}
				return {
					form,
					sentences: parsed.forms[form].map(sentence => ({
						active: sentence.toLowerCase().includes(form.toLowerCase()),
						sentence,
						translation: sentencesTranslated[globalIndex++],
						word: form,
					}))
				};
			});
			this.openaiModalLoading = false;

			function parseResponse(text) {
				// Extract forms order
				const formsOrder = [...new Set(
					text.split('\n')[0]
						.replace(/^[\p{L}]+\s?:/ui, '')
						.split(',')
						.map(form => form.trim())
				)];

				// Extract sentences for each form
				const forms = {};
				let currentForm = null;

				text.split('\n').forEach(line => {
					const formMatch = line.match(/#\s?[\p{L}]+\s*:\s*([\p{L}\s]+)/ui);
					if (formMatch) {
						currentForm = formMatch[1].trim();
						if (!forms[currentForm]) {
							// there may be 2 same forms
							forms[currentForm] = [];
						}
					} else if (currentForm && line.startsWith('- ')) {
						const regexp = /- (["“”«»„‚‘’‹›]?.*["“”«»„‚‘’‹›]?)/;
						const sentenceMatch = line.match(regexp);
						if (sentenceMatch) {
							const sentence = sentenceMatch[1].trim().replace(/^["“”«»„‚‘’‹›]/, '').replace(/["“”«»„‚‘’‹›]$/, '').trim();
							forms[currentForm].push(sentence);
						} else {
							console.log(`Line "${line}" didn't match to ${regexp}`);
						}
					}
				});

				return {
					formsOrder,
					forms
				};
			}

			function parseTranslationResponse(text) {
				text = '\n' + text;
				return text.match(/\n\d+\.\s*/gi).map(part => {
					return text.slice(text.indexOf(part) + part.length).split('\n')[0].trim();
				});
			}
		},

		openaiModalSave: async function() {
			if (!this.openaiModalResponse) {
				return;
			}
			
			if (!window.confirm('You sure you wanna add sentences into lango?')) {
				return;
			}

			const sentencesToAdd = this.openaiModalResponse.flatMap(({ form, sentences }) => {
				return sentences.filter(({ active, sentence, translation }) => active && sentence.length <= 255 && translation.length <= 255 ).map(({ sentence, translation }) => ({
					text: sentence,
					translation,
					source: 6,
					meta: 1,
					langDest: Number(this.langDest),
					langSource: Number(this.langSource),
				}));
			});

			if (sentencesToAdd.length === 0) {
				this.openaiModalOpen = false;
				return;
			}

			this.openaiModalLoading = true;
			const sentencesIds = await adminCreateSentence({
				id: this.password,
				data: sentencesToAdd,
			});
			this.openaiModalLoading = false;

			const sentencesData = sentencesToAdd.map(({ text, translation }, i) => {
				return {
					id: sentencesIds[i],
					text, translation
				}
			});

			this.sentencesWordForms = {};
			const sentencesIdsByText = new Map(sentencesToAdd.map(({ text }, i) => [text, sentencesIds[i]]));
			this.openaiModalResponse.forEach(({ sentences }) => {
				sentences.forEach(({ sentence, word }) => {
					this.sentencesWordForms[sentencesIdsByText.get(sentence)] = word;
				});
			})

			this.searchSentencesData.loading = false;
			this.searchSentencesData.answer = sentencesData;
			this.openaiModalResponse = null;
			this.openaiModalOpen = false;
		},

		openaiUpdateFormFor: function(item) {
			item.word = window.prompt(`Input new form for the sentence "${item.sentence}"`, item.word);
		}
	},

	watch: {
		"searchWords.q": async function(newVal) {
			// this.selectedWordId = null;
			if(newVal === ''){
				this.searchWords.answer = null;
				return;
			}

			this.searchWords.answer = null;
			this.searchWords.loading = true;
			try {
				const data = await searchWordsDebounced(newVal, {
					langDest: Number(this.langDest),
					langSource: Number(this.langSource)
				}, this.password);
				// когда запрос завершён, уже всё могло измениться
				if (this.searchWords.q === newVal) {
					this.searchWords.answer = data;
				}
			} catch (e) {
				if (this.searchWords.q === newVal) {
					this.searchWords.answer = [];
				}
			} finally {
				this.searchWords.loading = false;
			}
		},

		selectedWordId: async function(newVal) {
			if (newVal === null) {
				this.findLinksData.loading = false;
				this.findLinksData.answer = null;
				return;
			}

			this.findLinksData.loading = true;
			const res = await adminFindLinks({
				id: this.password,
				data: {
					id: newVal,
					type: 'word'
				}
			});
			this.findLinksData.loading = false;
			this.findLinksData.answer = res;
			// this.searchSentencesData.answer = null;
			this.sentencesSelectedIds = res.map(link => link.SentenceId);
			this.sentencesWordForms = {};
			res.forEach(link => {
				this.$set(this.sentencesWordForms, link.SentenceId, link.WordForm);
			});
		},

		"createWordData.text": function(newVal) {
			this.createWordData.lemma = newVal;
		},

		"editWordData.text": function(newVal) {
			this.editWordData.lemma = newVal;
		},

		"wordData.text": function(newVal) {
			this.wordForm = newVal;
		}
	},

	components: {
		Page,
		TextField,
		Button,
		Modal,
		ModalMarkup,
		WordsList,
	},
};

async function fetchOpenAIResponse(modelName, systemPrompt, userPrompt) {
  const apiKey = localStorage.getItem('openai_key');
  const apiUrl = 'https://api.openai.com/v1/chat/completions';

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${apiKey}`,
  };

  const payload = {
    model: modelName,
    messages: [
      { role: 'system', content: systemPrompt },
      { role: 'user', content: userPrompt },
    ],
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    const answer = data.choices[0].message.content;
  	console.log(`Request to ${modelName} with`, { systemPrompt, userPrompt }, '→', { answer })
    return answer;
  } catch (error) {
    console.error('Error fetching OpenAI response:', error);
    return null;
  }
}
</script>

<style lang="stylus" scoped>
.admin-page
	.admin-page-content
		display grid
		grid-template-columns 400px auto
		column-gap 40px
		height 100%

	.admin-page-words
		padding-left 40px
		display subgrid
		grid-template-rows auto 1fr
		.words
			height calc(50vh + 40px)

	.admin-page-sentences
		padding-right 40px
		.sentences
			label
				border-bottom solid 1px #ddd
				cursor pointer
				display grid
				grid-template-columns 40px auto
				grid-template-areas "id text" "id translation" "input input"
			label:last-child
				border-bottom none
			label.checked
				background #ddd
			.id
				grid-area id
				font-size 9px
				text-align center
				padding-top 15px
			.text
				grid-area text
				padding-left 10px
				padding-bottom 5px
				padding-top 15px
				font-weight 600
			label.checked .text
				color #888
				u
					color #000
			.translation
				grid-area translation
				padding-left 10px
				padding-bottom 15px
				padding-top 5px
				font-style italic
				font-weight 300
			.input
				grid-area input
				padding-left 50px
				padding-right 5px
				padding-bottom 5px

	.sentences-search .additional-controls
		padding 10px 0
		display grid
		grid-template-columns 190px 124px 1fr auto
		line-height 35px
		label
			margin-right 10px
			cursor pointer
	
	.sentences-from-search
		padding-bottom 35px
		margin-bottom 35px
		border-bottom dashed 1px #ccc

.create-word-markup, .create-sentence-markup
	span:first-child
		margin-top 0
	span
		margin-top 7px
		display block
	select
		margin-top 7px

	// ---
	.block
		text-align center
		display block

	.block-content
		display inline-block
		width 400px
		border-bottom 1px solid #ccc
		padding-top 40px
		padding-bottom 40px

	span
		margin-bottom 10px
		display block

	.text-field-container
		margin-bottom 10px

	.answer
		margin-top 20px
		background rgba(0, 0, 0, 0.05)
		border solid 1px rgba(0, 0, 0, 0.1)
		padding 10px
		text-align left

.openai-request
	width 100%
	height 280px
	border solid 1px #aaa
	padding 20px
	box-sizing border-box
	border-radius 2px
	margin-bottom 10px

.openai-sentence
	display block
	display grid
	grid-template-columns 50px auto
	grid-template-areas "input text" "input translation"
	padding 3px
	border-bottom 1px solid #aaa
	&.active
		background #eee

	.input
		display flex
		justify-content center
		grid-area input
	.openai-text
		font-weight bold
		grid-area text
		padding 3px 0
		color #666
		u
			color black
	.openai-translation
		font-style italic
		grid-area translation
		padding 3px 0
		opacity 0.8
</style>
