<template>
	<div :class="[
		'text-field-container',
		'partof_' + partof,
		'size_' + size,
		'design_' + design,
		'placeholder_' + placeholderStyle,
		rightIcon && 'right-icon_' + rightIcon
	]">
		<input
			:class="['text-field', textFieldClass]"
			:value="value"
			@input="$emit('input', modifiersProcess($event.target.value))"
			@focus="$emit('focus', $event)"
			@blur="$emit('blur', $event)"
			@keypress="$emit('keypress', $event)"
			@keydown="$emit('keydown', $event)"
			:placeholder="placeholder"
			:type="type"
			:disabled="disabled"
			:autofocus="autofocus"
			:autocomplete="autocomplete"
			:autocapitalize="autocapitalize"
			:maxlength="maxlength"
			ref="input"
		/>
		<div v-if="hint" :class="['text-field-hint', 'design_' + hintDesign]">
			{{ hint }}
		</div>
		<div v-if="$slots.righticon" class="slot-righticon">
			<slot name="righticon" />
		</div>
	</div>
</template>

<script>
import icons from '@/assets/icons';

import polyGreekModifier from '@/lib/poly-greek-modifier';
const modifiers = {
	'poly-greek': polyGreekModifier
};

export default {
	name: 'TextField',
	props: {
		value: String,
		placeholder: String,
		type: {
			type: String,
			default: 'text',
		},
		design: {
			validator: val =>
				['default', 'main-page', 'bottom-line', 'v2-search-words'].includes(val),
			default: 'default',
		},
		placeholderStyle: {
			type: Number,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		autocapitalize: {
			type: String
		},
		autocomplete: String,
		modifiers: {
			type: Array,
			validator: val => val.every(x => ['poly-greek'].includes(x))
		},
		maxlength: Number,

		rightIcon: {
			type: String,
			validator: val => Object.keys(icons).includes(val),
		},

		size: {
			validator: val => ['small', 'medium', 'big'].includes(val),
			default: 'medium',
		},
		partof: {
			validator: val => ['top', 'middle', 'bottom', 'nothing'].includes(val),
			default: 'nothing',
		},
		hint: String,
		hintDesign: {
			validator: val => ['default', 'action', 'error'].includes(val),
			default: 'default',
		},
		textFieldClass: {
			type: String,
		}
	},

	computed: {
		rightIconSvg: function() {
			const icon = this.rightIcon;
			if (!icon) {
				return;
			}
			;
		}
	},

	methods: {
		focus: function() {
			this.$refs.input?.focus();
		},

		blur: function() {
			this.$refs.input?.blur();
		},

		modifiersProcess: function(input) {
			if (!this.modifiers) {
				return input;
			}
			return this.modifiers.reduce((acc, cur) => modifiers[cur](acc), input);
		}
	},
};
</script>

<style lang="stylus">
@import "./style.styl";

.text-field-container
	width 100%
	display grid
	grid-template-columns 100% 200px
	position relative
	&.partof_top
		.text-field
			border-bottom-left-radius 0
			border-bottom-right-radius 0
	&.partof_middle
		.text-field
			border-radius 0
			border-top 0
	&.partof_bottom
		.text-field
			border-top-left-radius 0
			border-top-right-radius 0
			border-top 0
	&.size_medium
		.text-field
			font-size 16px
			line-height 33px
		.text-field-hint
			line-height 35px
	&.size_big
		.text-field
			font-size 20px
			line-height 41px
		.text-field-hint
			line-height 43px
	&.size_small
		.text-field
			font-size 14px
			line-height 25px
		.text-field-hint
			line-height 27px

.text-field
	width 100%
	box-sizing border-box
	font-family main-font
	outline 0
	&::placeholder
		font-weight 300

.design_v2-search-words
	.text-field
		border solid 1px stroke
		border-radius 12px
		color text-secondary
		font-size 14px !important
		line-height 20px
		padding 6px 16px
	.text-field:focus
		border-color main-color
		box-shadow inset 0 0 0 1px main-color

.design_default .text-field
	padding 0 15px
	background white
	border solid 1px #D2D2D2
	border-radius 4.8px

.design_main-page .text-field
	font-size 36px !important
	padding 12px 20px
	padding-bottom 14px
	font-weight 400
	background white
	// mask-image: paint(squircle)
	// --squircle-radius: 7px;
	// --squircle-smooth: 0.8;
	border 0
	box-shadow 0px 0px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -7px rgba(0,0,0,0.1),0px 10px 100px 42px rgba(0,0,100,0.1)
	border-radius 1rem
	&::placeholder
		color #aaa
	&:focus
		box-shadow 0 0 2px rgba(0, 0, 0, 0.5), 0px 0px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -7px rgba(0,0,0,0.1),0px 10px 100px 42px rgba(0,0,100,0.1)

.placeholder_1 .text-field::placeholder
	color #C6A78F
	padding-left 7px

.placeholder_2 .text-field::placeholder
	color rgba(11, 142, 171, 0.5)
	padding-left 7px

.placeholder_3 .text-field::placeholder
	color #A0B3A8
	padding-left 7px

.slot-righticon
	position absolute
	right 0

.text-field-hint
	font-family main-font
	word-break keep-all
	padding-left 10px
	min-width: 300px
	text-align left
	&.design_action
		color main-color-darker
	&.design_error
		color main-red
</style>
