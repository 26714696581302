import { createCourse, addCourseToFavorite } from '@/lib/api';
import { createLoadableModel } from './loadable-model';

export default createLoadableModel(async data => {
	const id = await createCourse({
		title: data.name,
		avatar: data.avatar,
		shared: data.shared,
		topic: data.topic
	});
	addCourseToFavorite(id, true);
	return id;
});
