<template>
	<div
		class="horizontal-menu"
	>
        <div class="menu top-menu">
            <a
                v-for="item in items"
                :key="item.id"
                :class="['menu-item', selectedItem === item.id && 'active']"
                @click="goToLink(item)"
            >
                <Icon :type="item.icon" class="icon" />
                <div class="text">{{ item.text }}</div>
            </a>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo';
import Icon from '@/components/Icon';

export default {
	name: 'HorizontalMenu',
	inject: ['i18n'],
	props: {
        selectedItem: {
            type: String,
            required: true
        } 
	},
    data: function () {
        return {
            items: [{
                id: 'home',
                icon: 'home',
                text: this.i18n('menu', 'home')
            }, {
                id: 'learned-words',
                icon: 'myWords',
                text: this.i18n('menu', 'myWords')
            }, {
                id: 'bookmarks',
                icon: 'bookmarks',
                text: this.i18n('menu', 'bookmarks')
            }]
        };
    },
    methods: {
        goToLink(item) {
			this.$router.push('/' + item.id);
        }
    },
	components: {
        Icon,
		Logo,
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.horizontal-menu
    position fixed
    z-index 10
    bottom 0
    width 100%

.menu
    height 52px
    background white
    border-top solid 1px #dcdcdc
    display flex
    flex-direction row
    justify-content space-around
    align-items center
    box-sizing border-box
    padding-bottom 2px

.menu-item
    text-align center
    font-size 10px
    font-weight 300
    color #656565
    .icon
        color #DCDCDC
        margin-bottom 4px
    .active
        color #292929

/* 
.menu-item
    cursor pointer
    font-size 14px
    padding 0 16px
    margin-bottom 8px
    display block
    height 40px
    color text-secondary
    display flex
    align-items center
    &.active, &:hover
        color text-primary

    .icon
        margin-left 12px
        margin-right 10px

.bottom-menu
    position absolute
    bottom 40px */
</style>
