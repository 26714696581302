<template>
	<div id="hanzi-writer" ref="main">
	</div>
</template>

<script>
const HanziWriter = require('hanzi-writer').default;
// через highlightStroke и подсчёт уже нарисованных строк
// можно сделать подсказку

export default {
	name: 'HanziWriter',
	props: {
		text: String,
		quizMode: Boolean,
		quizLevel: Number,
	},
	
	data: () => ({
		showModeProps: {
			width: 100,
			height: 100,
			padding: 5,
			showOutline: false,
			radicalColor: '#5BA442'
		},

		quizModeProps: {
			width: 200,
			height: 200,
			padding: 5,
			radicalColor: '#5BA442'
		}
	}),

	mounted: function () {
		if (this.text.match(/[a-z]/)) {
			return;
		}
		const main = this.$refs.main;
		main.innerHTML = '';
		
		let currentAnimationIndex = 0;
		const writers = this.text.split('').map((symbol, i) => {
			const div = document.createElement('div');
			div.style.border = 'solid 1px #ccc';
			div.style.display = 'inline-block';
			div.style.borderRadius = '2px';
			div.style.borderCollapse = 'collapse';
			main.appendChild(div);

			const quizSizes = [200, 150, 100];
			const writer = HanziWriter.create(div, symbol, this.quizMode ? {
				...this.quizModeProps,
				showCharacter: false,
				showOutline: this.quizLevel === 0,
				showHintAfterMisses: this.quizLevel + 1,
				width: quizSizes[this.quizLevel],
				height: quizSizes[this.quizLevel],
				onComplete: onQuizComplete
			} : {
				...this.showModeProps
			});

			if (this.quizMode) {
				writer.quiz();
			} else {
				writer.hideCharacter();
				if (i === 0) {
					writer.animateCharacter({
						onComplete: onAnimationComplete
					});
				}
			}
			return writer;
		});

		const that = this;
		let completedQuizzes = 0;
		function onQuizComplete() {
			completedQuizzes++;
			if (completedQuizzes === writers.length) {
				that.$emit('quizCompleted');
			}
		}
		function onAnimationComplete() {
			currentAnimationIndex++;
			if (writers[currentAnimationIndex]) {
				writers[currentAnimationIndex].animateCharacter({
					onComplete: onAnimationComplete
				});
			} else {
				that.$emit('animationComplete');
			}
		}
	},
};
</script>
