<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:height="height"
		:width="width"
		:viewBox="`0 0 ${width} ${height}`"
		:class="['icon', 'icon_' + width + '_' + height]"
		@click="$emit('click')"
		v-once
	>
		<path
			v-for="path in paths"
			:key="path.d"
			:d="path.d"
			:fill="aiGradient ? 'url(#ai_gradient)' : path.fill"
			:fill-rule="path.fillRule"
			:clip-rule="path.clipRule"
			:transform="margin ? `translate(${margin[0]}, ${margin[1]})` : ''"
		/>

		<linearGradient v-if="aiGradient" id="ai_gradient" x1="10.9995" y1="-6" x2="-13.3125" y2="8.46227" gradientUnits="userSpaceOnUse">
			<stop stop-color="#FF00F5"/>
			<stop offset="1" stop-color="#0037FA"/>
		</linearGradient>
	</svg>
</template>

<script>
import icons from '@/assets/icons';

export default {
	name: 'Icon',
	props: {
		type: {
			validator: val => icons[val] !== undefined,
		},
		aiGradient: Boolean
	},
	data: ({ type }) => ({
		paths: icons[type].paths,
		margin: icons[type].margin,
		width: icons[type].width,
		height: icons[type].height
	}),
};
</script>
