<template>
	<Paranja :open="open" paranjaClass="modal-paranja v2" @click="$emit('paranjaClick')">
		<!-- не забываем про stopPropagation, чтобы клик по паранже не прилетал -->
		<div
			:class="['modal', loading && 'loading']"
			:style="{
				width: modalWidth + 'px',
			}"
			@click="$event.stopPropagation()"
		>
			<Spinner v-if="loading" />
			<slot v-else />
		</div>
	</Paranja>
</template>

<script>
import Paranja from '@/components/Paranja';
import Spinner from '@/components/Spinner';

export default {
	name: 'Modal',
	props: {
		open: {
			type: Boolean,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		width: {
			type: Number,
		},
		maximumWidth: {
			type: Number,
		},
		widthPageMargin: {
			type: Number,
		},
	},
	data: (props) => ({
		modalWidth: props.width !== undefined ?
			props.width :
			Math.min(props.maximumWidth, window.innerWidth - props.widthPageMargin),
	}),
	mounted: async function() {
		window.addEventListener('keydown', this.checkIfEnterPressed);
		window.addEventListener('resize', this.updateModalWidth);
		window.addEventListener('deviceorientation', this.updateModalWidth);
	},

	destroyed: function() {
		window.removeEventListener('keydown', this.checkIfEnterPressed);
		window.removeEventListener('resize', this.updateModalWidth);
		window.removeEventListener('deviceorientation', this.updateModalWidth);
	},
	methods: {
		checkIfEnterPressed(event) {
			if (event.keyCode === 13) {
				this.$emit('enterPressed');
			}
		},
		updateModalWidth: function() {
			this.modalWidth = Math.min(this.maximumWidth, window.innerWidth - this.widthPageMargin);
		}
	},
	components: {
		Paranja,
		Spinner,
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.modal-paranja.v1
	display grid
	align-items center
	.modal
		background white
		border-radius 7px
		display inline-block
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		&.loading
			padding 50px 0
			text-align center

.modal-paranja.v2
	.modal
		background white
		position absolute
		right 0
		top 0
		height 100%
		display grid
		overflow hidden
		&.loading
			align-items center
			justify-content center		
</style>
