import { render, staticRenderFns } from "./Badge.vue?vue&type=template&id=e39f3e20&scoped=true"
import script from "./Badge.vue?vue&type=script&lang=js"
export * from "./Badge.vue?vue&type=script&lang=js"
import style0 from "./Badge.vue?vue&type=style&index=0&id=e39f3e20&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e39f3e20",
  null
  
)

export default component.exports