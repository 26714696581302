<template>
	<portal to="portal-main" v-if="open">
		<div :class="['paranja', paranjaClass]" @click="$emit('click')">
			<slot />
		</div>
	</portal>
</template>

<script>
export default {
	name: 'Paranja',
	props: {
		open: {
			type: Boolean,
			required: true,
		},
		paranjaClass: String,
	},
	methods: {
		processParanjaStateChange() {
			this.$nextTick(() => {
				if (document.querySelector('.paranja')) {
					document.body.classList.add('paranja-open');
				} else {
					document.body.classList.remove('paranja-open');
				}
			});
		}
	},
	mounted() {
		this.processParanjaStateChange();
	},
	unmounted() {
		this.processParanjaStateChange();
	},
	destroyed() {
		this.processParanjaStateChange();
	},
	watch: {
		open(newValue) {
			this.processParanjaStateChange();
		}
	}
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.paranja
	background rgba(0, 0, 0, 0.2)
	position fixed
	z-index 5
	top 0
	left 0
	width 100%
	height 100%
</style>
