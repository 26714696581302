<template>
	<div class="fancy-explanation">
		<div class="first-list" ref="firstList">
			<div class="first-list-item">
				It doesn't
			</div>
			<div class="first-list-item">
				You have to
			</div>
			<div class="first-list-item">
				We want to
			</div>
		</div>
		<div class="main-part">make</div>
		<div class="second-list" ref="secondList">
			<div class="second-list-item">
				sense
			</div>
			<div class="second-list-item">
				a choice
			</div>
			<div class="second-list-item">
				an offer
			</div>
		</div>
	</div>
</template>

<script>
// todo: move under MainPage
import {animate, easeOutCirc} from '@/lib/animation';

const ANIMATION_DURATION = 750;
const ANIMATION_INTERVAL = 3000;
const ROW_HEIGHT = 50;

export default {
	name: 'FancyExplanation',
	inject: ['i18n'],

	data: () => ({
		activeItem: 0,
		itemsCount: 3,
		animationId: null
	}),

	methods: {
		nextItem: function() {
			const newActiveItem = this.activeItem === this.itemsCount - 1 ? 0 : this.activeItem + 1;
			const newScrollPosition = newActiveItem * ROW_HEIGHT;
			this.activeItem = newActiveItem;
			this.animateElement(this.$refs.firstList, newScrollPosition);
			this.animateElement(this.$refs.secondList, newScrollPosition);
		},

		animateElement: function(element, scrollPosition) {
			animate(element.scrollTop, scrollPosition, ANIMATION_DURATION, easeOutCirc, (value) => {
				element.scrollTop = value;
			});
		},
	},

	mounted: function() {
		this.animationId = setTimeout(() => {
			this.nextItem();
			this.animationId = setInterval(this.nextItem, ANIMATION_INTERVAL);
		}, ANIMATION_INTERVAL / 2);
	},

	destroyed: function() {
		clearInterval(this.animationId);
	},
};
</script>

<style lang="stylus" scoped>
.fancy-explanation
	font-size 36px
	font-family 'Josefin Sans', 'Berlin Sans FB', Lato, Montserrat, Roboto
	.first-list,
	.main-part,
	.second-list
		display inline-block

.first-list, .main-part, .second-list
	height 40px
	overflow hidden
	line-height 50px

.first-list
	margin-right 35px
	text-align right
	font-weight 100

.second-list
	margin-left 35px
	line-height 50px
	text-align left
	font-weight 100

.first-list-item:first-child,
.second-list-item:first-child
	color #C6A78F

.first-list-item:nth-child(2),
.second-list-item:nth-child(2)
	color #0B8EAB

.first-list-item:nth-child(3),
.second-list-item:nth-child(3)
	color #A0B3A8
	// color #AB150B
</style>
