<template>
	<div class="container" :title="i18n('courseList', 'total')(total)">
        <div class="progress-bar" :style="style" />
        <div class="labels">
            <div class="positive-label">{{i18n('courseList', 'learned')(fullyLearned)}}</div>
            <div class="warning-label">{{i18n('courseList', 'toRepeat')(totalLearned - fullyLearned)}}</div>
            <div class="empty-label">{{i18n('courseList', 'toLearn')(total - totalLearned)}}</div>
        </div>
	</div>
</template>

<script>
export default {
	name: 'CourseProgressBar',
	inject: ['i18n'],
    props: {
        fullyLearned: Number,
        totalLearned: Number,
        total: Number,
    },
    computed: {
        style: function() {
            const p1 = this.fullyLearned / this.total;
            const p2 = this.totalLearned / this.total;

			const progress1 = Math.floor(p1 * 100);
            const progress2 = Math.floor(p2 * 100);

			const color1 = '#5FAA44';
			const color2 = '#FEAD39';
            const color3 = '#F6F6F6';

            if (this.total === 0) {
                return color3;
            }

			return {
				backgroundImage: `linear-gradient(90deg, ${color1} ${progress1 -
					0.1}%, ${color2} ${progress1}%, ${color2} ${progress2 - 0.1}%, ${color3} ${progress2}%)`,
			};
		},
    }
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.container
    width 100%

.progress-bar
    width 100%
    background #F6F6F6
    height 8px
    border-radius 8px

.labels
    display flex
    justify-content space-between
    font-size 12px
    line-height 16px
    font-weight 400
    margin-top 8px

.positive-label
    color #5FAA44

.warning-label
    color #FEAD39

.empty-label
    color #656565
    font-weight 300
</style>
