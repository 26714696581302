import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import courseListAll from './course-list-all';
import courseListMine from './course-list-mine';
import courseShow from './course-show';
import courseTraining from './course-training';
// import courseEdit from './course-edit';
import courseGetProps from './course-get-props';
import courseEditProps from './course-edit-props';
import courseCreate from './course-create';

import favorites from './favorites';
import myLearnedWords from './my-learned-words';
import userTrainingStats from './user-training-stats';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		lang: 'ru',
	},
	mutations: {},
	actions: {},
	modules: {
		user,
		courseListAll,
		courseListMine,
		courseCreate,
		courseGetProps,
		courseEditProps,
	//	courseEdit,
		courseShow,
		courseTraining,
		myLearnedWords,
		userTrainingStats,
		favorites,
	},
	strict: process.env.NODE_ENV !== 'production',
});
