<template>
	<div class="words-list">
		<Switcher
			:options="switcherOptions"
			:value="currentCategory"
			@input="$emit('changeCategory', $event)"
		/>
		<div
			:class="['category-list', 'show-delete_' + showDelete]"
			v-for="categoryName in availableCategories"
			v-show="currentCategory === categoryName"
			:key="categoryName"
		>
			<div
				v-for="word in wordsPrepared[categoryName]"
				:key="word.id"
				:class="['word-item', word.removed && 'removed']"
			>
				<div class="word" :title="word.transcription">{{ word.wordShowText }}</div>
				<div class="description" :title="word.description">{{ word.description }}</div>

				<a class="delete-icon" v-if="showDelete" @click="$emit('delete', word)">
					<Icon type="crosshair" />
				</a>
				<i v-if="word.removed" style="grid-column: 2/3; font-size: 12px; padding-top: 8px">{{ i18n('editCourse', 'wordWillBeRemoved') }}</i>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@/components/Icon';
import Switcher from '@/components/Switcher';

const CATEGORY_ORDER = [
	'verb',
	'noun',
	'adjective',
	'adverb',
	'pronoun',
	'phrase',
	'idiom',
	'numeral',
	'determiner',
	'conjunction',
	'preposition',
	'particle',
	'interjection',
	'collocation',
	'other'
];

export default {
	name: 'WordsList',
	inject: ['i18n'],
	props: {
		words: Array,
		showDelete: Boolean,
		removedWordsIds: Array,
		chosenCategory: String,
	},
	computed: {
		wordsPrepared() {
			const wordsSortedByPartsOfSpeech = {};
			CATEGORY_ORDER.forEach(categoryName => wordsSortedByPartsOfSpeech[categoryName] = []);

			this.words.forEach(word => {
				const [transcription, description] = (
					word.description === '-' ? ['', ''] :
					word.description.indexOf(' | ') > -1 ? word.description.split(' | ') :
					['', word.description]
				);

				const partOfSpeech = (word.partOfSpeech.split('|')[0]).toLowerCase();
				const text = partOfSpeech === 'verb' ? 'to ' + word.text : word.text;

				(wordsSortedByPartsOfSpeech[partOfSpeech] || wordsSortedByPartsOfSpeech['other']).push({
					...word,
					wordShowText: text,
					description,
					transcription,
					removed: this.removedWordsIds && this.removedWordsIds.includes(word.id),
				});
			});

			return wordsSortedByPartsOfSpeech;
		},

		availableCategories() {
			return CATEGORY_ORDER.filter(categoryName =>  this.wordsPrepared[categoryName].length > 0);
		},

		currentCategory() {
			return CATEGORY_ORDER.includes(this.chosenCategory) ? this.chosenCategory : this.availableCategories[0];
		},

		switcherOptions() {
			return this.availableCategories.map(categoryName => ({
				title: this.i18n('words', `partOfSpeech_plural_${categoryName}`),
				value: categoryName
			}));
		}
	},
	components: {
		Icon,
		Switcher,
	}
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";
.category-list
	padding 10px 0
	display grid
	row-gap 12px
	overflow-y auto

.word-item
	background #F6F6F6
	color text-primary
	padding 14px 20px
	border-radius 12px
	font-size 14px
	line-height 20px
	display grid
	grid-template-columns 150px 1fr
	.word
		font-weight 500
	.description
		font-weight 400
	&.removed
		opacity 0.5

.delete-icon
	cursor pointer
	display flex
	justify-content center
	align-items center
	height 100%
	padding 0 4px
	line-height 46px
	color #BDBDBD
	&:hover
		color #9d9d9d
	&:active
		color #7d7d7d
	svg
		width 20px

.category-list.show-delete_true .word-item
	grid-template-columns 150px 1fr auto
</style>
