<template>
	<div
		:class="[
			'button',
			'design_' + design,
			'size_' + size,
			noHorizontalMargins && 'no_horizontal_margins',
			disabled && 'disabled',
		]"
		@click="!disabled && $emit('click', $event)"
	>
		<div v-if="showSpinner" class="button-spinner-container">
			<Spinner size="small" design="action" />
		</div>
		<slot v-if="showContent" />
	</div>
</template>

<script>
import Spinner from '@/components/Spinner';

export default {
	name: 'Button',
	props: {
		size: {
			validator: val => ['small', 'medium', 'big'].includes(val),
			default: 'medium',
		},
		design: {
			validator: val =>
				['default', 'action', 'yellow', 'ghost', 'ghost-action', 'link', 'link-primary',
				'link-button', 'danger', 'google-auth', 'icon-button', 'white-button',
				'shadow-action', 'link-button-primary'].includes(val),
			default: 'default',
		},
		noHorizontalMargins: {
			type: Boolean,
		},
		showSpinner: {
			type: Boolean,
			default: false,
		},
		showContent: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Spinner,
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.button
	display inline-block
	box-sizing border-box
	cursor pointer
	padding 0 15px
	border-radius 4.8px
	color white
	font-family heading-font
	text-align center
	user-select none
	&.design_action
		background main-color
		&:active
			background main-color-darker
	&.design_yellow
		background #FEAD39
		&:active
			background darken(#FEAD39, 10)
	&.design_shadow-action
		background #F6F6F6
		color #5FAA44
		&:hover
			background #f0f0f0
		&:active
			background #eaeaea
	&.design_ghost, &.design_ghost-action
		box-shadow inset 0 0 0 1px #ddd
		color #888
		&:hover
			color #777
		&:active
			background rgba(0, 0, 0, 0.05)
		&.design_ghost-action:active
			background main-color
			box-shadow none
			color white
			transition 0.3s
	&.design_danger
		background main-red
		&:active
			background main-red-darker
	&.design_link-primary
		color main-color
	&.design_link
		color #7292f6
	&.design_link-button
		color #7292f6
	&.design_link-button:active
		color #7292f6
		background #ddd
	&.design_link-button-primary
		color main-color
	&.design_google-auth
		background white
		background-image url(../assets/google.png)
		background-repeat no-repeat
		background-size 24px 24px
		background-position 20px center
		color #555
		font-weight 600
		box-shadow 0 1px 2px rgba(0, 0, 0, 0.5)
		padding-left 49px
	&.design_google-auth:active
		background-color #eee
	&.design_icon-button
		padding 0
		flex-centrify()
		width 60px
	&.design_white-button
		background white
		color black
		box-shadow 0 0 2px rgba(0, 0, 0, 0.3)

	&.size_small
		font-size 14px
		padding 3px 10px
	&.size_medium
		font-size 16px
		line-height 20px
		padding 12px 20px
		border-radius 8px
	&.size_big
		font-size 16px
		line-height 43px
		height 43px
		border-radius 8px
		.spinner-container
			padding-top 6px
	&.design_action.disabled
		background #777
		cursor not-allowed
	&.design_ghost.disabled
		background #ddd
		cursor not-allowed
	&.design_white-button.disabled
		background #ddd
		cursor not-allowed
	&.no_horizontal_margins
		padding-left 0
		padding-right 0

.button-spinner-container
	display inline-flex
	justify-content center
	align-items center
	height 100%

.button .icon_20_20
	vertical-align sub
</style>
