<template>
	<div :class="['header', noShadow && 'no-shadow', `mode_${mode}`]">
		<div class="left">
			<slot />
		</div>
		<div class="middle"></div>
		<div class="right">
			<div :class="['userpanel', menuOpen && 'menu_open']" v-if="user" tabindex="-1" @click="$event.stopPropagation(); menuOpen = !menuOpen">
				<div class="username">
					{{user.username}}
				</div>
				<img :src="user.avatar || boringAvatar" />
			</div>
		<div class="menu" v-if="menuOpen">
			<!-- <div class="menu-item" v-if="['key', 'Keyten'].includes(user.username)" @click="polyGreek">Poly Greek</div> -->
			<!-- <div class="menu-item" @click="showLearnedWords">{{ i18n('menu', 'learnedWords') }}</div> -->
			<div class="menu-item" @click="logout">{{ i18n('menu', 'logout') }}</div>
		</div>
			<!-- <div class="ispeak" v-else>
				<div class="pre">Мой язык</div>
				<div class="lang">
					русский
					<svg
						width="11"
						height="7"
						viewBox="0 0 11 7"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.395 1.29998L9.93609 0.841064L5.50001 5.27714L1.06393 0.841064L0.605011 1.29998L5.50001 6.19498L10.395 1.29998Z"
							fill="currentColor"
						/>
					</svg>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
	name: 'Header',
	inject: ['i18n'],

	data: () => ({
		menuOpen: false
	}),

	props: {
		mode: {
			// white is used on the pages without sidebar
			// embed is used on the pages with sidebar
			// (check Page.vue)
			validator: val => ['white', 'embed'].includes(val),
			default: 'white',
		},
	},

	computed: {
		user: function() {
			return this.$store.state.user && this.$store.state.user.user;
		},

		noShadow: function() {
			return !this.user && this.$route.path === '/';
		},

		boringAvatar: function() {
			return `https://source.boringavatars.com/beam/120/${this.user.username}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`;
		}
	},

	mounted: function() {
		window.addEventListener('click', this.closeMenu);
	},

	destroyed: function() {
		window.removeEventListener('click', this.closeMenu);
	},

	methods: {
		// showLearnedWords: function() {
		// 	this.$router.push('/learned-words');
		// },

		logout: function() {
			return this.$store.dispatch('user/logout');
		},

		closeMenu: function() {
			this.menuOpen = false;
		},

		// polyGreek: function() {
		// 	window.polyGreek = true;
		// 	window.langPair = {
		// 	    langSource: 2, langDest: 0
		// 	};
		// }
	},

	components: {
		Logo
	}
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.header
	width 100%
	height 55px
	display grid
	grid-template-columns auto 1fr auto

	&.mode_white
		background #ffffff
		box-shadow 0 0 24px rgba(0, 0, 0, 0.10)
		position absolute
		z-index 3
	
	&.mode_embed
		border-bottom solid 1px stroke

	&.no-shadow
		box-shadow none

.ispeak
	font-family main-font
	font-size 16.8px
	line-height 55px
	margin-right 33.6px
	.pre
		display inline-block
		font-weight 300
	.lang
		padding-left 10px
		display inline-block
		cursor pointer
		&:hover
			color red
		svg
			width 13.2px
			height 8.4px

.userpanel
	height 55px
	line-height 55px
	display flex
	cursor pointer
	padding-left 20px
	.username
		font-family main-font
		font-size 14px
		line-height 55px
		margin-right 15px
		font-weight 500
		color #333
	img
		width 35px
		height 35px
		border-radius 100%
		margin-top 10px
		margin-right 20px
		cursor pointer

.userpanel.menu_open
	background #eee

.menu
	background white
	position absolute
	right 0px
	top 55px
	min-width 150px
	padding 5px 0
	text-align right
	border-bottom-left-radius 4px
	box-shadow 0 3px 6px rgba(0, 0, 0, 0.25)
	font-size 14px
	.menu-item
		padding 7px 20px
		cursor pointer
	.menu-item:hover
		background #fafafa
</style>
