<template>
	<Page mode="with-sidebar" selectedItem="favorites" :pageName="i18n('menu', 'bookmarks')">
		<Switcher
			:options="typeSwitcher.options"
			v-model="explanationType"
			design="buttons-elevated"
		/>

		<PageSection :title="i18n('myWords', 'myCollectionsTitle')" v-if="explanationType === 'courses'">
			<div class="courses linear">
				<div v-if="status === 'loading'" class="spinner-container">
					<Spinner size="medium" />
				</div>
				<div v-else-if="status === 'error' || !data" class="error-container">
					<p>{{ error }}</p>
					<Button design="ghost-action" @click="load()">{{ i18n('errors', 'retryButtonText') }}</Button>
				</div>
				<div v-else class="courses-list">
					<CourseBlock
						v-for="course in data.startedCourses"
						:key="course.id"
						:title="course.title === '{{my_words}}' ? i18n('courseList', 'myWords') : course.title"
						:avatar="course.avatar"
						mode="author"
						:subtitle="course.description /* i18n('courseList', 'courseSubtitle')(course.wordsCount, course.unknownWordsCount, +course.forgotCount, (+course.wordsCount) - (+course.learnedCount)) */"
						@click="goToTraining(course)"
						@edit="editCourse(course)"
						@show="showCourse(course)"
					>
						<div class="course-block-progress-bar">
							<CourseProgressBar
								:fullyLearned="Number(course.learnedCount - course.forgotCount)"
								:totalLearned="Number(course.learnedCount)"
								:total="Number(course.wordsCount)"
							/>
						</div>
					</CourseBlock>
				</div>
			</div>
		</PageSection>

		<PageSection :title="i18n('myWords', 'learningProgressTitle')" v-if="explanationType === 'courses'">
			<div class="courses">
				<div v-if="status === 'loading'" class="spinner-container" style="margin-top: 60px"><Spinner size="medium" /></div>
				<div v-else-if="status === 'error' || !data" class="error-container">
					<p>{{ error }}</p>
					<Button design="ghost-action" @click="load()">{{ i18n('errors', 'retryButtonText') }}</Button>
				</div>
				<div v-else class="courses-list">
					<CourseBlock
						v-for="course in data.courses"
						:key="course.id"
						:title="course.title === '{{my_words}}' ? i18n('courseList', 'myWords') : course.title"
						:avatar="course.avatar"
						:mode="course.authorId === userId ? 'author' : 'user'"
						:subtitle="course.description /* i18n('courseList', 'courseSubtitle')(course.wordsCount, course.unknownWordsCount, +course.forgotCount, (+course.wordsCount) - (+course.learnedCount)) */"
						@click="goToTraining(course)"
						@edit="editCourse(course)"
						@show="showCourse(course)"
					/>
				</div>
			</div>
		</PageSection>

		<PageSection :title="''" v-if="explanationType === 'explanations'">
			<div class="courses">
				<div v-if="status === 'loading'" class="spinner-container" style="margin-top: 60px"><Spinner size="medium" /></div>
				<div v-else-if="status === 'error' || !data" class="error-container">
					<p>{{ error }}</p>
					<Button design="ghost-action" @click="load()">{{ i18n('errors', 'retryButtonText') }}</Button>
				</div>
				<div v-else class="courses-list">
					<CourseBlock
						v-for="explanation in data.explanations"
						:key="explanation.id"
						:title="i18n('favorites', 'explanationTitle' + explanation.type)({})"
						mode="no-button"
						:subtitle="explanation.text.slice(0, 10)"
						@click="openExplanation(explanation)"
					/>
				</div>
			</div>
		</PageSection>

		<ModalEditCourse
			v-if="$route.query.edit || $route.query.create === 'true'"
			:mode="$route.query.create === 'true' ? 'create' : 'edit'"
			:courseId="Number($route.query.edit)"
			@closeSuccess="$router.push({ path: $route.path, query: { update: true } })"
			@close="$router.push({ path: $route.path, query: { update: $route.query.update === 'true' } })"
			@editCourseProps="$router.replace({ path: $route.path, query: { editProps: $route.query.edit, update: true } })"
		/>
		<!-- this modal doesn't seem to be needed here -->
		<!-- <ModalShowCourse
			v-if="$route.query.show"
			:courseId="Number($route.query.show)"
			@close="$router.push({ path: $route.path, query: {} })"
		/> -->
		<ModalMyWords
			v-if="$route.query.showwords === 'true'"
			@close="$router.push({path: $route.path, query: {} })"
		/>
	</Page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Page from '@/components/Page';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Switcher from '@/components/Switcher';
import Spinner from '@/components/Spinner';
import PageSection from '@/components/PageSection';
import Icon from '@/components/Icon';
import WordsGraph from '@/components/WordsGraph';

import { addCourseToFavorite } from '@/lib/api';
import {
	ModalShowCourse,
	ModalEditCourse,
	CourseBlock,
} from '@/views/course';
import ModalMyWords from './ModalMyWords';

import CourseProgressBar from '@/components/CourseProgressBar';

export default {
	name: 'FavoritesPage',

	data: function() {
		const i18n = this.i18n;
		return {
			explanationType: 'courses',
			typeSwitcher: {
				options: [
					{
						value: 'courses',
						title: i18n('favorites', 'courses'),
					},
					{
						value: 'explanations',
						title: i18n('favorites', 'explanations'),
					}
				],
			},
		};
	},

	inject: ['i18n'],

	computed: {
		...mapState('favorites', {
			status: state => state.status,
			data: state => state.data,
			error: state => state.error,
		}),

		userId: function() {
			return this.$store.state.user.user.id;
		},

		// слегка костыль: мы ждём, пока update будет true, и при
		// этом не будет открыто окно редактирования
		needToUpdate: function() {
			return (
				this.$route.query.update === 'true' &&
				this.$route.query.edit === undefined
			);
		},
	},

	mounted: function() {
		this.load();
	},

	methods: {
		...mapActions('favorites', {
			load: 'load',
		}),

		goToTraining: function(course) {
			if (!course.favorite) {
				addCourseToFavorite(course.id, true);
			}
			this.$router.push(`/course/${course.id}/training`);
		},

		editCourse: function(course) {
			this.$router.push({ path: this.$route.path, query: { edit: course.id } });
		},

		openExplanation: function(explanation) {
			console.log(explanation);
		}
	},

	watch: {
		needToUpdate: function(val) {
			if (val) {
				this.load();
			}
			this.$router.replace({
				path: this.$route.path,
				query: {},
			});
		},
	},

	components: {
		CourseBlock,
		CourseProgressBar,
		Switcher,
		Spinner,
		PageSection,
		Icon,
		WordsGraph,

		Page,
		Badge,
		Button,
		ModalEditCourse,
		ModalShowCourse,
		ModalMyWords,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.spinner-container
	text-align center
	padding-top 20px

.error-container
	text-align center
	font-size 18px
	padding-top 20px
	color error-red

	.button
		width 150px
		margin-top 20px

.course-block-progress-bar
	padding 20px 24px

oneColumnModeMax = 664px
twoColumnModeMax = 950px
threeColumnModeMax = 1216px
fourColumnModeMax = 91232px
fiveColumnModeMax = 91514px
sixColumnModeMax = 91720px
rowGap = 25px
columnGap = 20px

.courses-list
	display grid
	grid-template-columns auto auto auto auto auto auto
	row-gap rowGap
	column-gap columnGap
	justify-content center
	// margin-bottom 250px

.courses.linear
	overflow-x auto
	overflow-y hidden
	padding-bottom 12px
	scrollbar-color transparent transparent
	transition 0.3s scrollbar-color
	.courses-list
		display block
		white-space nowrap
		.course-block
			width 376px
			display inline-block
			box-sizing border-box
			white-space normal
		.course-block + .course-block
			margin-left 24px

.courses.linear:hover
	scrollbar-color unset

/*
.courses.linear
	overflow scroll
	.courses-list
		display block
		white-space nowrap
		.course-block
			display inline-block
			width 376px
			white-space normal
			box-sizing border-box
		.course-block + .course-block
			margin-left 24px
*/

@media only screen and (max-width: oneColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr
	}
}

@media only screen and (min-width: oneColumnModeMax) and (max-width: twoColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr
	}
}

@media only screen and (min-width: twoColumnModeMax) and (max-width: threeColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr
	}
}

@media only screen and (min-width: threeColumnModeMax) and (max-width: fourColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr 1fr
	}
}

@media only screen and (min-width: fourColumnModeMax) and (max-width: fiveColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr 1fr 1fr
	}
}

@media only screen and (min-width: fiveColumnModeMax) and (max-width: sixColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr 1fr 1fr 1fr 
	}
}

// categories
.section
	margin-top 40px
	margin-left 32px
	margin-right 32px
	
	.categoryTitle
		font-size 20px
		line-height 24px
		font-weight 600

	.section-menu
		margin-top 20px
		margin-bottom 32px

	.courses
		margin-top 24px
		.loading
			text-align center

	& + &
		margin-top calc(64px - 12px) // for scrollbar

.courses-filters
	display flex
	align-items center
	justify-content space-between
	.community-courses-switch
		cursor pointer
		font-size 14px
		font-weight 400
		line-height 20px
		color #292929
		.toggle-switch
			vertical-align middle
			margin-left 12px
			margin-top -1px
	.separator
		margin-left 20px
		margin-right 20px
		display inline-block
		width 0px
		height 36px
		vertical-align middle
		border solid 2px #DCDCDC
		border-radius 2px
</style>
