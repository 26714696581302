const ru = {
	/** number, [string, string, string] */
	declOfNoun: (number, words) => {
		return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
	},

	/** 12345 → 12 345 */
	formatBigNumber: (number) => {
		return (
			number.toString()
				.split('').reverse().join('')
				.replace(/(\d\d\d)/gi, '$1 ')
				.split('').reverse().join('')
		);
	},

	errors: {
		retryButtonText: 'Повторить',
	},

	login: {
		authorizeGoogle: 'Войти через Google'
	},

	menu: {
		learnedWords: 'Выученные слова',
		logout: 'Выйти',
		home: 'Главная',
		sendFeedback: 'Обратная связь',
		settings: 'Настройки',
		myWords: 'Мои слова',
		bookmarks: 'Избранное'
	},

	fileUploader: {
		chooseFile: 'Загрузите файл',
		fileFormatDescription: (formats) => {
			const formatsStr = formats.length === 1 ?
				'.' + formats[0] :
				formats.slice(0, formats.length - 1).map(x => '.' + x).join(', ') + ' или .' + formats[formats.length - 1];
			return `Это должен быть ${formatsStr}`;
		}
	},

	words: {
		partOfSpeech_plural_noun: 'Существительные',
		partOfSpeech_plural_verb: 'Глаголы',
		partOfSpeech_plural_adjective: 'Прилагательные',
		partOfSpeech_plural_adverb: 'Наречия',
		partOfSpeech_plural_pronoun: 'Местоимения',
		partOfSpeech_plural_phrase: 'Фразы',
		// partOfSpeech_plural_idiom: 'Идиомы',
		partOfSpeech_plural_numeral: 'Числительные',
		partOfSpeech_plural_determiner: 'Определители',
		partOfSpeech_plural_conjunction: 'Союзы',
		partOfSpeech_plural_preposition: 'Предлоги',
		partOfSpeech_plural_particle: 'Частицы',
		partOfSpeech_plural_interjection: 'Междометия',
		partOfSpeech_plural_collocation: 'Коллокации',
		partOfSpeech_plural_other: 'Остальные'
	},
	
	courseList: {
		favorite: 'Изучаемые',
		favoriteTitle: 'Изучаемые',
		mine: 'Мои',
		mineTitle: 'Созданные мной',
		all: 'Все',
		allTitle: 'Все курсы',
		createNew: 'Создать курс',
		myWords: 'Мои слова',
		continueLearning: 'Продолжить учиться',
		discoverCourses: 'Новые курсы',
		courseSubtitle: (total, unknown, forgotCount, unknownCount) => {
			const totalPart = `${total} ${ru.declOfNoun(total, ['слово', 'слова', 'слов'])}`;
			// todo: упростить / удалить когда не будет нужно
			if (unknown) {
				const unknownPart = ` / ${unknown} ${ru.declOfNoun(unknown, ['новое', 'новых', 'новых'])}`;
				return totalPart + unknownPart;
			} else if (forgotCount || unknownCount) {
				const forgotPart = ` / ${forgotCount} ${ru.declOfNoun(forgotCount, ['забытое', 'забытых', 'забытых'])}`;
				const unknownPart = ` / ${unknownCount} ${ru.declOfNoun(unknownCount, ['новое', 'новых', 'новых'])}`;
				return totalPart + (forgotCount ? forgotPart : '') + (unknownCount ? unknownPart : '');
			} else {
				return totalPart;
			}
		},
		categoryAll: 'Все курсы',
		categoryTopic: 'По темам',
		categoryTopicDescription: 'Например, спорт или авто',
		categoryContent: 'По фильмам и книгам',
		categoryContentDescription: 'Например, «Слова из Гарри Поттера»',
		categoryCompare: 'По сравнениям',
		categoryCompareDescription: 'Например, drown vs. sink',
		courseLevelsAll: 'Все уровни',
		courseLevel1: 'Начинающий (A1-A2)',
		courseLevel3: 'Средний (B1-B2)',
		courseLevel5: 'Продвинутый (C1+)',
		learned: num => num + ' выучено',
		toRepeat: num => num + ' повторить',
		toLearn: num => num + ' выучить',
		total: num => num + ' всего',
		showCommunityCourses: 'Показывать курсы пользователей'
	},

	showCourse: {
		noWordsInCourse: 'В этом курсе ещё нет ни одного слова.',
		startCourse: 'Изучать курс',
		listOfWords: 'Список слов',
		addToBookmarks: 'Добавить в закладки',
		removeFromBookmarks: 'Удалить из закладок',
		wordsNumber: (number) => `${number} ${ru.declOfNoun(number, ['слово', 'слова', 'слов'])}`
	},

	createCourse: {
		nameFieldPlaceholder: 'Введите название курса',
		continue: 'Добавить слова',
		cancel: 'Отмена',
		shared: 'Доступен другим для изучения',
		addAvatar: '+ Загрузить аватарку',
		avatarOpening: 'Загрузка виджета загрузки...',
		avatarWidgetError: 'Ошибка виджета загрузки',
		clearAvatar: "Удалить аватарку",
		category: 'Категория',
		categoryTopic: 'По темам',
		categoryContent: 'По фильмам и книгам',
		categoryCompare: 'По сравнениям',
		categoryOther: '--',

		createACollection: 'Создать коллекцию',
		description: 'Вы можете создать свой набор слов — ввести их вручную, загрузить файл или экспортировать слова из Memrise или Quizlet.',
		searchWords: 'Поиск слов',
		uploadAFile: 'Загрузить файл',
		importFromMemrise: 'Memrise',
		importFromQuizlet: 'Quizlet',
	},

	editCourse: {
		successfullySaved: 'Успешно сохранено.',
		searchWordPlaceholder: 'Введите слово для поиска',
		noWordsFound: 'Ничего не найдено.',
		createWord: 'Добавить это слово в словарь Lango',
		searchDescription: 'Используйте поиск чтобы найти и добавить первые слова.<br/><br/>Например: <b>talk</b>, <b>chat</b>, <b>speak</b>, <b>say</b>, <b>tell</b>, <b>jabber</b>, <b>chatter</b>.',
		closeButtonLabel: 'Хорошо',
		saveButtonLabel: 'Сохранить',
		cancelButtonLabel: 'Отмена',
		editCourseProps: 'Настройки курса',
		wordWillBeRemoved: 'Это слово будет удалено при сохранении курса',
		addedWords: 'Добавленные слова',
		step: (step, total) => `Шаг ${step}/${total}`,
		proceedButtonLabel: 'Продолжить',
		settingsButtonLabel: 'Настройки',
		goBackButtonLabel: 'Назад',
		titleInputPlaceholder: 'Например, "Слова из Гарри Поттера"',
		titleInputLabel: 'Назовите свою коллекцию:',
		descriptionInputPlaceholder: 'Например, "Слова, которые были новыми для меня, когда я смотрел(а) Гарри Поттера"',
		descriptionInputLabel: 'Опишите свою коллекцию:',
		topicLabel: 'Категория коллекции',
		topicDescription: 'Если коллекция относится к одной из категорий',
		noTopic: 'Без категории',
		levelLabel: 'Уровень коллекции',
		levelDescription: 'Уровень языка, для которого подходит коллекция',
		levelNotDefined: 'Не определён',
		privateToggleLabel: 'Сделать эту коллекцию приватной',
		privateToggleDescription: 'Приватные коллекции не будут видимы другим'
	},

	training: {
		noWordsForTraining: 'В этот курс ещё не добавлено ни одного слова. Или вы уже всё выучили.',
		noTranslation: 'Перевода нет.',
		nextButtonLabel: 'Проверить',
		tryThisWordButtonLabel: 'Попробовать',
		iKnowItButtonLabel: 'Я знаю его',
		helpButtonLabel: 'Подсказка',
		giveMeAHint: 'Дать подсказку',
		complaintSent: 'Жалоба отправлена',
		complainButtonLabel: 'Пожаловаться',
		explainButtonLabel: 'Спросить AI',
		youglishButtonLabel: 'Видео включены',
		wordButtonLabel: 'Слово',
		incorrectWordTranslation: 'Неправильный перевод слова',
		incorrectSentenceTranslation: 'Неправильный перевод предложения',
		incorrectSentence: 'Некорректное предложение',
		otherIssues: 'Другие проблемы',
		yayWeGot: item => `Ура, мы заработали ${item}`,
		feedTheCat: 'Накормить котика',
		trainAgain: 'Тренироваться ещё',
		unloggedLabel: 'Круто!',
		unloggedDescription: 'Есть способ сохранить прогресс.',
		foodWatermelon: 'арбузик',
		foodFood: 'еду',
		foodPizza: 'пиццу',
		goBackLink: 'Назад к курсам',
		explainContextHeader: 'Объяснить контекст',
		explainOriginHeader: 'Происхождение слова',
		explanationGotIt: 'Понятно!',
		explainContextMessage: (answerParts, inputWord) => `Какое слово мне нужно использовать в этом предложении:\n\n“${answerParts[0].trim()} ${new Array(answerParts[1].length).fill('_').join('')} ${answerParts[2].trim()}”?\n\nПочему мне нужно использовать это слово, а не его синонимы?${inputWord ? '\n\n' + 'Можно ли здесь использовать слово “' + inputWord.trim() + '”?' : ''}`,
		explanationContextTitle: 'Сравнить синонимы',
		explanationOriginTitle: 'Показать происхождение слова',
		explanationOriginMessage: (word) => `Какое происхождение у слова “${word}”?`
	},

	commentsPopup: {
		noComments: 'Нет комментариев',
		addCommentTextfieldPlaceholder: 'Напишите комментарий...',
		addCommentButtonText: 'Отправить',
		etymology: 'Этимология',
		joinWord: 'Комментарий к нескольким словам',
	},

	myWords: {
		myCollectionsTitle: 'Мои коллекции',
		createCollectionTitle: 'Создать коллекцию',
		createCollectionDescription: 'Добавляйте свои собственные слова',
		learningProgressTitle: 'Прогресс в изучении',
		wordsStat: (count) => `${en.formatBigNumber(count)} ${ru.declOfNoun(count, ['слово', 'слова', 'слов'])}`,
		wordsDescription: 'выучено и повторено',
		coursesStat: (count) => `${en.formatBigNumber(count)} ${ru.declOfNoun(count, ['курс', 'курса', 'курсов'])}`,
		coursesDescription: 'успешно закончено',
		secondsStat: (count) => `${count} ${ru.declOfNoun(count, ['секунда', 'секунды', 'секунд'])}`,
		minutesStat: (count) => `${count} ${ru.declOfNoun(count, ['минута', 'минуты', 'минут'])}`,
		hoursStat: (count) => `${en.formatBigNumber(count)} ${ru.declOfNoun(count, ['час', 'часа', 'часов'])}`,
		hoursDescription: 'потрачено на обучение',
		learnedWordsTitle: 'Выученные слова',
		finishedCoursesTitle: 'Законченные курсы',
		noWordsLearned: 'Не выучено ещё ни одного слова'
	},

	favorites: {
		courses: 'Курсы',
		explanations: 'Объяснения',
		explanationTitle0: (word) => `Сравнение синонимов слова ${word}`,
		explanationTitle1: (word) => `История слова ${word}`,
	}
};

const en = {
	/** number, [string, string] */
	declOfNoun: (number, words) => {
		return number === 1 ? words[0] : words[1];
	},

	/** 12345 → 12 345 */
	formatBigNumber: (number) => {
		return (
			number.toString()
				.split('').reverse().join('')
				.replace(/(\d\d\d)/gi, '$1 ')
				.split('').reverse().join('')
		);
	},

	errors: {
		retryButtonText: 'Retry',
	},

	menu: {
		learnedWords: 'Learned words',
		logout: 'Logout',
		home: 'Home',
		sendFeedback: 'Send feedback',
		settings: 'Settings',
		myWords: 'My words',
		bookmarks: 'Bookmarks'
	},

	fileUploader: {
		chooseFile: 'Choose file from your computer',
		fileFormatDescription: (formats) => {
			const formatsStr = formats.length === 1 ?
				'.' + formats[0] :
				formats.slice(0, formats.length - 1).map(x => '.' + x).join(', ') + ' or .' + formats[formats.length - 1];
			return `It should be ${formatsStr}`;
		}
	},

	words: {
		partOfSpeech_plural_noun: 'Nouns',
		partOfSpeech_plural_verb: 'Verbs',
		partOfSpeech_plural_adjective: 'Adjectives',
		partOfSpeech_plural_adverb: 'Adverbs',
		partOfSpeech_plural_pronoun: 'Pronouns',
		partOfSpeech_plural_phrase: 'Phrases',
		// partOfSpeech_plural_idiom: 'Idioms',
		partOfSpeech_plural_numeral: 'Numerals',
		partOfSpeech_plural_determiner: 'Determiners',
		partOfSpeech_plural_conjunction: 'Conjunctions',
		partOfSpeech_plural_preposition: 'Prepositions',
		partOfSpeech_plural_particle: 'Particles',
		partOfSpeech_plural_interjection: 'Interjections',
		partOfSpeech_plural_collocation: 'Collocations',
		partOfSpeech_plural_other: 'Others'
	},
	
	courseList: {
		favorite: 'Learned',
		favoriteTitle: 'Learned courses',
		mine: 'Mine',
		mineTitle: 'Created by me',
		all: 'All',
		allTitle: 'All courses',
		createNew: 'Create a course',
		myWords: 'My words',
		continueLearning: 'Continue learning',
		discoverCourses: 'Discover courses',
		courseSubtitle: (total, unknown, forgotCount, unknownCount) => {
			const totalPart = `${total} ${en.declOfNoun(total, ['word', 'words'])}`;

			if (unknown) {
				const unknownPart = ` / ${unknown} new`;
				return totalPart + (unknown ? unknownPart : '');
			} else if (forgotCount || unknownCount) {
				const forgotPart = ` / ${forgotCount} to repeat`;
				const unknownPart = ` / ${unknownCount} new`;
				return totalPart + (forgotCount ? forgotPart : '') + (unknownCount ? unknownPart : '');
			} else {
				return totalPart;
			}
		},
		categoryAll: 'All courses',
		categoryTopic: 'By topics',
		categoryTopicDescription: 'For example, sport or auto',
		categoryContent: 'By movies and books',
		categoryContentDescription: 'For example, "Words from Harry Potter"',
		categoryCompare: 'By comparison',
		categoryCompareDescription: 'For example, drown vs. sink',
		courseLevelsAll: 'All levels',
		courseLevel1: 'Beginner (A1-A2)',
		courseLevel3: 'Intermediate (B1-B2)',
		courseLevel5: 'Advanced (C1+)',
		learned: num => num + ' learned',
		toRepeat: num => num + ' to repeat',
		toLearn: num => num + ' to learn',
		total: num => num + ' total',
		showCommunityCourses: 'Show community courses'
	},

	showCourse: {
		noWordsInCourse: 'There are no words in this course yet.',
		startCourse: 'Start the course',
		listOfWords: 'List of words',
		addToBookmarks: 'Add to bookmarks',
		removeFromBookmarks: 'Remove from bookmarks',
		wordsNumber: (number) => `${number} ${en.declOfNoun(number, ['word', 'words'])}`
	},

	createCourse: {
		nameFieldPlaceholder: 'Enter course name',
		continue: 'Add words',
		cancel: 'Cancel',
		shared: 'Available for everyone',
		addAvatar: 'Upload avatar',
		avatarOpening: 'Loading upload widget...',
		avatarWidgetError: 'Upload widget error',
		clearAvatar: 'Remove avatar',
		category: 'Category',
		categoryTopic: 'By topics',
		categoryContent: 'By movies and books',
		categoryCompare: 'By comparison',
		categoryOther: '--',

		createACollection: 'Create a collection',
		description: 'You can create your own collection of words — type them manually, upload a file or export words from Memrize or Quizlet.',
		searchWords: 'Search words',
		uploadAFile: 'Upload a file',
		importFromMemrise: 'Memrise',
		importFromQuizlet: 'Quizlet',
	},

	editCourse: {
		successfullySaved: 'Saved successfully.',
		searchWordPlaceholder: 'Enter word to search',
		noWordsFound: 'Nothing found.',
		createWord: 'Add this word to Lango dictionary',
		searchDescription: 'Use search to find and add first words.<br/><br/>For example: <b>talk</b>, <b>chat</b>, <b>speak</b>, <b>say</b>, <b>tell</b>, <b>jabber</b>, <b>chatter</b>.',
		closeButtonLabel: 'Good',
		saveButtonLabel: 'Save',
		cancelButtonLabel: 'Cancel',
		editCourseProps: 'Course settings',
		wordWillBeRemoved: 'This word will be removed when you save the course',
		addedWords: 'Added words',
		step: (step, total) => `Step ${step}/${total}`,
		proceedButtonLabel: 'Proceed',
		goBackButtonLabel: 'Go back',
		titleInputPlaceholder: 'For example, "Words from Harry Potter"',
		titleInputLabel: 'Name your collection:',
		descriptionInputPlaceholder: 'For example, "Words that were new for me when I watched Harry Potter"',
		descriptionInputLabel: 'Describe your collection:',
		topicLabel: 'Collection\'s category',
		topicDescription: 'If the collection fits any of the categories',
		noTopic: 'No category',
		levelLabel: 'Collection\'s level',
		levelDescription: 'Language level this collection is made for',
		levelNotDefined: 'Not defined',
		privateToggleLabel: 'Make this collection private',
		privateToggleDescription: 'Private collections won\'t be visible to others'
	},

	training: {
		noWordsForTraining: 'There are no words in this course yet. Or you already learned everything.',
		noTranslation: 'No translation.',
		nextButtonLabel: 'Check',
		tryThisWordButtonLabel: 'Try this word',
		iKnowItButtonLabel: 'I know it',
		helpButtonLabel: 'Hint',
		giveMeAHint: 'Give me a hint',
		complaintSent: 'Complaint sent',
		complainButtonLabel: 'Complain',
		wordButtonLabel: 'Word comments',
		explainButtonLabel: 'Ask AI',
		youglishButtonLabel: 'Video enabled',
		incorrectWordTranslation: 'Incorrect word translation',
		incorrectSentenceTranslation: 'Incorrect sentence translation',
		incorrectSentence: 'Incorrect sentence',
		otherIssues: 'Other issues',
		yayWeGot: item => `Yay, we got ${item}`,
		feedTheCat: 'Feed the cat',
		trainAgain: 'Train more',
		unloggedLabel: 'Cool!',
		unloggedDescription: 'There is a way to save progress.',
		foodWatermelon: 'a watermelon',
		foodFood: 'some food',
		foodPizza: 'a pizza',
		goBackLink: 'Back to courses',
		explainContextHeader: 'Explain the context',
		explainOriginHeader: 'The word\'s origin',
		explanationGotIt: 'Got it!',
		explainContextMessage: (answerParts, inputWord) => `Which word should I use in this sentence:\n\n“${answerParts[0].trim()} ${new Array(answerParts[1].length).fill('_').join('')} ${answerParts[2].trim()}”?\n\nWhy should I use this word instead of its synonyms?${inputWord ? '\n\n' + 'Can I use the word “' + inputWord.trim() + '” here?' : ''}`,
		explanationContextTitle: 'Compare synonyms',
		explanationOriginTitle: 'Show word origin',
		explanationOriginMessage: (word) => `What's the origin of the word “${word}”?`
	},

	commentsPopup: {
		noComments: 'No comments',
		addCommentTextfieldPlaceholder: 'Write comment...',
		addCommentButtonText: 'Send',
		etymology: 'Etymology',
		joinWord: 'Comment to multiple words',
	},

	myWords: {
		myCollectionsTitle: 'My collections',
		createCollectionTitle: 'Create a collection',
		createCollectionDescription: 'Add your own words you want to learn',
		learningProgressTitle: 'Learning progress',
		wordsStat: (count) => `${en.formatBigNumber(count)} ${en.declOfNoun(count, ['word', 'words'])}`,
		wordsDescription: 'learned and repeated',
		coursesStat: (count) => `${en.formatBigNumber(count)} ${en.declOfNoun(count, ['course', 'courses'])}`,
		coursesDescription: 'finished successfully',
		secondsStat: (count) => `${count} ${en.declOfNoun(count, ['second', 'seconds'])}`,
		minutesStat: (count) => `${count} ${en.declOfNoun(count, ['minute', 'minutes'])}`,
		hoursStat: (count) => `${en.formatBigNumber(count)} ${en.declOfNoun(count, ['hour', 'hours'])}`,
		hoursDescription: 'spent on learning',
		learnedWordsTitle: 'Learned words',
		finishedCoursesTitle: 'Finished courses',
		noWordsLearned: 'You haven\'t learned any word yet'
	},

	favorites: {
		courses: 'Courses',
		explanations: 'Explanations',
		explanationTitle0: (word) => `Synonyms comparison for "${word}"`,
		explanationTitle1: (word) => `"${word}" word origin`,
	}
};

export default function(set, key) {
	const langSet = navigator.language === 'ru' ? ru : en;
	return langSet[set][key];
}