export const linear = (x) => x;
export const easeOutExpo = (x) => {
	return Math.pow(x, 6);
	// return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

export const easeOutCirc = x => {
	return Math.sqrt(1 - Math.pow(x - 1, 2));
}
	

export const swing = p => {
	return 0.5 - Math.cos( p * Math.PI ) / 2;
}

export function animate(
	from,
	to,
	duration,
	easing,
	tickFunction,
	onFinish
) {
	const start = Date.now();
	const tick = () => {
		const t = easing(Math.min((Date.now() - start) / duration, 1));
		tickFunction(from + (to - from) * t);
		if (t < 1) {
			requestAnimationFrame(tick);
		} else {
			tickFunction(to);
			if (onFinish) onFinish();
		}
	};
	requestAnimationFrame(tick);
}
