import pinyin from 'pinyin';

const reChinese = /[\u4e00-\u9fa5]/;

export const isChinese = text => reChinese.test(text);

export const chineseToPinyinMap = (hanyu, pinyin) => {
  const map = {};
  pinyin.split(' ').map((transcription, i) => {
    map[hanyu[i]] = transcription;
  });
  return map;
}

export const convertPinyinToChinese = (input, hanyu, pinyin) => {
  const chinese = pinyin.split(' ').map((transcription, i) => {
    return {
      pinyin: transcription,
      hanyu: hanyu[i]
    };
  });

  input = input.toLowerCase().replace(/\s/gi, '');
  chinese.forEach(({ hanyu, pinyin }) => {
    input = input.split(pinyin).join(hanyu);
  });
  return input;
}

export const convertChineseToPinyin = (input, hanyu, pinyin) => {
  const chinese = pinyin.split(' ').map((transcription, i) => {
    return {
      pinyin: transcription,
      hanyu: hanyu[i]
    };
  });

  chinese.forEach(({ hanyu, pinyin }) => {
    input = input.split(hanyu).join(pinyin);
  });
  return input;
}

export const convertChineseSentenceToPinyin = (sentence) => {
  return pinyin(sentence, {
    segment: "segmentit",
    group: true
  }).map(wordParts => wordParts.join('')).join(' ');
}
