<template lang="pug">
.comments-popup(
	@click="$event.stopPropagation()"
	@keydown="$event.stopPropagation()"
	@keypress="$event.stopPropagation()"
	@keyup="$event.stopPropagation()"
	@keydown.esc="$emit('close')"
	ref="commentsPopup"
)
	.arrow(:style="{ left: arrowPosition + 'px' }")
	.info-about(v-if="infoAbout && infoAbout !== '-'")
		span(v-if="infoAbout.includes(' | ')")
			i /{{ infoAbout.split(' | ')[0] }}/
			span {{ infoAbout.split(' | ')[1] }}
		span(v-else) {{ infoAbout }}

	.loading(
		v-if="loading"
		style="padding: 30px; text-align: center;"
	)
		Spinner(size="medium")

	.comment-container(v-else-if="commentsPrepared.length > 0")
		.comment(
			v-for="comment in commentsPrepared"
			:key="comment.id"
		)
			.comment-header(
				v-if="comment.header"
			) {{ comment.header }}
			.comment-text
				p(
					v-for="text in comment.text"
					:key="text"
				) {{ text }}
			.comment-data
				a.author(:href="`/users/${comment.authorId}`") {{comment.authorName}}

	.no-comments(v-else) {{ i18n('commentsPopup', 'noComments') }}

	.add-comment(v-if="!loading")
		textarea(
			v-model="commentText"
			:placeholder="i18n('commentsPopup', 'addCommentTextfieldPlaceholder')"
			@keydown.enter="addComment"
		)
		Button(
			design="ghost-action"
			@click="sendComment"
		) {{ i18n('commentsPopup', 'addCommentButtonText') }}
		.additional-words
			.additional-word(
				v-for="word in additionalWords"
				:key="word.id"
				:title="word.description"
			) {{ word.text }}
			Button(
				design="link"
				@click="joinWordModalOpen = true"
			) {{ i18n('commentsPopup', 'joinWord') }}

	WordCommentsChooseWord(
		v-if="joinWordModalOpen"
		@close="joinWordModalOpen = false"
		@wordChosen="onAddWord"
	)
</template>

<script>
import Spinner from '@/components/Spinner';
import Button from '@/components/Button';
import WordCommentsChooseWord from '@/views/WordCommentsChooseWord';
import { getWordComments, addWordComment } from '@/lib/api';
import { unique } from '@/lib/utils';

export default {
	name: 'WordCommentsPopup',

	props: {
		wordId: {
			type: Number,
			required: true,
		},
		infoAbout: {
			type: String,
		},
		anchorElement: {},
	},

	inject: ['i18n'],

	data: () => ({
		loading: false,
		comments: [],
		commentText: '',
		additionalWords: [],
		arrowPosition: 0,
		joinWordModalOpen: false
	}),

	computed: {
		commentsPrepared() {
			return this.comments.map(comment => {
				const isEtymology = comment.text.includes('{{etymology}}');
				return {
					...comment,
					header: isEtymology ? this.i18n('commentsPopup', 'etymology') : null,
					text: comment.text.split('{{etymology}}').join('').trim().split('\n\n')
				}
			});
		}
	},

	mounted: function() {
		this.loadComments();

		window.addEventListener('resize', this.updateArrowPosition);
		this.updateArrowPosition();
	},

	beforeDestroy: function() {
		window.removeEventListener('resize', this.updateArrowPosition);
	},

	methods: {
		loadComments: async function() {
			this.loading = true;
			this.comments = [];
			this.comments = await getWordComments(this.wordId);
			this.loading = false;
		},

		updateArrowPosition: function() {
			const anchorBounds = this.anchorElement.getBoundingClientRect();
			const popupBounds = this.$refs.commentsPopup.getBoundingClientRect();
			this.arrowPosition = anchorBounds.left + anchorBounds.width / 2 - popupBounds.left;
		},

		sendComment: async function() {
			this.loading = true;
			const wordsIds = unique([this.wordId, ...this.additionalWords.map(word => word.id)]);
			await addWordComment({
				text: this.commentText,
				type: 0,
				wordsIds
			});
			this.commentText = '';
			this.additionalWords = [];
			this.loadComments();
		},

		onAddWord: function(word) {
			this.joinWordModalOpen = false;
			this.additionalWords.push(word);
		}
	},

	watch: {
		wordId: function() {
			this.loadComments();
		},

		anchorElement: function() {
			this.updateArrowPosition();
		}
	},

	components: {
		Spinner,
		Button,
		WordCommentsChooseWord,
	}
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.comments-popup
	position fixed
	background white
	top 110px
	display grid
	grid-template-rows 100px auto 151px
	max-height 80vh
	overflow scroll

.arrow
	content ""
	position absolute
	border-color white
	border solid transparent
	border-bottom-color white
	border-width 11px
	margin-left -10px
	top -21px
	z-index 1

.info-about
	border-bottom solid 1px #eee
	padding 20px
	i
		margin-right 5px

// phones
@media only screen and (max-width: 720px) {
	.comments-popup {
		position fixed
		left 0
		width 100%
		height calc(100% - 110px)
	}
}

// desktops
@media only screen and (min-width: 720px) {
	.comments-popup {
		left 50%
		transform translateX(-50%)
		width 600px
		border-radius 4px
		box-shadow 0 3px 6px rgba(0, 0, 0, 0.25)
	}
}

.comment-container
	overflow auto
	max-height 80vh

.no-comments
	padding 30px
	text-align center

.comment
	margin 20px
	p + p
		margin-top 7px
	.comment-header
		margin-bottom 14px
		font-size 13pt
	.comment-data
		margin-top 20px
		font-size 14px
		a
			color #888
			text-decoration none
	.comment-text
		white-space pre-wrap

.comment + .comment
	border-top solid 1px #e6e6e6
	padding-top 20px

.comment-container + .add-comment
	border-top solid 1px #e6e6e6

.add-comment
	padding 20px
	display grid
	grid-template-columns 1fr auto
	grid-template-areas "textarea button" "words words"
	column-gap 10px
	textarea
		grid-area textarea
		display block
		border solid 3px #ddd
		padding 10px
		outline 0
		box-sizing border-box
		font-size 16px
		width 100%
		font-family main-font
		border-radius 5px
		transition 0.3s border-color
		&:focus
			border-color main-color
	& > .button
		grid-area button
	.additional-words
		grid-area words
		padding-top 10px
		text-align center
		.additional-word
			display inline-block
			padding 5px
			margin 0 5px
			border solid 1px #aaa

</style>
