<template>
	<Modal
		:open="true"
		:maximumWidth="600"
		:widthPageMargin="30"
		:loading="status === 'loading'"
		key="wordsModal"
		@paranjaClick="$emit('close')"
	>
		<div
			v-if="status === 'error' || !data"
			class="error"
		>
			{{error}}
		</div>
		<ModalContentCourse
			v-else
			:header="i18n('myWords', 'learnedWordsTitle')"
			@close="$emit('close')"
		>
			<div class="modal-contents">
				<div v-if="data.words.length === 0" class="no-words">
					{{ i18n('myWords', 'noWordsLearned') }}
				</div>
				<div v-else class="words-list">
					<WordsListV2
						:words="data.words"
						:chosenCategory="wordsListChosenCategory"
						@changeCategory="wordsListChosenCategory = $event"
					/>
				</div>
			</div>
		</ModalContentCourse>
	</Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import Modal from '@/components/Modal';
import ModalContentCourse from '@/components/ModalContentCourse.vue';
import WordsListV2 from '@/components/WordsListV2.vue';

export default {
	name: 'ModalMyWords',

	data: () => ({
		wordsListChosenCategory: null,
	}),

	inject: ['i18n'],

	computed: {
		...mapState('myLearnedWords', {
			status: state => state.status,
			data: state => state.data,
			error: state => state.error
		}),
	},

	mounted() {
		this.load();
	},

	methods: mapActions('myLearnedWords', {
		load: 'load'
	}),

	components: {
		Modal,
		ModalContentCourse,
		WordsListV2,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.modal-contents
	overflow hidden
	display grid
	grid-template-rows 1fr
	margin-top 24px

	.words-list
		display grid
		grid-template-rows auto 1fr
		overflow hidden

.no-words
	color text-secondary
	font-size 14px
	line-height 20px

.error
	display grid
	justify-content center
	align-items center
	color error-red
</style>
