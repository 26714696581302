<template>
	<div :class="['spinner', 'design_' + design, 'size_' + size]" />
</template>

<script>
export default {
	name: 'Spinner',
	props: {
		size: {
			validator: val => ['xs', 'small', 'medium', 'big'].includes(val),
			default: 'medium',
		},
		design: {
			validator: val => ['default', 'action'].includes(val),
			default: 'default',
		},
	},
};
</script>

<style lang="stylus">
@import "./style.styl";

.spinner
	display inline-block
	border-style solid
	border-left-color transparent
	border-right-color transparent
	border-radius 100%
	animation spinner 1.2s infinite cubic-bezier(0.37, 0, 0.63, 1)
	&.size_xs
		width 12px
		height 12px
		border-width 2px
	&.size_small
		width 16px
		height 16px
		border-width 3px
	&.size_medium
		width 34px
		height 34px
		border-width 5px
	&.size_big
		width 54px
		height 54px
		border-width 7px
	&.design_default
		border-top-color main-color
		border-bottom-color main-color
	&.design_action
		border-top-color white
		border-bottom-color white

@keyframes spinner {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
</style>
