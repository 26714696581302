<template>
	<Modal
		:open="true"
		:maximumWidth="600"
		:widthPageMargin="30"
		:loading="status === 'loading'"
		key="wordsModal"
		@paranjaClick="clear(); $emit('close')"
	>
		<div
			v-if="status === 'error' || !data"
			class="error"
		>
			{{error}}
		</div>
		<ModalContentCourse
			v-else
			:header="data.title"
			:description="description"
			:info="data.description"
			@close="clear(); $emit('close')"
		>
			<template>
				<div class="modal-contents">
					<h5 class="heading-h5">{{ i18n('showCourse', 'listOfWords') }}</h5>
					<div v-if="data.Words.length === 0" class="no-words">
						{{ i18n('showCourse', 'noWordsInCourse') }}
					</div>
					<div v-else class="words-list">
						<WordsListV2
							:words="data.Words"
							:chosenCategory="wordsListChosenCategory"
							@changeCategory="wordsListChosenCategory = $event"
						/>
					</div>
				</div>
			</template>
			<template #buttons>
				<div class="modal-buttons">
					<Button
						design="link-primary"
						:noHorizontalMargins="true"
						v-if="favoritesStatus === 'empty'"
						@click="addCourseToFavorites"
					>
						<Icon type="bookmark" />
						{{ i18n('showCourse', 'addToBookmarks') }}</Button>
					<Button
						design="link-primary"
						:noHorizontalMargins="true"
						v-else-if="favoritesStatus === 'favorite'"
						@click="removeCourseFromFavorites"
					>
						<Icon type="bookmark" />
						{{ i18n('showCourse', 'removeFromBookmarks') }}</Button>
					<div />
					<Button
						design="action"
					>{{ i18n('showCourse', 'startCourse') }}</Button>
				</div>
			</template>
		</ModalContentCourse>
	</Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

import ModalContentCourse from '@/components/ModalContentCourse.vue';
import WordsListV2 from '@/components/WordsListV2.vue';

import { addFavoriteCourse, removeFavoriteCourse } from '@/lib/api';

export default {
	name: 'ModalShowCourse',

	props: {
		courseId: Number,
	},

	data: () => ({
		wordsListChosenCategory: null,
		favoritesStatus: 'empty', // empty, favorite, loading, error
	}),

	inject: ['i18n'],

	computed: {
		...mapState('courseShow', {
			status: state => state.status,
			data: state => state.data,
			error: state => state.error
		}),

		...mapGetters('courseShow', [
			'wordExistsInCourse'
		]),

		description: function() {
			if (this.status !== 'success') {
				return '';
			}
			const { courseLevel, Words } = this.data;
			const parts = [];
			if (courseLevel !== 0) {
				parts.push(this.i18n('courseList', 'courseLevel' + courseLevel));
			}
			parts.push(this.i18n('showCourse', 'wordsNumber')(Words.length))
			return parts.join(' • ');
		}
	},

	mounted() {
		this.load(this.courseId);
	},

	methods: {
		...mapActions('courseShow', {
			load: 'load',
			clear: 'clear'
		}),

		addCourseToFavorites: async function() {
			this.favoritesStatus = 'loading';
			await addFavoriteCourse(this.courseId);
			this.favoritesStatus = 'favorite';
		},

		removeCourseFromFavorites: async function() {
			this.favoritesStatus = 'loading';
			await removeFavoriteCourse(this.courseId);
			this.favoritesStatus = 'empty';
		},
	},

	components: {
		Button,
		Icon,
		Modal,
		ModalContentCourse,
		WordsListV2,
	},
};
</script>

<style lang="stylus" scoped>
@import "../../components/style.styl";

.modal-contents
	overflow hidden
	display grid
	grid-template-rows auto 1fr
	.heading-h5
		font-size 16px
		line-height 20px
		font-weight 500
		margin-top 48px
		margin-bottom 16px

	.words-list
		display grid
		grid-template-rows auto 1fr
		overflow hidden

.modal-buttons
	display grid
	grid-template-columns auto 1fr auto

.no-words
	color text-secondary
	font-size 14px
	line-height 20px

.error
	display grid
	justify-content center
	align-items center
	color error-red
</style>
