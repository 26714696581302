var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
	'page',
	// zeroPadding && 'zero-padding',
	_vm.mode === 'with-sidebar' && 'with-sidebar',
	_vm.mode === 'with-header' && 'with-header',
	_vm.center && 'center',
	_vm.loading && 'loading',
	_vm.useDocumentHeight && 'useDocumentHeight'
]},[_c('div',[(_vm.mode === 'with-header')?_c('div',{staticClass:"page-container"},[_c('Header',{attrs:{"mode":"white"}},[_c('Logo')],1),_c('div',{staticClass:"page-with-header-content"},[(_vm.loading)?_c('Spinner',{attrs:{"size":"big"}}):_vm._t("default")],2)],1):_c('div',{staticClass:"page-container"},[_c('Sidebar',{attrs:{"selectedItem":_vm.selectedItem}}),_c('HorizontalMenu',{attrs:{"selectedItem":_vm.selectedItem}}),_c('div',{staticClass:"page-with-sidebar-content"},[_c('Header',{attrs:{"mode":"embed"}},[_c('h1',{staticClass:"page-name"},[_vm._v(_vm._s(_vm.pageName))])]),_c('div',{staticClass:"page-with-sidebar-content-container"},[(_vm.loading)?_c('Spinner',{attrs:{"size":"big"}}):_vm._t("default")],2)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }