<template>
	<div
		:class="[
			'file_uploader'
		]"
		@click="!loading && onClick()"
		@dragover.prevent
		@drop.prevent="onDrop"
	>
		<div v-if="loading" class="spinner-container">
			<Spinner size="small" design="action" />
		</div>
		<div v-else :class="['inner-container', dropping && 'dropping']">
			<Icon type="add" class="add-icon" />
			<div class="title">{{ i18n('fileUploader', 'chooseFile') }}</div>
			<div class="description">{{ i18n('fileUploader', 'fileFormatDescription')(formats) }}</div>
		</div>
	</div>
</template>

<script>
import Icon from '@/components/Icon';
import Spinner from '@/components/Spinner';

function openFileDialog(formats) {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = formats.map(x => '.' + x).join(',');// '.xlsx,.csv,.numbers';
    input.onchange = (event) => {
      const file = event.target.files[0];
      resolve(file);
    };
    input.click();
  });
}

export default {
	name: 'FileUploader',
	data: () => ({
		loading: false,
		dropping: false,
	}),
	inject: ['i18n'],
	props: {
		formats: {
			type: Array,
		},
	},
	components: {
		Icon,
		Spinner,
	},
	methods: {
		onClick() {
			openFileDialog(this.formats).then(this.handleFile);
		},

		onDrop(event) {
			const file = event.dataTransfer.files[0];
			if (file) {
				this.handleFile(file);
			}
		},

		handleFile(file) {
			const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
			if (!this.formats.includes(fileExtension)) {
				alert(this.i18n('fileUploader', 'fileFormatDescription')(this.formats));
				return;
			}
			this.$emit('choose', file);
		}
	}
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.file_uploader
	background #F6F6F6
	border-radius 16px
	padding 20px 0
	text-align center
	cursor pointer

.add-icon
	color main-color
	margin-bottom 16px
	transition color 0.3s

.file_uploader:hover .add-icon
	color lighten(main-color, 10)

.file_uploader:active .add-icon
	color darken(main-color, 10)

.title
	font-size 16px
	line-height 20px
	font-weight 500
	color text-primary
	margin-bottom 4px

.description
	font-size 14px
	line-height 20px
	color text-secondary
</style>
