<template>
    <div class="popup">
        <slot />
    </div>
</template>

<script>
export default {
	name: 'Popup',
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.popup
    background #fff
    border-radius 8px
    box-shadow 0 4px 8px rgba(0, 0, 0, 0.1)
    position absolute
    z-index 99
    width 100%
    margin-top 8px
</style>
