<template>
	<table>
		<tr>
			<th>Question</th>
			<th>Answer</th>
		</tr>
		<tr v-for="item in data" :key="item.id">
			<td>
				<div class="question">{{ item.question }}</div>
			</td>
			<td>
				<div class="answer">{{ item.answer }}</div>
				<div class="example">
					{{ item.example[0] }}<b>{{ item.example[1] }}</b
					>{{ item.example[2] }}
				</div>
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: 'TableCards',
	props: {
		data: {
			validator: val => Array.isArray(val),
			required: true,
		},
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

table
	display inline-block
	font-size 16px
	text-align left
	th
		padding 28px
		font-weight 500
		text-align left
		border-bottom solid 1px rgba(0, 0, 0, 0.1)
	td
		padding 22px 28px
		border-bottom solid 1px rgba(0, 0, 0, 0.1)
		vertical-align top
		font-weight 400
		b
			font-weight 400
		.example
			font-weight 300
			color #6C6C6C
			padding-top 30px
</style>
