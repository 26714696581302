// https://github.com/mdn/dom-examples/blob/main/web-speech-api/speak-easy-synthesis/script.js

// We don't really want to show 'Zombie' and 'Whisper' in the list of voices on top lines
// So this list is for good voices that we show first
const goodVoices = {
    en: [
        'Aaron'
    ]
};

export function getVoicesForLang(lang) {
    const results = {
        goodVoices: [],
        otherVoices: []
    };
    window.speechSynthesis.getVoices().forEach(voice => {
        if (voice.lang.indexOf(lang) !== 0) {
            return;
        }

        if (goodVoices[lang].includes(voice.name)) {
            results.goodVoices.push(voice);
        } else {
            results.otherVoices.push(voice);
        }
    });
    return results;
}

export function pronounce({ phrase, voice, rate = 1 }) {
    if (window.speechSynthesis.speaking) {
        return Promise.reject();
    }

    return new Promise((resolve, reject) => {
        const utterance = new SpeechSynthesisUtterance(phrase);
        utterance.voice = voice;
        utterance.pitch = 1;
        utterance.rate = rate;
        utterance.onend = resolve;
        utterance.onerror = reject;
        window.speechSynthesis.speak(utterance);
    });
}

/* function playTextToSpeech(
  text,
  { 
    volume = 1,
    rate = 1,
    pitch = 1,
    voiceURI = null,
    lang = navigator.language || 'en-US'
  } = {}
) {
  return new Promise((resolve, reject) => {
    // Check if the Web Speech API is available
    if (!('speechSynthesis' in window)) {
      reject(new Error('Web Speech API not supported in this browser.'));
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.volume = volume;
    utterance.rate = rate;
    utterance.pitch = pitch;
    utterance.lang = lang;

    if (voiceURI) {
      const voices = window.speechSynthesis.getVoices();
      const voice = voices.find(v => v.voiceURI === voiceURI);
      if (voice) {
        utterance.voice = voice;
      } else {
        reject(new Error(`Voice with URI "${voiceURI}" not found.`));
        return;
      }
    }

    utterance.onend = () => resolve();
    utterance.onerror = (event) => reject(event.error);

    window.speechSynthesis.speak(utterance);
  });
} */

/* // Play the text with default parameters
playTextToSpeech('Hello, world!').then(() => {
  console.log('Playback finished');
}).catch(error => {
  console.error('Error playing text:', error);
});

// Play the text with custom parameters
playTextToSpeech('This is a test', {
  volume: 0.8,
  rate: 1.2,
  pitch: 0.9,
  voiceURI: 'Google UK English Male',
  lang: 'en-GB'
}).then(() => {
  console.log('Playback finished');
}).catch(error => {
  console.error('Error playing text:', error);
}); */

  /* function getAvailableVoices() {
  return new Promise((resolve, reject) => {
    if (!('speechSynthesis' in window)) {
      reject(new Error('Web Speech API not supported in this browser.'));
      return;
    }

    const voices = window.speechSynthesis.getVoices();
    if (voices.length > 0) {
      resolve(voices);
    } else {
      window.speechSynthesis.onvoiceschanged = () => {
        resolve(window.speechSynthesis.getVoices());
      };
    }
  });
} */