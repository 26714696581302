<template>
	<div
		class="mascot-container"
		v-if="mascotConfig"
	>
		<img :src="srcs[mode]" ref="img" />
	</div>
</template>

<script>
import { day } from '@/lib/utils';

import catSlimHungry from '@/assets/mascot-cat/slim/cat-hungry.png';
import catSlimPirate from '@/assets/mascot-cat/slim/cat-pirate.png';
import catSlimSing from '@/assets/mascot-cat/slim/cat-sing.png';
import catSlimSlippers from '@/assets/mascot-cat/slim/cat-slippers.png';
import catSlimWatermelon from '@/assets/mascot-cat/slim/cat-watermelon.png';
import catSlimWalk from '@/assets/mascot-cat/slim/cat-walk.png';
import catMediumHungry from '@/assets/mascot-cat/medium/cat-hungry.png';
import catMediumPirate from '@/assets/mascot-cat/medium/cat-pirate.png';
import catMediumSing from '@/assets/mascot-cat/medium/cat-sing.png';
import catMediumSlippers from '@/assets/mascot-cat/medium/cat-slippers.png';
import catMediumWalk from '@/assets/mascot-cat/medium/cat-walk.png';
import catFatHungry from '@/assets/mascot-cat/fat/cat-hungry.png';
import catFatPirate from '@/assets/mascot-cat/fat/cat-pirate.png';
import catFatSing from '@/assets/mascot-cat/fat/cat-sing.png';
import catFatSlippers from '@/assets/mascot-cat/fat/cat-slippers.png';
import catFatWatermelon from '@/assets/mascot-cat/fat/cat-watermelon.png';
import catFatWalk from '@/assets/mascot-cat/fat/cat-walk.png';

const modes = [
	'hungry',
	'pirate',
	'sing',
	'slippers',
	'watermelon',
	'walk'
];

const catStates = [{
	hungry: catSlimHungry,
	pirate: catSlimPirate,
	sing: catSlimSing,
	slippers: catSlimSlippers,
	watermelon: catSlimWatermelon,
	walk: catSlimWalk
}, {
	hungry: catMediumHungry,
	pirate: catMediumPirate,
	sing: catMediumSing,
	slippers: catMediumSlippers,
	watermelon: catMediumSlippers,
	walk: catMediumWalk
}, {
	hungry: catFatHungry,
	pirate: catFatPirate,
	sing: catFatSing,
	slippers: catFatSlippers,
	watermelon: catFatWatermelon,
	walk: catFatWalk
}];

export default {
	name: 'Mascot',

	data: () => ({
		position: null
	}),

	props: {
		mode: {
			validator: val => modes.includes(val),
			default: () => modes[Math.random() * modes.length | 0]
		},
	},

	computed: {
		mascotConfig: function() {
			return this.$store.state.user.user && this.$store.state.user.user.mascot;
		},

		mascotFatness: function(){
			const mascotConfig = this.mascotConfig;
			if (!mascotConfig) {
				return 0;
			}

			const { feedDate, feedStatus } = mascotConfig;
			const diff = Date.now() - feedDate;
			return diff < day / 2 ? 2 : diff < day ? 1 : 0;
			// if (feedStatus === 2) {
			// 	return diff < day / 2 ? 2 : diff < day ? 1 : 0;
			// }
			// if (feedStatus === 1) {
			// 	return diff < day / 2 ? 1 : 0;
			// }
			// // feedStatus - ceil(diff / (day/2))
			// return 0;
		},

		srcs: function(){
			return catStates[this.mascotFatness];
		}
	},

	mounted: function() {
		this.recalcPosition();
		window.addEventListener('resize', this.onWindowResize);
	},

	destroyed: function() {
		window.removeEventListener('resize', this.onWindowResize);
	},

	methods: {
		recalcPosition: function() {
			if (!this.$refs.img) {
				return;
			}

			this.position = this.$refs.img.getBoundingClientRect();
		},

		onWindowResize: function() {
			this.recalcPosition();
		}
	},

	watch: {
		mode: function() {
			this.recalcPosition();
		}
	}
};
</script>

<style lang="stylus">
.mascot-container
	pointer-events none
	position fixed
	bottom 50px
	left 80px
	img 
		width 128px
		height auto

@media only screen and (max-width: 600px) {
	.mascot-container {
		left: 20px
	}
}

@media only screen and (max-width: 600px) {
	.mascot-container {
		opacity 0.6
	}
}

@media only screen and (max-height: 665px) {
	.mascot-container {
		top 480px
		opacity 0.6
	}
}
</style>
