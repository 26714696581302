import { render, staticRenderFns } from "./Mascot.vue?vue&type=template&id=6c683de1"
import script from "./Mascot.vue?vue&type=script&lang=js"
export * from "./Mascot.vue?vue&type=script&lang=js"
import style0 from "./Mascot.vue?vue&type=style&index=0&id=6c683de1&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports