const $ = (a, b) => Object.assign(a, b);
const getEmptyOb = () => ({});

export const createLoadableModel = function(request, ...modelsRaw) {
	const { processData, state, actions, mutations, getters } = modelsRaw[0] || {};
	const models = modelsRaw || [];

	return Object.assign({
		namespaced: true,

		state: () => {
			return models.reduce((acc, model) => {
				if (!model.state) {
					return acc;
				}
				return { ...acc, ...model.state() };
			}, {
				status: 'initial',
				data: null,
				error: null
			});
		},

		getters,

		actions: {
			load: async function({ commit }, ...requestArgs) {
				commit('loadingStart');
				try {
					const rawData = await request(...requestArgs);
					const data = processData ? processData(rawData) : rawData;
					commit('loadingSuccess', data);
				} catch (error) {
					commit('loadingError', error);
				}
			},
			clear: ({ commit }) => commit('clear'),
			...(actions || getEmptyOb)()
		},

		mutations: {
			loadingStart: state => $(state, { status: 'loading', data: null, error: null }),
			loadingSuccess: (state, data) => $(state, { status: 'success', data, error: null }),
			loadingError: (state, error) => $(state, { status: 'error', data: null, error }),
			clear: state => $(state, { status: 'initial', data: null, error: null }),
			...(mutations || getEmptyOb)()
		}
	});
}
