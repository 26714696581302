<template>
    <div class="toggle-switch" @click="toggle" :class="[{ active: value }, 'size_' + size]">
        <div class="slider" />
    </div>
</template>

<script>
export default {
	name: 'ToggleSwitch',
    props: {
        value: Boolean,
        size: {
            validator: val =>
				['small', 'medium'].includes(val),
			default: 'medium',
        }
    },
    methods: {
        toggle() {
            this.$emit('input', !this.value);
        },
    },
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.toggle-switch
    display inline-block
    background-color #DCDCDC
    position relative
    cursor pointer
    transition background-color 0.3s ease
    .slider
        background-color white
        border-radius 100%
        position absolute
        transition all 0.3s ease
    &.active
        background #5FAA44

.toggle-switch.size_small
    width 36px
    height 20px
    border-radius 25px
    .slider
        width 16px
        height 16px
        top 2px
        left 2px
    &.active .slider
        left 18px

.toggle-switch.size_medium
    width 56px
    height 32px
    border-radius 28px
    .slider
        width 26px
        height 26px
        top 3px
        left 3px
    &.active .slider
        left 27px

</style>
