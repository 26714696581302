import {
	courseGet10Training,
	getTrainingWordsGuest,
	pocGetTrainingAi,
	complainSentence,
	updateWordLastDate,
	courseGet10TrainingPackageScenario,
} from '@/lib/api';
import { isChinese, convertPinyinToChinese, chineseToPinyinMap } from '../lib/chinese';

// возвращает количество символов, совпадающих с начала строки
function howManySame(a, b) {
    for (var i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return i;
    }
    return a.length;
}

// todo: вместо этого сделать наследование классов типа class ChineseModel extends GeneralModel
const supportLanguage = {
	preSetAnswer(card, value) {
		if (isChinese(card.wordValue)) {
			return convertPinyinToChinese(value, card.wordValue, card.wordLemma)
		}
		return value;
	},

	fixLastCharForHelp(card, value, lastChar) {
		// объяснение — там, где эта функция вызывается
		// lastChar = last char of value (lastHelpedChar actually)
		if (isChinese(card.wordValue) && lastChar === ' ') {
			return convertPinyinToChinese(value, card.wordValue, card.wordLemma);
		}
		return value;
	},

	helpForChinese(state) {
		let current = state.answerToSend.toLowerCase();
		const card = state.data[state.currentCardIndex];
		const hanziMap = chineseToPinyinMap(card.answer[1], card.wordLemma);
		const chunks = card.answer[1].split('').map((char, i) => ({
			hanzi: char,
			pinyin: hanziMap[char],
		}));
		
		current = current.replace(/\s/gi, '');

		let currentChunkIndex = 0;
		for (let i = 0; i < current.length; i++) {
			const currentChunk = chunks[currentChunkIndex];
			if (isChinese(current[i]) && currentChunk.hanzi === current[i]) {
				currentChunkIndex++;
			} else if (current[i].match(/[a-zA-Z0-9]/gi)) {
				if (current.slice(i).indexOf(currentChunk.pinyin) === 0) {
					// jump to checking next chunk
					i += currentChunk.pinyin.length - 1;
					currentChunkIndex++;
				} else {
					// we started entering pinyin
					const howMany = howManySame(current.slice(i), currentChunk.pinyin);
					const sliced = current.substr(0, i + howMany);
					if (sliced === state.answerToSend) {
						state.answerToSend = sliced + currentChunk.pinyin[howMany];
					} else {
						state.answerToSend = sliced;
					}
					return;
				}
			} else {
				// it's not hanzi neither pinyin - cut until here
				state.answerToSend = current.slice(0, i);
				return;
			}
		}

		if (chunks[currentChunkIndex]) {
			state.answerToSend += chunks[currentChunkIndex].pinyin[0];
		}
		state.answerToSend = convertPinyinToChinese(state.answerToSend, card.wordValue, card.wordLemma);
	}
};

export default {
	namespaced: true,

	state: () => ({
		loading: true,
		data: null,
		currentCardIndex: null,
		answerToSend: null,
		complained: false,
		wordsRepeated: null,
	}),

	actions: {
		start: async function({ commit }, { courseId, userLoggedIn, scenario }) {
			commit('startLoading');
			if (userLoggedIn) {
				const data = scenario
					? await courseGet10TrainingPackageScenario(courseId, scenario)
					: await courseGet10Training(courseId);
				commit('accomplishLoading', data);
			} else {
				const data =  await getTrainingWordsGuest(courseId);
				commit('accomplishLoading', data);
			}
		},

		'ai-start': async function({ commit }, { languageTarget, languageNative, words }) {
			commit('startLoading');
			commit('accomplishLoading', await pocGetTrainingAi(words, languageNative, languageTarget));
		}, 
	},

	mutations: {
		startLoading(state) {
			state.loading = true;
			state.data = null;
			state.currentCardIndex = null;
			state.answerToSend = null;
		},

		accomplishLoading(state, data) {
			state.loading = false;
			state.data = data;
			state.currentCardIndex = 0;
			state.answerToSend = '';
			state.wordsRepeated = {};
			for (var i = 0; i < data.length; i++) {
				if (state.wordsRepeated[data[i].wordId]) {
					data[i].wordIsNew = false;
				}

				if (!state.wordsRepeated[data[i].wordId]) {
					state.wordsRepeated[data[i].wordId] = {
						total: 0,
						completed: 0,
					};
				}
				state.wordsRepeated[data[i].wordId].total++;
				// data[i].wordIsNew = true;
			}
		},

		nextCard(state) {
			state.currentCardIndex++;
			state.answerToSend = '';
			state.complained = false;

			// if state.currentCardIndex > state.data.length
		},

		updateWordDate(state) {
			const wordId = state.data[state.currentCardIndex].wordId;
			if (++state.wordsRepeated[wordId].completed >= Math.min(3, state.wordsRepeated[wordId].total)) {
				updateWordLastDate(wordId, false);
			}
		},

		setAnswer(state, value) {
			state.answerToSend = supportLanguage.preSetAnswer(
				state.data[state.currentCardIndex],
				value
			);
		},

		// complain(state) {
		// 	complainSentence(state.data[state.currentCardIndex].id);
		// 	state.complained = true;
		// },

		complained(state) {
			state.complained = true;
		},

		iKnowIt(state) {
			const wordId = state.data[state.currentCardIndex].wordId;
			updateWordLastDate(wordId, true);
			state.data = state.data.filter(word => word.wordId !== wordId);
		},

		help(state) {
			let current = state.answerToSend.toLowerCase();
			const answer = state.data[state.currentCardIndex].answer[1].toLowerCase();

			if (isChinese(answer)) {
				return supportLanguage.helpForChinese(state);
			}

			if (current === answer) {
				return;
			}
			// right answer + smth else (eg, 'finely' instead of 'fine')
			if (current.indexOf(answer) === 0) {
				state.answerToSend = state.answerToSend.slice(0, answer.length);
				return;
			}

			let i = 0;

			if (current.length > answer.length) {
				current = current.slice(0, answer.length);
			}

			// find i for which they diverge
			for (; i < answer.length; i++) {
				if (current[i] !== answer[i]) {
					break;
				}
			}

			// if the whole word is wrong, also hint the first letter
			if (current[i] && i !== 0) {
				current = current.slice(0, i);
			} else {
				current = current.slice(0, i) + answer[i];
			}

			state.answerToSend = current;
		},
	},
};
