<template>
	<div>
		<canvas
			:width="graphBounds.size.width"
			:height="graphBounds.size.height"
			ref="canvas"
			:style="`width: ${graphBounds.style.width}; height: ${graphBounds.style.height}`"
		></canvas>
		<select style="margin: 20px; width: 100px" v-model="scale">
			<option :value="1">1</option>
			<option :value="2">2</option>
			<option :value="3">3</option>
		</select>
	</div>
</template>

<script>
function getStartOfDayTimestamp(offset = 0) {
	const dayInMilliseconds = 24 * 60 * 60 * 1000;
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	
	if (offset) {
		today.setTime(today.getTime() + (dayInMilliseconds * offset));
	}
	
	return +today;
}

function getXCoords(width, count, marginLeft, marginRight) {
	const coords = [];
	const step = (width - marginLeft - marginRight) / count;
	for (let i = 0; i < count; i++) {
		coords.push(i * step + marginLeft);
	}
	return coords;
}

function drawCatmullRom(ctx, points, tension = 1) {
    if (points.length < 4) {
		throw new Error("Need at least 4 points to draw a Catmull-Rom spline");
    }

    for (let i = 1; i < points.length - 1; i++) {
        const p0 = points[i - 1];
        const p1 = points[i];
        const p2 = points[i + 1];
        const p3 = points[i + 2] || p2;

        // Control points for the Bézier curve, derived from the Catmull-Rom segment
        const cp1 = [
            p1[0] + (tension * (p2[0] - p0[0]) / 6),
            p1[1] + (tension * (p2[1] - p0[1]) / 6)
        ];
        const cp2 = [
            p2[0] - (tension * (p3[0] - p1[0]) / 6),
            p2[1] - (tension * (p3[1] - p1[1]) / 6)
        ];

        ctx.bezierCurveTo(cp1[0], cp1[1], cp2[0], cp2[1], p2[0], p2[1]);
    }
}

function drawCatmullRomArea(ctx, height, points, tension) {
	ctx.beginPath();
	ctx.moveTo(points[0][0], height);
	if (points.length > 4) {
		ctx.lineTo(points[0][0], points[0][1]);
		drawCatmullRom(ctx, points, tension);

	} else {
		for (let i = 0; i < points.length; i++) {
			ctx.lineTo(points[i][0], points[i][1]);
		}
	}
	const lastPoint = points[points.length - 1];
	ctx.lineTo(lastPoint[0], height);
}

export default {
	name: 'WordsGraph',
	inject: ['i18n'],
	props: ['values'],
	data: () => ({
		numberOfDates: 7,
		numberOfLevels: 11,
		BOTTOM_MARGIN: 50,
		TOP_MARGIN: 50,
		LEFT_MARGIN: 50,
		RIGHT_MARGIN: 50,
		GRAPH_WIDTH: 500,
		GRAPH_HEIGHT: 400,
		scale: 3,
	}),
	computed: {
		dates() {
			return this.values.map(x => ({
				knownCount: x.knownCount.map(y => ({
					repeatTime: y.repeatTime,
					count: +y.count
				})),
				date: new Date(x.date)
			})).sort((a, b) => a.date - b.date);
		},

		datesReversed() {
			return this.dates.slice().reverse();
		},

		datesValidated() {
			const result = [];
			for (let i = 0; i < this.numberOfDates; i++) {
				// if we don't clone it, it adds `count` multiple times to the object
				const pointsForDate = this.getPointsForDate(i).map(a => ({ ...a }));
				if (!pointsForDate) {
					continue;
				}
				for (let level = pointsForDate.length - 2; level >= 0; level--) {
					pointsForDate[level].count += pointsForDate[level + 1].count;
				}
				result.push(pointsForDate);
			}
			return result;
		},

		allCounts() {
			return this.datesValidated.flatMap(x => x.map(y => y.count));
		},

		maxCount() {
			return Math.max(...this.allCounts);
		},

		minCount() {
			return Math.min(...this.allCounts);
		},

		deltaCount() {
			return this.maxCount - this.minCount;
		},

		graphBounds() {
			const dpi = window.devicePixelRatio;

			return {
				style: {
					width: `${this.GRAPH_WIDTH}px`,
					height: `${this.GRAPH_HEIGHT}px`
				},
				size: {
					width: this.GRAPH_WIDTH * dpi,
					height: this.GRAPH_HEIGHT * dpi,
				}
			};
		}
	},

	methods: {
		getPointsForDate(i) {
			const date = getStartOfDayTimestamp(-this.numberOfDates + i + 2);
			const entry = this.datesReversed.find(entry => entry.date <= date);
			if (!entry) {
				return null;
			}
			return entry.knownCount.sort((a, b) => a.repeatTime - b.repeatTime);
		},

		getPointsForLevel(i, xCoords, height) {
			const graphHeight = height - this.BOTTOM_MARGIN - this.TOP_MARGIN;
			const points = xCoords.map((x, j) => {
				const entry = this.datesValidated[j]?.find(({ repeatTime }) => repeatTime === i);
				if (!entry) {
					return;
				}
				const count = entry.count;
				const normalizedCount = count / this.deltaCount;

				return [
					x,
					height * this.scale - this.BOTTOM_MARGIN - normalizedCount * graphHeight * this.scale,
				];
			}).filter(x => x);
			if (points.length === 1) {
				// special case
				return [
					points[0],
					[xCoords[1], points[0][1]]
				];
			}
			return points;
		},

		redraw() {
			const canvas = this.$refs.canvas;
			const ctx = canvas.getContext("2d");
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			
			const xCoords = getXCoords(canvas.width, this.numberOfDates, this.LEFT_MARGIN, this.RIGHT_MARGIN);
			for (let i = 0; i < this.numberOfLevels; i++) {
				const points = this.getPointsForLevel(i, xCoords, canvas.height);
				drawCatmullRomArea(ctx, canvas.height, points);
				ctx.fillStyle = 'rgba(0, 100, 255, 0.1)';
				ctx.fill();
			}
		}
	},
	
	mounted() {
		this.redraw();
	},

	watch: {
		values: function() {
			this.redraw();
		},

		scale: function() {
			this.redraw();
		}
	}
};
</script>
