<template>
    <div class="section">
        <h1 class="section-title">
            {{ title }}
            <slot name="title" />
        </h1>
		<div class="section-sub" v-if="$slots.sub">
            <slot name="sub" />
        </div>
        <div class="section-content">
            <slot name="default" />
        </div>
    </div>
</template>

<script>
export default {
	name: 'PageSection',
    props: {
        title: String,
    }
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.section
	margin-top 40px
	margin-left 32px
	margin-right 32px
	
	.section-title
		font-size 20px
		line-height 24px
		font-weight 600

	.section-sub
		margin-top 20px
		margin-bottom 32px

	.section-content
		margin-top 24px
		.loading
			text-align center

	& + &
		margin-top calc(64px - 12px) // for scrollbar
</style>
