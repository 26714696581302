<template>
	<div
		class="sidebar"
	>
        <div class="logo-container">
            <Logo />
        </div>
        <div class="menu top-menu">
            <a
                v-for="item in items.top"
                :key="item.id"
                :class="['menu-item', selectedItem === item.id && 'active']"
                @click="goToLink(item)"
            >
                <Icon :type="item.icon" class="icon" />
                {{ item.text }}
            </a>
        </div>
        <div class="menu bottom-menu">
            <a
                v-for="item in items.bottom"
                :key="item.id"
                :class="['menu-item', selectedItem === item.id && 'active']"
                @click="goToLink(item)"
            >
                <Icon :type="item.icon" class="icon" />
                {{ item.text }}
            </a>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo';
import Icon from '@/components/Icon';

export default {
	name: 'Sidebar',
	inject: ['i18n'],
	props: {
        selectedItem: {
            type: String,
            required: true
        } 
	},
    data: function () {
        return {
            items: {
                top: [{
                    id: 'home',
                    icon: 'home',
                    text: this.i18n('menu', 'home')
                }, {
                    id: 'my-words',
                    icon: 'myWords',
                    text: this.i18n('menu', 'myWords')
                }, {
                    id: 'favorites',
                    icon: 'bookmarks',
                    text: this.i18n('menu', 'bookmarks')
                }],
                bottom: [{
                    id: 'sendFeedback',
                    icon: 'sendFeedback',
                    text: this.i18n('menu', 'sendFeedback')
                }, {
                    id: 'settings',
                    icon: 'settings',
                    text: this.i18n('menu', 'settings')
                }]
            }
        };
    },
    methods: {
        goToLink(item) {
			this.$router.push('/' + item.id);
        }
    },
	components: {
        Icon,
		Logo,
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.sidebar
    position fixed
    width 200px
    background white
    height: 100vh
    border-right stroke solid 1px

.logo-container
    height 64px

.menu-item
    cursor pointer
    font-size 14px
    padding 0 16px
    margin-bottom 8px
    display block
    height 40px
    color text-secondary
    display flex
    align-items center
    &.active, &:hover
        color text-primary

    .icon
        margin-left 12px
        margin-right 10px
        color #DCDCDC
    
    &.active .icon
        color #3D3D3D

.bottom-menu
    position absolute
    bottom 40px
</style>
