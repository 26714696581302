<template>
    <Page class="course-tree-page">
        <div class="course-header-container">
            <div class="course-header">
                <Button
                    design="action"
                >Тренировать весь курс</Button>
            </div>
        </div>
        <div
            class="subcourse-level"
            v-for="subcourseLevel in subcourseLevels"
            :key="subcourseLevel.map(x => x.id).join(',')"
        >
            <CourseBlock
                v-for="subcourse in subcourseLevel"
                :key="subcourse.id"
                :title="subcourse.title"
                :subtitle="subcourse.subtitle"
                :avatar="subcourse.avatar"
                :mode="subcourse.mode"
                :design="subcourse.disabled ? 'default-disabled' : 'default'"
                :ref="'subcourse-' + subcourse.id"
            />
        </div>

        <div class="lines">
            <div
                class="line"
                v-for="line in lines"
                :key="Object.values(line).toString()"
                :style="calculateLineStyle(line)"
            />
        </div>
    </Page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Page from '@/components/Page';
import Button from '@/components/Button';
import Switcher from '@/components/Switcher';
import Spinner from '@/components/Spinner';

import {
	ModalShowCourse,
	ModalEditCourse,
	CourseBlock,
} from '@/views/course';

import { addCourseToFavorite } from '@/lib/api';

const mockData = [
    [{
        id: 0,
        title: 'Местоимения',
        subtitle: '(I, you, we, she, it, they)'
    }],
    [
        {
            id: 1,
            title: 'Глаголы (Present Simple)',
            subtitle: '(have, do, make - убедиться, что во всех примерах там разница четкая, и отдельно её объяснить, go, know, work, come, learn - убедиться, что и узнавать там есть, want + существительное)'
        },
        {
            id: 2,
            title: 'Еда',
            subtitle: '(объясняем тут, что -s это множественное число; egg, cheese, bread, soup, cake, chicken, pork, beef, apple, banana, orange, lemon, corn, rice, oil, seed, knife, spoon, fork, plate, cup, breakfast, lunch, dinner, sugar, salt, bottle, foodC; coffee, tea, wine, beer, juice, water, milk, beverageC)'
        }
    ],
    [{
        id: 3,
        title: 'Отрицания и вопросы (Present Simple)',
        subtitle: '(объяснение do-support с историей возникновения, и что раньше в английском было I not eat a fruit)',
        disabled: true,
    }],
    [
        {
            id: 4,
            title: 'Инфинитивы',
            subtitle: '(инфинитив обозначается частичкой to, которая аналог нашего ть, но еще и показывает направленность к действию ... )',
            disabled: true,
        },
        {
            id: 5,
            title: 'Люди',
            subtitle: '(son*, daughter*, mother, father, parent (= mother/father), baby, man, woman, brother*, sister*, family, grandfather, grandmother, husband*, wife*, king, queen, president, neighbor, boy, girl, child (= boy/girl), adult (= man/woman), human (≠ animal), friend (Add a friend’s name), victim, player, fan, crowd, person / people)',
            disabled: true,
        }
    ],
    [{
        id: 6,
        title: 'to be (Present Simple) + разные adjectives',
        subtitle: '(объяснение про праиндоевропейский и что у нас было аз есмь и пример со славянскими языками ... )',
        disabled: true,
    }],
    [
        {
            id: 7,
            title: 'Модальные глаголы',
            subtitle: '(can, may, must + could, might — объясняем, что не инфлектятся, запрыгиваем слегка в прошедшее время)',
            disabled: true,
        },
        {
            id: 8,
            title: 'Транспорт',
            subtitle: '(train, plane, car, truck, bicycle, bus, boat, ship, tire, gasoline, engine, (train) ticket, transportation)',
            disabled: true,
        }
    ],
    [{
        id: 9,
        title: 'Future Simple + ещё глаголов',
        subtitle: '(отдельно объясняем про will be, что оно не склоняется, и посвящаем ему много предложений)',
        disabled: true,
    }],
    [
        {
            id: 10,
            title: 'Past Simple - правильные глаголы',
            subtitle: '(правильные — те, для которых есть правило; добавляем -ed)',
            disabled: true,
        },
        {
            id: 11,
            title: 'Past Simple - неправильные глаголы',
            subtitle: '(те, для которых правила нет; небольшая историческая справка про аблаут и что он есть в русском)',
            disabled: true,
        }
    ],
    [{
        id: 12,
        title: 'Инфинитив Continuous',
        subtitle: '(отдельно показываем прям-таки инфинитив, объясняем его дословное значение, затем предлагаем его посклонять в настоящем времени)',
        disabled: true,
    }]
].map(level => level.map(course => ({ ...course, mode: 'user', avatar: null })));

export default {
	name: 'CourseTreePage',

	data: function() {
		const i18n = this.i18n;
		return {
            subcourseLevels: mockData,
            lines: []
		};
	},

	inject: ['i18n'],

	computed: {
	},

	mounted: function() {
        this.recalculateLines();
		window.addEventListener('resize', this.recalculateLines);
		window.addEventListener('deviceorientation', this.recalculateLines);
	},

	destroyed: function() {
		window.removeEventListener('resize', this.recalculateLines);
		window.removeEventListener('deviceorientation', this.recalculateLines);
	},

	watch: {
        subcourseLevels: function() {
            this.recalculateLines();
        }
	},

	methods: {
        calculateLineStyle(line) {
            const length = Math.sqrt(
                Math.pow(line.x2 - line.x1, 2) + Math.pow(line.y2 - line.y1, 2)
            );
            const angle = (Math.atan2(line.y2 - line.y1, line.x2 - line.x1) * 180) / Math.PI;

            return {
                width: `${length}px`,
                top: `${line.y1}px`,
                left: `${line.x1}px`,
                height: `${line.height}px`,
                transform: `rotate(${angle}deg)`,
                transformOrigin: 'top left',
                background: line.color
            };
        },

        recalculateLines() {
            const PAGE_PADDING = 75; // header size
            const levels = this.subcourseLevels;
            const linesBlocks = [];
            // сначала собираем пары блоков, соединённые линиями
            // порядок from и to важен
            for (let i = 1; i < levels.length; i++) {
                const prevLevel = levels[i - 1];
                const curLevel = levels[i];
                if (prevLevel.length === 1 && curLevel.length === 1) {
                    // [ ]
                    //  |
                    // [ ]
                    linesBlocks.push({ from: prevLevel[0].id, to: curLevel[0].id });
                } else if (prevLevel.length === 1 && curLevel.length > 1) {
                    //   [ ]
                    //   / \
                    // [ ] [ ]
                    linesBlocks.push(
                        ...curLevel.map(subcourse => ({
                            from: prevLevel[0].id,
                            to: subcourse.id
                        }))
                    );
                } else if (prevLevel.length > 1 && curLevel.length === 1) {
                    // [ ] [ ]
                    //   \ /
                    //   [ ]
                    linesBlocks.push(
                        ...prevLevel.map(subcourse => ({
                            from: subcourse.id,
                            to: curLevel[0].id
                        }))
                    );
                } else {
                    // невалидный случай
                }
            }

            const getSubcourseElement = id => (
                this.$refs['subcourse-' + id] &&
                this.$refs['subcourse-' + id][0] &&
                this.$refs['subcourse-' + id][0].$el
            );

            // собираем линии
            this.$nextTick(() => {
                this.lines = linesBlocks.map(({ from, to }) => {
                    const fromSubcourse = getSubcourseElement(from);
                    const toSubcourse = getSubcourseElement(to);
                    if (!fromSubcourse || !toSubcourse) {
                        // тут рефы ещё могут быть не проинициализированы
                        return;
                    }
                    const { bottomCenter } = getElementCenterPoints(fromSubcourse);
                    const { topCenter } = getElementCenterPoints(toSubcourse);
                    return {
                        x1: bottomCenter.x, y1: bottomCenter.y - PAGE_PADDING,
                        x2: topCenter.x, y2: topCenter.y - PAGE_PADDING,
                        color: 'rgba(0, 0, 0, 0.04)',
                        height: 2
                    };
                });
            });
        }
	},

	components: {
		CourseBlock,
		Switcher,
		Spinner,

		Page,
		Button,
		// ModalCreateCourse,
		ModalEditCourse,
		// ModalEditCourseProps,
		ModalShowCourse,
	},
};

function getElementCenterPoints(elem) {
    const rect = elem.getBoundingClientRect();
    const topCenter = {
        x: rect.left + rect.width / 2,
        y: rect.top
    };
    const bottomCenter = {
        x: rect.left + rect.width / 2,
        y: rect.bottom
    };
    return { topCenter, bottomCenter };
}

</script>

<style lang="stylus">
@import "../components/style.styl";

// .course-tree-page
    // text-align center

.subcourse-level
    display flex
    justify-content center
    column-gap "calc(%s / 2)" % (course-block-width)
    margin-bottom 100px

.course-header-container
    text-align center

.course-header
    display inline-block
    text-align right
    margin 10px 0
    width "calc(%s * 2.5)" % (course-block-width)

@media only screen and (max-width: 886px) {
	.subcourse-level {
        padding 0 10px
		column-gap 10px
	}
}

.line
    position absolute
    border-radius 2px
</style>