<template>
	<div class="selectbox-container" :class="{ open }" @click="$event.stopPropagation()">
		<div class="selectbox" @click="toggle">
			{{ currentOption.title }}
			<Icon type="arrowDown" />
		</div>
		<Popup :style="popupStyle" v-if="open">
			<div class="items-container">
				<div
					class="item"
					v-for="item in options"
					:class="{ active: item.value === value }"
					@click="select(item.value)"
				>
					{{item.title}}
				</div>
			</div>
		</Popup>
	</div>
</template>

<script>
// todo: use Menu instead of manually use menu here
// same with autocomplete in searching words
import Icon from './Icon';
import Popup from './Popup';

export default {
	name: 'Selectbox',
	data: () => ({
		open: false,
	}),
	props: {
		value: {
			required: true
		},
		options: Array,
		width: Number,
		rightPositioned: Boolean, // whether selectbox is on the right of page, so popup is gotta be aligned with the right side of the button
	},
	computed: {
		currentOption: function() {
			return this.options.find(({ value }) => value === this.value);
		},
		popupWidth: function() {
			return Math.max(...this.options.map(option => measureTextLength(option.title)));
		},
		popupStyle: function() {
			const style = { width: this.popupWidth + 'px' };
			if (this.rightPositioned) {
				style.right = 0;
			}
			return style;
		}
	},
	methods: {
		toggle() {
			this.open = !this.open;
		},
		close() {
			this.open = false;
		},
		select(value) {
			this.$emit('input', value);
			this.open = false;
		}
	},
	watch: {
		open: function(value) {
			if (value) {
				window.addEventListener('click', this.close);
			} else {
				window.removeEventListener('click', this.close);
			}
		}
	},
	destroy: function() {
		window.removeEventListener('click', this.close);
	},
	components: {
		Icon,
		Popup
	}
};

function measureTextLength(text) {
	const div = document.createElement('div');
	div.textContent = text;

	// todo: font & font size
	div.style.fontSize = '20px';
	div.style.position = 'absolute';
	div.style.top = '-10000px';
	div.style.left = '-10000px';
	document.body.appendChild(div);
	const s = getComputedStyle(div).width;
	document.body.removeChild(div);
	return parseInt(s);
}
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.selectbox-container
	display inline-block
	position relative

.selectbox
	display inline-flex
	align-items center
	justify-content space-between
	box-sizing border-box
	cursor pointer
	color #292929
	background white
	line-height 20px
	font-size 14px
	font-weight 400
	padding 6px 14px
	border solid 2px #f6f6f6
	border-radius 8px
	.icon
		color #656565
		padding 0 5px
		padding-top 7.5px
		padding-bottom 6.67px
		margin-left 4px

.selectbox-container.open
	.selectbox
		border-color #5faa44

.items-container
	padding 4px
	box-sizing border-box
	max-height 50vh
	overflow auto
	transition 0.3s height
	.item
		font-size 14px
		line-height 36px
		height 36px
		padding 0 8px
		box-sizing border-box
		cursor pointer
		border-radius 6px
	.item:hover
		background #eee
</style>
