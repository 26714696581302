var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'button',
		'design_' + _vm.design,
		'size_' + _vm.size,
		_vm.noHorizontalMargins && 'no_horizontal_margins',
		_vm.disabled && 'disabled',
	],on:{"click":function($event){!_vm.disabled && _vm.$emit('click', $event)}}},[(_vm.showSpinner)?_c('div',{staticClass:"button-spinner-container"},[_c('Spinner',{attrs:{"size":"small","design":"action"}})],1):_vm._e(),(_vm.showContent)?_vm._t("default"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }