<template>
	<a
		:href="href || '#'"
		:class="[
			'link',
			'design_' + design,
			'size_' + size,
			disabled && 'disabled',
		]"
		@click="!disabled && $emit('click', $event)"
	>
		<slot />
	</a>
</template>

<script>
export default {
	name: 'Link',
	props: {
		href: {
			type: String,
		},
		size: {
			validator: val => ['small', 'medium', 'big'].includes(val),
			default: 'medium',
		},
		design: {
			validator: val =>
				['default', 'gray', 'gray-emphasized'].includes(val),
			default: 'default',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.link
	cursor pointer
	&.design_gray-emphasized
		color #8C8C8C
		font-family logo-font
	&.design_gray-emphasized:hover
		// color two grades darker
		color #636363
	&.design_gray
		color #8C8C8C
	&.design_gray:hover
		// color two grades darker
		color #636363

	&.size_small
		font-size 14px
	&.size_medium
		font-size 16px
	&.size_big
		font-size 18px
	&.disabled
		cursor not-allowed
</style>
