<template>
	<div>
		<div class="modal-markup-content">
			<div
				v-if="status === 'loading'"
				class="modal-markup-content-spinner"
			>
				<Spinner size="medium" />
			</div>
			<div v-else>
				<slot />
			</div>
		</div>

		<div class="modal-markup-buttons">
			<div
				v-for="button in buttons"
				:key="button.id"
				class="button-block"
			>
				<Button
					:disabled="button.disabled"
					:design="button.design"
					:style="button.style"
					@click="$emit('button' + button.id[0].toUpperCase() + button.id.slice(1))"
				>
					{{button.text}}
				</Button>
			</div>
		</div>
	</div>
</template>

<script>
import Button from '@/components/Button';
import Spinner from '@/components/Spinner';

export default {
	name: 'ModalMarkup',

	props: {
		status: {
			type: String
		},
		buttons: {
			validator: val => Array.isArray(val)
		}
	},

	components: {
		Button,
		Spinner,
	},
};
</script>

<style lang="stylus">
@import "../components/style.styl";

.modal-markup-content
	padding 30px

.modal-markup-content-spinner
	padding 30px
	text-align center
	padding-bottom 10px
	font-style italic

.modal-markup-buttons
	padding-bottom 40px
	padding-top 0
	text-align center
	.button-block
		display block
	.button-block + .button-block
		margin-top 5px
	.button
		width 200px
</style>
