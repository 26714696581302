<template>
	<div class="loading-dots">
		<div class="dot"></div>
		<div class="dot"></div>
		<div class="dot"></div>
	</div>
</template>

<script>
export default {
	name: 'ThreeDotsLoading',
};
</script>

<style lang="stylus" scoped>
.loading-dots {
  display: inline-block;
}

.dot {
	display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 50%;
  animation: bounce 2s infinite ease-in-out;
}

.dot + .dot {
	margin-left: 8px;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% {
	opacity: 0.5;
  }
  40% {
	opacity: 0.3;
  }
}

</style>
