import { getCourseById } from '@/lib/api';
import { createLoadableModel } from './loadable-model';

export default createLoadableModel(getCourseById, {
	getters: {
		wordIndexInCourse: ({ status, data }) => wordId => {
			if (status !== 'success') {
				return -1;
			}
			const word = data.find(({ id }) => id === wordId);
			return data.indexOf(word);
		},

		wordExistsInCourse: (_, { wordIndexInCourse }) => wordId => {
			return wordIndexInCourse(wordId) !== -1;
		}
	}
});
