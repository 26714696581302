<template>
	<Modal
		:open="true"
		:maximumWidth="600"
		:widthPageMargin="30"
		key="explanationModal"
		@paranjaClick="$emit('close')"
	>
		<div
			v-if="error"
			class="error"
		>
			{{error}}
		</div>
		<ModalContentCourse
			v-else
			:header="i18n('training', explanationType === 'context' ? 'explainContextHeader' : 'explainOriginHeader')"
			@close="$emit('close')"
		>
			<template>
				<div class="modal-contents">
					<div class="chat-message user-message">
						<div class="message pre">{{explanationType === 'context' ? i18n('training', 'explainContextMessage')(sentence, inputWord) : i18n('training', 'explanationOriginMessage')(sentence[1])}}</div>
						<div class="avatar"><img :src="userAvatar" /></div>
					</div>
					<div class="chat-message bot-message">
						<div class="avatar"><AiAvatar /></div>
						<div class="message" v-if="loading"><ThreeDotsLoading /></div>
						<div class="message" v-else><span v-html="explanationHtml" /></div>
					</div>
				</div>
			</template>
			<template #buttons>
				<div class="modal-buttons">
					<Button
						design="action"
						@click="$emit('close')"
					>{{ i18n('training', 'explanationGotIt') }}</Button>
				</div>
			</template>
		</ModalContentCourse>
	</Modal>
</template>

<script>
import markdownit from 'markdown-it'
import { getContextExplanation, getOriginsExplanation } from '@/lib/api';

import Modal from '@/components/Modal';
import Button from '@/components/Button';

import AiAvatar from './AiAvatar';
import ThreeDotsLoading from './ThreeDotsLoading';
import ModalContentCourse from '@/components/ModalContentCourse.vue';

const md = markdownit();

export default {
	name: 'ModalExplanation',

	props: {
		wordId: {
			type: Number,
			required: true,
		},
		sentenceId: {
			type: Number,
			required: true,
		},
		inputWord: {
			type: String,
			required: true,
		},
		explanationType: {
			type: String,
			required: true,
			validator: val => ['context', 'origin'].includes(val)
		},
		sentence: Array
	},

	data: () => ({
		loading: false,
		error: null,
		text: null,
	}),

	inject: ['i18n'],

	computed: {
		user: function() {
			return this.$store.state.user && this.$store.state.user.user;
		},

		userAvatar: function() {
			return (
				this.user.avatar
				|| `https://source.boringavatars.com/beam/120/${this.user.username}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`
			);
		},

		explanationHtml: function() {
			if (!this.text) {
				return '';
			}
			return md.render(this.text);
		}
	},

	mounted() {
		this.loadAnswer();
	},

	methods: {
		loadAnswer: async function() {
			this.loading = true;
			this.text = null;
			this.error = null;
			try {
				this.text = this.explanationType === 'context'
					? await getContextExplanation(this.wordId, this.sentenceId, this.inputWord)
					: await getOriginsExplanation(this.wordId);
			} catch (e) {
				this.error = e.toString();
			}
			this.loading = false;
		},
	},

	watch: {
		wordId: function() {
			this.loadAnswer();
		},
	},

	components: {
		Button,
		Modal,
		ModalContentCourse,
		ThreeDotsLoading,
		AiAvatar,
	},
};
</script>

<style lang="stylus" scoped>
@import "../../components/style.styl";

.modal-buttons
	text-align right

.error
	display grid
	justify-content center
	align-items center
	color error-red

.modal-contents
	margin-top 40px
	overflow auto

.chat-message
	display grid
	column-gap 16px
	.avatar img
		width 40px
		height 40px
		border-radius 100%
	&.user-message
		grid-template-columns 1fr auto
	&.bot-message
		grid-template-columns auto 1fr
	& .message
		background #F6F6F6
		color #292929
		padding 14px 20px
		border-radius 12px
		font-size 14px
		line-height 20px
	& .message.pre
		white-space pre-wrap
	& .message >>> p
		margin-top 16px
		&:first-child
			margin-top 0
	& .message >>> h1,
	& .message >>> h2,
	& .message >>> h3,
	& .message >>> h4,
	& .message >>> h5,
	& .message >>> h6
		margin-top 16px
		&:first-child
			margin-top 0
		& + p
			margin-top 8px
	& .message >>> ol,
	& .message >>> ul
		margin-top 16px
		margin-left 32px
	& .message >>> li
		margin-top 16px
	& + &
		margin-top 32px
</style>
