export default function(word) {
	word = word.toLowerCase();
	word = word.split(/[ἐἑέέὲἒἓἔἕ]/).join('ε');
	word = word.split(/[ὀὁόὸὂὃὄὅ]/).join('ο');
	word = word.split(/[ὐὑύὺῦὖὗὒὓὔὕ]/).join('υ');
	word = word.split(/[ἰἱίὶῖἶἷἲἳἴἵϊΐῗῒ]/).join('ι');
	word = word.split(/[ἀἁάάὰᾶἂἃἄἅἆἇ]/).join('α');
	word = word.split(/[ᾳᾁᾀᾴᾲᾷᾆᾇᾂᾃᾄᾅ]/).join('α');
	word = word.split(/[ὠὡώὼῶὦὧὢὣὤὥ]/).join('ω');
	word = word.split(/[ῳᾠᾡῴῲῷᾦᾧᾢᾣᾤᾥ]/).join('ω');
	word = word.split(/[ἡἠήῆὴἦἧἢἣἤἥ]/).join('η');
	word = word.split(/[ῃᾐᾑήῄῇῂᾖᾗᾒᾓᾔᾕ]/).join('η');
	word = word.replace(/[;.,·]$/gi, '');
	word = word.replace(/^[;.,·]/, '');
	word = word.replace(/[^Α-Ωα-ωίϊΐόάέύϋΰήώ]/gi, '')
	return word;
}