import config from '@/lib/config';
import { logout } from '@/lib/api';

const debugLoggedOut = false;
export default {
	namespaced: true,

	state: () => {
		return {
			user: debugLoggedOut ? null : config.user || null,
		};
	},

	actions: {
		logout: async function({ commit }) {
			await logout();
			commit('setUser', null);
		},
	},

	mutations: {
		setUser(state, user) {
			state.user = user;
		},
	},
};
