import { render, staticRenderFns } from "./CourseBlock.vue?vue&type=template&id=31c857f8&scoped=true"
import script from "./CourseBlock.vue?vue&type=script&lang=js"
export * from "./CourseBlock.vue?vue&type=script&lang=js"
import style0 from "./CourseBlock.vue?vue&type=style&index=0&id=31c857f8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c857f8",
  null
  
)

export default component.exports