<template>
    <div class="modal-content" @click="$emit('click')">
        <div class="header">
            <h1>{{ header }}</h1>
            <Icon type="crosshair" class="crosshair-button" @click="$emit('close')" />
        </div>
        <div class="description" v-if="description">
            {{ description }}
        </div>
        <div class="info" v-if="info">
            {{ info }}
        </div>
        <div class="content">
            <slot name="default" />
        </div>
        <div class="buttons">
            <slot name="buttons" />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon';

export default {
	name: 'ModalContentCourse',

	props: {
        header: {
            type: String,
            required: true
        },
        description: {
            type: String,
        },
        info: String,
	},

	components: {
		Icon,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.modal-content
    padding 28px 40px
    overflow hidden
    display grid
    grid-template-rows auto auto auto 1fr auto

.header
    grid-row 1
    display grid
    grid-template-columns 1fr auto
    h1
        font-size 24px
        line-height 32px
    .crosshair-button
        opacity 0.3
        transition 0.3s
        cursor pointer
        &:hover
            opacity 0.4

.description
    grid-row 2
    margin-top 8px
    color text-secondary
    font-size 14px
    line-height 20px

.info
    grid-row 3
    margin-top 16px
    color text-primary
    font-size 14px
    line-height 20px

.content
    grid-row 4
    display grid
    overflow hidden

.buttons
    grid-row 5
    padding-top 14px
</style>
