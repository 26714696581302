<template lang="pug">
.words-list
	.word(
		v-for="word in words"
		:key="word.id"
		:class="['item', isSelected(word.id) && 'selected']"
		@click="$emit('wordClick', word)"
		:title="word.description"
	)
		//- icon
		svg(
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		)
			path(
				d="M10.8192 5.14396C10.8192 4.98771 10.769 4.85936 10.6685 4.75891L9.90681 4.00556C9.80078 3.89954 9.67522 3.84652 9.53013 3.84652C9.38504 3.84652 9.25949 3.89954 9.15346 4.00556L5.73828 7.41237L3.84654 5.52063C3.74051 5.4146 3.61495 5.36159 3.46986 5.36159C3.32477 5.36159 3.19922 5.4146 3.09319 5.52063L2.33147 6.27398C2.23102 6.37442 2.1808 6.50277 2.1808 6.65902C2.1808 6.80969 2.23102 6.93525 2.33147 7.0357L5.36161 10.0658C5.46763 10.1719 5.59319 10.2249 5.73828 10.2249C5.88895 10.2249 6.0173 10.1719 6.12332 10.0658L10.6685 5.52063C10.769 5.42018 10.8192 5.29462 10.8192 5.14396ZM12.9286 6.49998C12.9286 7.66628 12.6412 8.74189 12.0664 9.72682C11.4916 10.7118 10.7118 11.4916 9.72684 12.0664C8.74191 12.6412 7.66629 12.9286 6.5 12.9286C5.3337 12.9286 4.25809 12.6412 3.27316 12.0664C2.28822 11.4916 1.50837 10.7118 0.933592 9.72682C0.358815 8.74189 0.0714264 7.66628 0.0714264 6.49998C0.0714264 5.33369 0.358815 4.25807 0.933592 3.27314C1.50837 2.28821 2.28822 1.50835 3.27316 0.933576C4.25809 0.3588 5.3337 0.0714111 6.5 0.0714111C7.66629 0.0714111 8.74191 0.3588 9.72684 0.933576C10.7118 1.50835 11.4916 2.28821 12.0664 3.27314C12.6412 4.25807 12.9286 5.33369 12.9286 6.49998Z"
				fill="currentColor"
			)

		//- word
		span(
			v-if="selectPart && word.text.toLowerCase().includes(selectPartPrepared)"
		)
			span.word.with-selection.notranslate
				| {{ word.text.slice(0, word.text.toLowerCase().indexOf(selectPartPrepared))}}
				b {{ selectPartPrepared }}
				| {{ word.text.slice(word.text.toLowerCase().indexOf(selectPartPrepared) + selectPartPrepared.length) }}

			PartOfSpeechBadge(:content="word.partOfSpeech")
		span(v-else)
			span.word.without-selection.notranslate {{(word.partOfSpeech === 'verb' ? 'to ' : '') + word.text}}

			PartOfSpeechBadge(:content="word.partOfSpeech")

		//- description
		span(
			v-if="word.description && word.description !== '-'"
			v-html="' – ' + (word.description.split(' | ')[1] || word.description)"
		)
</template>

<script>
import PartOfSpeechBadge from '@/components/PartOfSpeechBadge';

export default {
	name: 'WordsList',
	props: {
		words: Array,
		isSelected: Function,
		selectPart: String
	},
	computed: {
		selectPartPrepared: function () {
			return this.selectPart.toLowerCase().trim();
		}
	},
	components: {
		PartOfSpeechBadge
	}
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";
.words-list
	padding 10px 0
	max-height 50vh
	overflow auto
	.item
		height 32px
		overflow hidden
		white-space nowrap
		text-overflow ellipsis
		font-weight 400
		cursor pointer
		line-height 32px
		text-align left
		color #444
		padding-left 3px
		svg
			color #D7D7D7
			transform scale(1.2)
			margin-right 10px
			vertical-align middle
	.item.selected
		font-weight 600
		svg
			color main-color
	.item.create-item
		font-style italic
		font-weight 300
		svg
			color #B6B6B6
	& >>> .part-of-speech-badge
		margin-left 4px
</style>
