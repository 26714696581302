<template>
	<Page mode="with-sidebar" selectedItem="my-words" :pageName="i18n('menu', 'myWords')">
		<PageSection :title="i18n('myWords', 'myCollectionsTitle')">
			<div class="courses linear">
				<div v-if="status === 'loading'" class="spinner-container">
					<Spinner size="medium" />
				</div>
				<div v-else-if="status === 'error' || !data" class="error-container">
					<p>{{ error }}</p>
					<Button design="ghost-action" @click="load()">{{ i18n('errors', 'retryButtonText') }}</Button>
				</div>
				<div v-else class="courses-list">
					<div class="add-collection" @click="addCourse">
						<Icon type="add" />
						<div class="add-collection-title">{{ i18n('myWords', 'createCollectionTitle') }}</div>
						<div class="add-collection-description">{{ i18n('myWords', 'createCollectionDescription') }}</div>
					</div>
					<CourseBlock
						v-for="course in data.courses"
						:key="course.id"
						:title="course.title === '{{my_words}}' ? i18n('courseList', 'myWords') : course.title"
						:avatar="course.avatar"
						mode="author"
						:subtitle="course.description /* i18n('courseList', 'courseSubtitle')(course.wordsCount, course.unknownWordsCount, +course.forgotCount, (+course.wordsCount) - (+course.learnedCount)) */"
						@click="goToTraining(course)"
						@edit="editCourse(course)"
						@show="showCourse(course)"
					>
						<div class="course-block-progress-bar">
							<CourseProgressBar
								:fullyLearned="Number(course.learnedCount - course.forgotCount)"
								:totalLearned="Number(course.learnedCount)"
								:total="Number(course.wordsCount)"
							/>
						</div>
					</CourseBlock>
				</div>
			</div>
		</PageSection>

		<PageSection :title="i18n('myWords', 'learningProgressTitle')">
			<div v-if="status === 'loading'" class="spinner-container">
				<Spinner size="medium" />
			</div>
			<div v-else-if="status === 'error' || !data" class="error-container">
				<p>{{ error }}</p>
				<Button design="ghost-action" @click="load()">{{ i18n('errors', 'retryButtonText') }}</Button>
			</div>
			<div v-else>
				<div class="stat-blocks">
					<div class="stat-block clickable" @click="$router.push({ path: $route.path, query: { showwords: true } });">
						<div class="stat-block-title">
							{{ i18n('myWords', 'wordsStat')(data.stats.wordsLearned) }}<Icon type="arrowRight" />
						</div>
						<div class="stat-block-description">{{ i18n('myWords', 'wordsDescription') }}</div>
					</div>
					<div class="stat-block">
						<div class="stat-block-title">
							{{ i18n('myWords', 'coursesStat')(data.stats.coursesFinished) }}<Icon type="arrowRight" />
						</div>
						<div class="stat-block-description">{{ i18n('myWords', 'coursesDescription') }}</div>
					</div>
					<div class="stat-block">
						<div class="stat-block-title">
							{{ timeSpent }}
						</div>
						<div class="stat-block-description">{{ i18n('myWords', 'hoursDescription') }}</div>
					</div>
				</div>
				<div class="stat-graph">
					<WordsGraph :values="data.stats.userStats" />
				</div>
			</div>
		</PageSection>

		<ModalEditCourse
			v-if="$route.query.edit || $route.query.create === 'true'"
			:mode="$route.query.create === 'true' ? 'create' : 'edit'"
			:courseId="Number($route.query.edit)"
			@closeSuccess="$router.push({ path: $route.path, query: { update: true } })"
			@close="$router.push({ path: $route.path, query: { update: $route.query.update === 'true' } })"
			@editCourseProps="$router.replace({ path: $route.path, query: { editProps: $route.query.edit, update: true } })"
		/>
		<!-- this modal doesn't seem to be needed here -->
		<!-- <ModalShowCourse
			v-if="$route.query.show"
			:courseId="Number($route.query.show)"
			@close="$router.push({ path: $route.path, query: {} })"
		/> -->
		<ModalMyWords
			v-if="$route.query.showwords === 'true'"
			@close="$router.push({path: $route.path, query: {} })"
		/>
	</Page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Page from '@/components/Page';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Switcher from '@/components/Switcher';
import Spinner from '@/components/Spinner';
import PageSection from '@/components/PageSection';
import Icon from '@/components/Icon';
import WordsGraph from '@/components/WordsGraph';

import { addCourseToFavorite } from '@/lib/api';
import {
	ModalShowCourse,
	ModalEditCourse,
	CourseBlock,
} from '@/views/course';
import ModalMyWords from './ModalMyWords';

import CourseProgressBar from '@/components/CourseProgressBar';

export default {
	name: 'MyWordsPage',

	data: function() {
		return {};
	},

	inject: ['i18n'],

	computed: {
		...mapState('userTrainingStats', {
			status: state => state.status,
			data: state => state.data,
			error: state => state.error,
		}),

		timeSpent: function() {
			const secondsSpent = this.data.stats.secondsSpent;
			if (secondsSpent < 60) {
				return this.i18n('myWords', 'secondsStat')(secondsSpent | 0);
			}
			const minutesSpent = +(secondsSpent / 60).toFixed(2);
			if (minutesSpent < 60) {
				return this.i18n('myWords', 'minutesStat')(minutesSpent | 0);
			}
			const hoursSpent = +(minutesSpent / 60).toFixed(2);
			return this.i18n('myWords', 'hoursStat')(hoursSpent | 0);
		},

		// слегка костыль: мы ждём, пока update будет true, и при
		// этом не будет открыто окно редактирования
		needToUpdate: function() {
			return (
				this.$route.query.update === 'true' &&
				this.$route.query.edit === undefined
			);
		},
	},

	mounted: function() {
		this.load();
	},

	methods: {
		...mapActions('userTrainingStats', {
			load: 'load',
		}),

		addCourse: function() {
			this.$router.push({ path: this.$route.path, query: { create: true } });
		},

		goToTraining: function(course) {
			if (!course.favorite) {
				addCourseToFavorite(course.id, true);
			}
			this.$router.push(`/course/${course.id}/training`);
		},

		editCourse: function(course) {
			this.$router.push({ path: this.$route.path, query: { edit: course.id } });
		},
	},

	watch: {
		needToUpdate: function(val) {
			if (val) {
				this.load();
			}
			this.$router.replace({
				path: this.$route.path,
				query: {},
			});
		},
	},

	components: {
		CourseBlock,
		CourseProgressBar,
		Switcher,
		Spinner,
		PageSection,
		Icon,
		WordsGraph,

		Page,
		Badge,
		Button,
		ModalEditCourse,
		ModalShowCourse,
		ModalMyWords,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.spinner-container
	text-align center
	padding-top 20px

.error-container
	text-align center
	font-size 18px
	padding-top 20px
	color error-red

	.button
		width 150px
		margin-top 20px

.course-block-progress-bar
	padding 20px 24px
	width 329px

.courses-list
	display grid
	grid-template-columns auto auto auto auto auto auto
	row-gap rowGap
	column-gap columnGap
	justify-content center
	// margin-bottom 250px

.courses.linear
	overflow-x auto
	overflow-y hidden
	padding-bottom 12px
	scrollbar-color transparent transparent
	transition 0.3s scrollbar-color
	.courses-list
		display flex
		justify-content start
		align-items stretch
		column-gap 24px
	/*
	.courses-list
		display block
		white-space nowrap
		.course-block
			width 376px
			display inline-block
			box-sizing border-box
			white-space normal
		.course-block + .course-block
			margin-left 24px */

.courses.linear:hover
	scrollbar-color unset

.stat-blocks
	display grid
	grid-template-columns 1fr 1fr 1fr
	column-gap 24px
	.stat-block
		background white
		text-align center
		border-radius 16px
		padding 20px 24px
		box-sizing border-box
		.stat-block-title
			font-weight 500
			color #292929
			font-size 20px
			line-height 28px
			margin-bottom 4px
			.icon
				vertical-align sub
				color #BDBDBD
		.stat-block-description
			color #656565
			font-weight 300
			font-size 14px
			line-height 20px
		&.clickable
			cursor pointer
		&.clickable:hover
			background #fcfcfc
		&.clickable:active
			background #f6f6f6

.stat-graph
	margin-top 24px
	background white
	border-radius 16px
	padding 20px 24px

.add-collection
	display inline-block
	background white
	border-radius 16px
	padding 20px 24px
	box-sizing border-box
	cursor pointer
	text-align center
	.icon
		color main-color
		width 24px
		margin-bottom 16px
		transition color 0.3s
	&:hover .icon
		color lighten(main-color, 10)
	&:active .icon
		color darken(main-color, 10)
	.add-collection-title
		font-size 16px
		font-weight 600
		line-height 20px
		width 329px
		margin-bottom 4px
	.add-collection-description
		font-weight 300
		font-size 14px
		line-height 20px
		color #656565
</style>
