<template>
	<Modal
		:open="true"
		:maximumWidth="600"
		:widthPageMargin="30"
		key="wordsModal"
		:loading="courseLoading"
		v-if="true"
		@paranjaClick="courseLoading && $emit('close')"
	>
		<ModalContentCourse
			:header="courseMetadata.header"
			:description="courseMetadata.description"
			:info="courseMetadata.info"
			@close="$emit(saved ? 'closeSuccess' : 'close')"
			@click="searchWordsOpen = false"
		>
			<template>
			<!-- <Button
						design="link-primary"
						:noHorizontalMargins="true"
					>
						<Icon type="bookmark" />
						{{ i18n('showCourse', 'addToBookmarks') }}</Button> -->
				<div class="modal-contents-wordslist" v-if="step === 0">
					<Switcher
						design="buttons"
						:options="[{
							title: i18n('createCourse', 'searchWords'),
							value: 'search'
						}, {
							title: i18n('createCourse', 'uploadAFile'),
							value: 'upload'
						}/*, {
							title: i18n('createCourse', 'importFromMemrise'),
							value: 'memrise'
						}, {
							title: i18n('createCourse', 'importFromQuizlet'),
							value: 'quizlet'
						}*/]"
						v-model="selectWordsType"
					/>
					<div v-if="selectWordsType === 'search'" @click="$event.stopPropagation()">
						<TextField
							:value="searchWord"
							@input="
								searchWord = $event;
							"
							:placeholder="i18n('editCourse', 'searchWordPlaceholder')"
							@focus="searchWord !== '' && (searchWordsOpen = true)"
							rightIcon="search"
							ref="searchField"
							design="v2-search-words"
						>
							<template #righticon>
								<a href="#" class="search-clear" @click="searchWord = ''; $refs.searchField.focus()">
									<Icon type="crosshair" />
								</a>
							</template>
						</TextField>
						<Popup style="width: 520px" v-if="searchWordsOpen">
							<div class="searched-words-loading" v-if="searchWordsLoading">
								<Spinner />
							</div>
							<div class="searched-words-empty" v-else-if="!wordsFound || wordsFound.length === 0">
								{{i18n('editCourse', 'noWordsFound')}}
							</div>
							<div class="searched-word-list" v-else>
								<div
									v-for="word in wordsFound"
									:key="word.id"
									:class="['word', wordStaysInCourse(word.id) && 'selected']"
									@click="onSearchedWordClick(word)"
								>
									<div class="word-name" :title="word.transcription">
										{{word.textBissected[0]}}<b>{{word.textBissected[1]}}</b>{{word.textBissected[2]}}
									</div>
									<div class="word-translation">{{word.translation}}</div>
									<div class="word-pos">
										<PartOfSpeechBadge :content="word.partOfSpeech" />
									</div>
								</div>
							</div>
						</Popup>
					</div>
					<div v-if="selectWordsType === 'upload'">
						<FileUploader
							:formats="['xlsx', 'csv']"
							@choose="uploadFile"
						/>
					</div>
					<!-- <div v-if="selectWordsType === 'memrise'">
						Only English words that exist in the dictionary will be imported.

						<span class="premium">For Premium users you can import non-dictionary words.</span>
					</div>
					<div v-if="selectWordsType === 'quizlet'">Source</div> -->
					<div class="words-section">
						<h5 class="heading-h5" v-if="courseWords && courseWords.length > 0">{{i18n('editCourse', 'addedWords')}}</h5>
						<div class="words-list">
							<WordsListV2
								v-if="courseWords && courseWords.length > 0"
								:words="courseWords"
								:removedWordsIds="courseWordsToDelete"
								:showDelete="true"
								@delete="onWordDeleteClick"
								:chosenCategory="wordsListChosenCategory"
								@changeCategory="wordsListChosenCategory = $event"
							/>
						</div>
					</div>
				</div>

				<div class="modal-contents-edit" v-if="step === 1">
					<label class="textfield-label">
						{{i18n('editCourse', 'titleInputLabel')}}
						<TextField
							:value="courseName"
							@input="
								courseName = $event;
							"
							:placeholder="i18n('editCourse', 'titleInputPlaceholder')"
							:maxlength="255"
							design="v2-search-words"
						/>
					</label>

					<label class="textfield-label" style="margin-top: 24px; display: block">
						{{i18n('editCourse', 'descriptionInputLabel')}}
						<TextField
							:value="courseDescription"
							@input="
								courseDescription = $event;
							"
							:placeholder="i18n('editCourse', 'descriptionInputPlaceholder')"
							:maxlength="255"
							design="v2-search-words"
						/>
					</label>

					<label
						class="field-label"
						style="margin-top: 48px; cursor: pointer"
						@click="courseShared = !courseShared"
					>
						<div class="field-label-title">{{i18n('editCourse', 'privateToggleLabel')}}</div>
						<div class="field-label-description">{{i18n('editCourse', 'privateToggleDescription')}}</div>
						<div class="field-label-field" @click.stop>
							<ToggleSwitch :value="!courseShared" @input="courseShared = !$event" />
						</div>
					</label>

					<div v-if="courseShared">
						<label
							class="field-label"
							style="margin-top: 24px"
						>
							<div class="field-label-title">{{i18n('editCourse', 'levelLabel')}}</div>
							<div class="field-label-description">{{i18n('editCourse', 'levelDescription')}}</div>
							<div class="field-label-field">
								<Selectbox :options="levelSwitcher.options" v-model="courseLevel" :rightPositioned="true" />
							</div>
						</label>

						<label
							class="field-label"
							style="margin-top: 24px"
						>
							<div class="field-label-title">{{i18n('editCourse', 'topicLabel')}}</div>
							<div class="field-label-description">{{i18n('editCourse', 'topicDescription')}}</div>
							<div class="field-label-field">
								<Selectbox :options="topicSwitcher.options" v-model="courseTopic" :rightPositioned="true" />
							</div>
						</label>
					</div>
				</div>
			</template>
			<template #buttons>
				<div class="modal-buttons" v-if="mode === 'create'">
					<Button
						v-if="step === 1"
						design="shadow-action"
						@click="step = 0"
					>{{i18n('editCourse', 'goBackButtonLabel')}}</Button>
					<div style="width: 150px" v-else />

					<div class="step-container">{{i18n('editCourse', 'step')(step + 1, 2)}}</div>
					<Button
						v-if="step === 0"
						design="action"
						@click="step = 1"
					>{{i18n('editCourse', 'proceedButtonLabel')}}</Button>
					<Button
						v-else
						design="action"
						:showSpinner="courseSaveLoading"
						:showContent="!courseSaveLoading"
						@click="onSave"
					>{{i18n('editCourse', 'saveButtonLabel')}}</Button>
				</div>
				<div class="modal-buttons" v-else>
					<Button
						design="shadow-action"
						@click="step = step === 0 ? 1 : 0"
					>{{i18n('editCourse', step === 0 ? 'settingsButtonLabel' : 'goBackButtonLabel')}}</Button>

					<div class="step-container">{{i18n('editCourse', 'step')(step + 1, 2)}}</div>
					<Button
						design="action"
						:showSpinner="courseSaveLoading"
						:showContent="!courseSaveLoading"
						@click="onSave"
					>{{i18n('editCourse', 'saveButtonLabel')}}</Button>
				</div>
			</template>
		</ModalContentCourse>
	</Modal>
</template>

<script>
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Link from '@/components/Link';
import TextField from '@/components/TextField';
import Popup from '@/components/Popup';
import Spinner from '@/components/Spinner';
import Switcher from '@/components/Switcher';
import WordsList from '@/components/WordsList';
import FileUploader from '@/components/FileUploader';
import PartOfSpeechBadge from '@/components/PartOfSpeechBadge';
import WordsListV2 from '@/components/WordsListV2.vue';
import ToggleSwitch from '@/components/ToggleSwitch';
import Selectbox from '@/components/Selectbox';

import ModalContentCourse from '@/components/ModalContentCourse.vue';

import { getCourseById, searchWords, editCourseWords, editCourse, createWord, searchManyWords, createCourse, addCourseToFavorite } from '@/lib/api';
import { debounceAfter } from '@/lib/utils';
import { readFileData } from '@/lib/file';

const searchWordsDebounced = debounceAfter(searchWords, 500);

export default {
	name: 'ModalEditCourse',

	props: {
		mode: String, // 'create' or 'edit'
		courseId: Number,
	},

	inject: ['i18n'],

	data: function() {
		const i18n = this.i18n;
		return {
			// course loading
			courseLoading: false,
			courseData: null,
			courseWords: null,
			courseWordsToDelete: null, // can't use Set - vue doesn't watch it

			// course saving
			courseSaveLoading: false,
			saved: false, // used for whether to emit('close') or emit('closeSuccess')

			// steps
			step: 0,

			// selecting words
			selectWordsType: 'search',
			wordsListChosenCategory: null,

			// searching words
			searchWord: '',
			searchWordsOpen: false,
			searchWordsLoading: false,
			wordsFound: null,

			// course editing
			// shared: false,
			courseName: '',
			courseDescription: '',
			courseShared: true,
			courseTopic: null,
			courseLevel: 0,

			levelSwitcher: {
				options: [{
					value: 0,
					title: i18n('editCourse', 'levelNotDefined')
				}, {
					value: 1,
					title: i18n('courseList', 'courseLevel1')
				}, {
					value: 3,
					title: i18n('courseList', 'courseLevel3')
				}, {
					value: 5,
					title: i18n('courseList', 'courseLevel5')
				}]
			},

			topicSwitcher: {
				options: [
					{
						value: null,
						title: i18n('editCourse', 'noTopic'),
					},
					{
						value: 0,
						title: i18n('courseList', 'categoryTopic'),
						description: i18n('courseList', 'categoryTopicDescription'),
					},
					{
						value: 1,
						title: i18n('courseList', 'categoryContent'),
						description: i18n('courseList', 'categoryContentDescription'),
					},
					{
						value: 2,
						title: i18n('courseList', 'categoryCompare'),
						description: i18n('courseList', 'categoryCompareDescription'),
					},
				],
			}
		};
	},

	computed: {
		courseMetadata() {
			if (this.mode === 'create') {
				return {
					header: this.i18n('createCourse', 'createACollection'),
					description: this.i18n('createCourse', 'description'),
				};
			}

			if (!this.courseData) {
				return {
					header: '',
					description: '',
					info: ''
				};
			}

			const info = [];
			const courseLevelStr = this.i18n('courseList', 'courseLevel' + this.courseData.courseLevel);
			if (courseLevelStr) {
				info.push(courseLevelStr);
			}
			if (this.courseData.wordsCount > 0) {
				info.push(this.i18n('showCourse', 'wordsNumber')(this.courseData.wordsCount));
			}
			return {
				header: this.courseData.title,
				description: info.join(' • '),
				info: this.courseData.description,
			};
		}
	},

	mounted() {
		const populateDataFromCourseData = () => {
			this.courseData.wordsCount = this.courseData.Words.length;
			this.courseWords = this.courseData.Words;
			this.courseWordsToDelete = [];
			this.courseName = this.courseData.title;
			this.courseDescription = this.courseData.description || '';
			this.courseShared = this.courseData.shared;
			this.courseTopic = this.courseData.categoryId;
			this.courseLevel = this.courseData.courseLevel;
			this.saved = false;
		};

		if (this.mode === 'create') {
			this.courseData = {
				title: '',
				description: null,
				shared: false,
				avatar: '',
				Words: [],
				wordsCount: 0,
				categoryId: null,
				courseLevel: 0
			};
			populateDataFromCourseData();
			this.courseLoading = false;
		} else {
			this.courseLoading = true;
			getCourseById(this.courseId).then(course => {
				this.courseData = course;
				populateDataFromCourseData();
				this.courseLoading = false;
			});
		}

		this.$nextTick(() => {
			this.$refs.searchField?.focus();
		});

		window.addEventListener('focus', this.onWindowFocus);
	},

	unmounted: function() {
		window.removeEventListener('focus', this.onWindowFocus);
	},

	methods: {
		wordIndexInCourse: function(wordId) {
			const word = this.courseWords.find(({id}) => id === wordId);
			return this.courseWords.indexOf(word);
		},

		wordExistsInCourse: function(wordId) {
			return this.wordIndexInCourse(wordId) !== -1;
		},

		wordStaysInCourse: function(wordId) {
			return this.wordExistsInCourse(wordId) && !this.courseWordsToDelete.includes(wordId);
		},

		onWordDeleteClick: function(word) {
			if (this.courseWordsToDelete.includes(word.id)) {
				this.courseWordsToDelete.splice(this.courseWordsToDelete.indexOf(word.id), 1);
			} else {
				this.courseWordsToDelete.push(word.id);
			}
		},

		onSearchedWordClick: function(word) {
			if (!this.wordExistsInCourse(word.id)) {
				// слова ещё нет в курсе
				this.addWord(word);
			} else if (this.courseWordsToDelete.includes(word.id)) {
				// слово есть в курсе, но к удалению
				this.courseWordsToDelete.splice(this.courseWordsToDelete.indexOf(word.id), 1);
			} else {
				// слово есть в курсе
				this.courseWordsToDelete.push(word.id);
			}
		},

		addWord: function(word) {
			this.courseWords.push(word);
			this.wordsListChosenCategory = word.partOfSpeech.split('|')[0];
		},

		onSave: async function() {
			this.courseSaveLoading = true;
			if (this.mode === 'create') {
				// 1. create the course
				const id = await createCourse({
					title: this.courseName,
					description: this.courseDescription,
					shared: this.courseShared,
					categoryId: this.courseTopic,
					levelId: this.courseLevel,
				});
				// 2. save the words into it
				// 3. add it to favorites because user isn't smart, they will forget where to find their course
				await Promise.all([
					this.saveWordsToCourse(id),
					addCourseToFavorite(id, true)
				]);
				this.$emit('closeSuccess');
			} else {
				await Promise.all([
					this.saveWordsToCourse(this.courseId),
					editCourse(this.courseId, {
						title: this.courseName,
						description: this.courseDescription,
						shared: this.courseShared,
						categoryId: this.courseTopic,
						levelId: this.courseLevel
					})
				]);
				// and save the props of the course
			}
			this.saved = true;
			this.courseSaveLoading = false;
		},

		async saveWordsToCourse(courseId) {
			const filteredCourseWords = this.courseWords.filter(word => {
				return !this.courseWordsToDelete.includes(word.id);
			});
			await editCourseWords(courseId, {
				wordIds: filteredCourseWords.map(word => word.id)
			});
			this.courseWords = filteredCourseWords;
		},

		onWindowFocus: function() {
			this.$nextTick(() => {
				this.$refs.searchField?.focus();
			});
		},

		async uploadFile(file) {
			// todo: lazy load of xlsx.js lib here
			// no need to load it for everyone always
			const fileData = await readFileData(file);

			const identifyColumns = () => {
				const POS_OPTIONS = [
					'verb',
					'noun',
					'adjective',
					'adverb',
					'pronoun',
					'phrase',
					'idiom',
					'numeral',
					'determiner',
					'conjunction',
					'preposition',
					'particle',
					'interjection',
					'collocation',
					'other'
				];
				const wordCounts = {};
				const posCounts = {};
				const otherCounts = {}; // used for word if we didn't find [a-z]+
				for(let rowIndex in fileData) {
					const row = fileData[rowIndex];
					for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
						const cellContent = row[cellIndex].trim();
						if (POS_OPTIONS.includes(cellContent)) {
							if (!posCounts[cellIndex]) posCounts[cellIndex] = 0;
							posCounts[cellIndex]++;
						} else if (/[a-z]+/.test(cellContent)) {
							if (!wordCounts[cellIndex]) wordCounts[cellIndex] = 0;
							wordCounts[cellIndex]++;
						} else {
							if (!otherCounts[cellIndex]) otherCounts[cellIndex] = 0;
							otherCounts[cellIndex]++;
						}
					}
				}

				const wordCountsSorted = Object.entries(wordCounts).sort((a, b) => b[1] - a[1]);
				const posCountsSorted = Object.entries(posCounts).sort((a, b) => b[1] - a[1]);
				const otherCountsSorted = Object.entries(otherCounts).sort((a, b) => b[1] - a[1]);

				let wordsIndex;
				let posIndex;
				if (wordCountsSorted[0][0] !== posCountsSorted[0][0]) {
					wordsIndex = wordCountsSorted[0][0];
					posIndex = posCountsSorted[0][0];
				} else if (posCountsSorted.length === 1) {
					posIndex = posCountsSorted[0][0];
					wordsIndex = wordCountsSorted[1][0];
				} else if (wordCountsSorted.length === 1) {
					wordsIndex = wordCountsSorted[0][0];
					posIndex = posCountsSorted[1][0] || null;
				} else {
					posIndex = null;
					wordsIndex = wordCountsSorted[0][0];
				}

				if (!wordsIndex) {
					wordsIndex = otherCountsSorted[0][0];
				}

				return { wordsIndex, posIndex }
			};

			const getWordsList = ({ wordsIndex, posIndex }) => {
				const getWord = (word, pos) => {
					word = word.trim();

					if (word.indexOf('to ') === 0) {
						word = word.slice(3);
						pos = pos || 'verb';
					} else if (word.indexOf('a ') === 0) {
						word = word.slice(2);
						pos = pos || 'noun';
					} else if (word.indexOf('the ') === 0) {
						word = word.slice(4);
						pos = pos || 'noun';
					}
					word = word.trim().replace(/\s+/gi, ' ').toLowerCase();
					return {
						word,
						pos,
					};
				};

				return fileData.map(row => {
					if (!row[wordsIndex]) {
						return null;
					}

					return getWord(row[wordsIndex], row[posIndex]);
				}).filter(x => x);
			};

			const getBackendMatchingWords = (wordsFromFile, wordsOnBackend) => {
				const wordKey = (word, pos) => `${word}_${pos}`;
				// we push an array of words for each key because of homophones
				// e.g. 'make out': [<make out as discern>, <make out as kiss>, etc]
				const dictionary = {};
				const pushIntoDictionary = (key, element) => (dictionary[key] || (dictionary[key] = [])).push(element);
				wordsOnBackend.forEach(word => {
					const pos = word.partOfSpeech.split('|')[0];
					pushIntoDictionary(wordKey(word.text, pos), word);
					// use word.text as key as well for words that didn't specify pos
					pushIntoDictionary(word.text, word);
				});

				return wordsFromFile.flatMap(({ word, pos }) => {
					const key = wordKey(word, pos);
					return (pos ? dictionary[key] : dictionary[word]) || [];
				});
			}

			const { wordsIndex, posIndex } = identifyColumns();
			const wordsFromFile = getWordsList({ wordsIndex, posIndex });
			const wordsOnBackend = await searchManyWords(wordsFromFile.map(({ word }) => word));
			getBackendMatchingWords(wordsFromFile, wordsOnBackend).forEach(word => {
				if (!this.wordExistsInCourse(word.id)) {
					this.addWord(word);
				}
			});
		},
/*
		createWord: async function() {
			this.wordsFound = null;
			this.loading = true;
			const wordToCreate = this.searchWord.trim();
			try {
				const words = await createWord(wordToCreate);
				if (this.searchWord.trim() === wordToCreate) {
					this.wordsFound = words;
				}
			} catch (e) {
				if (this.searchWord.trim() === wordToCreate) {
					this.wordsFound = [];
				}
			} finally {
				this.loading = false;
			}

			this.$nextTick(() => {
				this.$refs.searchField?.focus();
			});
		}, */
	},

	watch: {
		searchWord: async function(newVal) {
			const isLanguageEnglish = true;
			const preprocessForSearch = (val) => {
				val = val.trim();
				if (val.indexOf('to ') === 0 && isLanguageEnglish) {
					val = val.slice(3);
				}
				return val;
			};

			newVal = newVal.trim();
			if(newVal === ''){
				this.wordsFound = null; //this.studiedWords.length > 0 ? [...this.studiedWords] : null;
				this.searchWordsOpen = false;
				return;
			}

			newVal = preprocessForSearch(newVal);

			this.wordsFound = null;
			this.searchWordsLoading = true;
			this.searchWordsOpen = true;
			try {
				const data = await searchWordsDebounced(newVal);
				const searchWord = preprocessForSearch(this.searchWord);
				// когда запрос завершён, уже всё могло измениться
				if (searchWord === newVal) {
					data.forEach(word => {
						let text = word.text;
						if (word.partOfSpeech.split('|').includes('verb') && isLanguageEnglish) {
							text = 'to ' + word.text;
						}

						const index = text.toLowerCase().indexOf(searchWord.toLowerCase());
						if (index > -1) {
							word.textBissected = [
								text.slice(0, index),
								text.slice(index, index + searchWord.length),
								text.slice(index + searchWord.length)
							];
						} else {
							word.textBissected = [text, '', ''];
						}
						
						if (word.description.includes(' | ')) {
							const [transcription, translation] = word.description.split(' | ');
							word.transcription = transcription;
							word.translation = translation;
						} else {
							word.transcription = null;
							word.translation = word.description;
						}
					});

					this.wordsFound = data;
				}
			} catch (e) {
				if (this.searchWord === newVal) {
					this.wordsFound = [];
				}
			} finally {
				this.searchWordsLoading = false;
			}
		}
	},

	components: {
		Icon,
		Button,
		Link,
		TextField,
		Spinner,
		Modal,
		Popup,
		Switcher,
		WordsList,
		ModalContentCourse,
		WordsListV2,
		FileUploader,
		PartOfSpeechBadge,
		ToggleSwitch,
		Selectbox
	},
};
</script>

<style lang="stylus" scoped>
@import "../../components/style.styl";

.modal-contents-wordslist
	overflow hidden
	display grid
	grid-template-rows auto auto 1fr
	.switcher
		margin-top 24px
		margin-bottom 24px
	.heading-h5
		font-size 16px
		line-height 20px
		font-weight 500
		margin-top 48px
		margin-bottom 16px

	.words-section
		display grid
		grid-template-rows auto 1fr
		overflow hidden
		.words-list
			display grid
			grid-template-rows auto 1fr
			overflow hidden

.search-clear
	display flex
	justify-content center
	align-items center
	height 46px
	width 46px
	line-height 46px
	color #BDBDBD
	&:hover
		color #9d9d9d
	&:active
		color #7d7d7d
	svg
		width 20px

.searched-words-loading, .searched-words-empty
	display flex
	align-items center
	justify-content center
	padding 12px
	font-size 14px
	font-style italic

.searched-word-list
	padding 4px
	box-sizing border-box
	max-height 70vh
	overflow auto
	transition 0.3s height
	.word
		display grid
		grid-template-columns 120px 1fr auto
		font-size 14px
		line-height 36px
		height 36px
		padding 0 8px
		box-sizing border-box
		cursor pointer
		border-radius 6px
		.word-name
			overflow hidden
			text-overflow ellipsis
			font-weight 300
			b
				font-weight 500
		.word-translation
			overflow hidden
			text-overflow ellipsis
			font-weight 300
			color #656565
		.word-pos
			padding-left 24px
	.word:hover, .word.selected
		background #eee

.modal-contents-edit
	margin-top 24px

.textfield-label
	color #292929
	font-size 12px
	line-height 20px
	font-weight 400
	& > div
		margin-top 8px

.field-label
	display grid
	grid-template-columns 1fr auto
	grid-template-areas "title field" "description field"
	row-gap 4px
	.field-label-title
		grid-area title
		font-size 14px
		line-height 20px
		font-weight 500
		color #292929
	.field-label-description
		grid-area description
		line-height 20px
		font-size 12px
		font-weight 300
		color #656565
	.field-label-field
		grid-area field

.modal-buttons
	display grid
	grid-template-columns auto 1fr auto
	.step-container
		text-align center
		line-height 44px
		color #656565
		font-size 16px
		font-weight 300
</style>
