import { editCourse } from '@/lib/api';
import { createLoadableModel } from './loadable-model';

export default createLoadableModel(async ({ id, data }) => {
	await editCourse(id, {
		title: data.name,
		avatar: data.avatar,
		shared: data.shared,
		topic: data.topic
	});
});
