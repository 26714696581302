var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"login-form",on:{"submit":_vm.sendForm}},[_c('TextField',{ref:"firstField",staticClass:"login",attrs:{"partof":_vm.state === _vm.FORM_STATES.LOGIN_INPUT || _vm.state === _vm.FORM_STATES.LOGIN_LOADING ? 'nothing' : 'top',"size":"big","placeholder":_vm.state === _vm.FORM_STATES.REGISTER_INPUT ? (
				_vm.secondaryMode === _vm.SECONDARY_MODES.LOGIN ? 'Email' : 'Логин'
			) : 'Логин или email',"type":"text","disabled":_vm.state === _vm.FORM_STATES.LOGIN_LOADING || _vm.state === _vm.FORM_STATES.PASSWORD_LOADING || _vm.state === _vm.FORM_STATES.REGISTER_LOADING,"autofocus":"","autocomplete":"username","hint":_vm.loginHint === _vm.HINTS.EMPTY ? 'Введите что-нибудь' :
			_vm.loginHint === _vm.HINTS.USER_EXISTS ? 'Пользователь найден' :
			_vm.loginHint === _vm.HINTS.REGISTER ? 'Такого пользователя ещё нет' :
			_vm.loginHint === _vm.HINTS.USE_GOOGLE ? 'Вы зарегистрированы через Google' :
			null,"hintDesign":_vm.loginHint === _vm.HINTS.EMPTY ? 'error' :
			_vm.loginHint === _vm.HINTS.USER_EXISTS ? 'action' :
			_vm.loginHint === _vm.HINTS.USE_GOOGLE ? 'action' :
			'default'},on:{"input":function($event){_vm.state = _vm.FORM_STATES.LOGIN_INPUT}},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),(_vm.state === _vm.FORM_STATES.REGISTER_INPUT || _vm.state === _vm.FORM_STATES.REGISTER_LOADING)?_c('TextField',{ref:"secondaryField",staticClass:"secondary-field",attrs:{"partof":"middle","size":"big","placeholder":_vm.secondaryMode === _vm.SECONDARY_MODES.LOGIN ? 'Логин' : 'Email',"type":"text","disabled":_vm.state === _vm.FORM_STATES.REGISTER_LOADING,"autocomplete":_vm.secondaryMode === _vm.SECONDARY_MODES.LOGIN ? 'username' : 'email',"hint":_vm.secondaryHint === _vm.HINTS.EMPTY ? 'Введите что-нибудь' :
			_vm.secondaryHint === _vm.HINTS.WRONG ? 'Email неправильный' :
			_vm.secondaryHint === _vm.HINTS.USER_EXISTS ? 'Логин занят' :
			null,"hintDesign":_vm.secondaryHint === _vm.HINTS.EMPTY ? 'error' :
			_vm.secondaryHint === _vm.HINTS.WRONG ? 'error' :
			_vm.secondaryHint === _vm.HINTS.USER_EXISTS ? 'error' :
			'default'},on:{"keydown":function($event){$event.which === 13 && _vm.$refs.passwordField && _vm.$refs.passwordField.focus()}},model:{value:(_vm.secondary),callback:function ($$v) {_vm.secondary=$$v},expression:"secondary"}}):_vm._e(),(_vm.state === _vm.FORM_STATES.PASSWORD_INPUT || _vm.state === _vm.FORM_STATES.REGISTER_INPUT || _vm.state === _vm.FORM_STATES.PASSWORD_LOADING || _vm.state === _vm.FORM_STATES.REGISTER_LOADING)?_c('TextField',{ref:"passwordField",staticClass:"password",attrs:{"partof":"bottom","size":"big","placeholder":"Пароль","type":"password","disabled":_vm.state === _vm.FORM_STATES.PASSWORD_LOADING || _vm.state === _vm.FORM_STATES.REGISTER_LOADING,"autocomplete":"current-password","hint":_vm.passwordHint === _vm.HINTS.EMPTY ? 'Введите что-нибудь' :
			_vm.passwordHint === _vm.HINTS.WRONG ? 'Неправильный пароль' :
			null,"hintDesign":_vm.passwordHint === _vm.HINTS.EMPTY ? 'error' :
			_vm.passwordHint === _vm.HINTS.WRONG ? 'error' :
			'default'},on:{"keydown":function($event){$event.which === 13 && _vm.sendForm()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e(),_c('div',{staticStyle:{"width":"100%","height":"1px","background":"#ececec","margin-top":"25px","margin-bottom":"15px"}}),_c('Button',{staticClass:"send-button",attrs:{"design":"action","size":"big","showSpinner":_vm.state === _vm.FORM_STATES.LOGIN_INPUT ? false :
			_vm.state === _vm.FORM_STATES.LOGIN_LOADING ? true :
			_vm.state === _vm.FORM_STATES.PASSWORD_INPUT ? false :
			_vm.state === _vm.FORM_STATES.PASSWORD_LOADING ? true :
			_vm.state === _vm.FORM_STATES.REGISTER_INPUT ? false :
			_vm.state === _vm.FORM_STATES.REGISTER_LOADING ? true :
			false,"showContent":_vm.state === _vm.FORM_STATES.LOGIN_INPUT ? true :
			_vm.state === _vm.FORM_STATES.LOGIN_LOADING ? false :
			_vm.state === _vm.FORM_STATES.PASSWORD_INPUT ? true :
			_vm.state === _vm.FORM_STATES.PASSWORD_LOADING ? true :
			_vm.state === _vm.FORM_STATES.REGISTER_INPUT ? true :
			_vm.state === _vm.FORM_STATES.REGISTER_LOADING ? true :
			_vm.state === _vm.FORM_STATES.USE_GOOGLE ? true :
			false,"disabled":_vm.state === _vm.FORM_STATES.USE_GOOGLE},on:{"click":_vm.sendForm}},[_vm._v(" "+_vm._s(_vm.state === _vm.FORM_STATES.LOGIN_INPUT ? 'Войти или зарегистрироваться' : _vm.state === _vm.FORM_STATES.LOGIN_LOADING ? '' : (_vm.state === _vm.FORM_STATES.PASSWORD_INPUT || _vm.state === _vm.FORM_STATES.PASSWORD_LOADING) ? 'Войти' : (_vm.state === _vm.FORM_STATES.REGISTER_INPUT || _vm.state === _vm.FORM_STATES.REGISTER_LOADING) ? 'Зарегистрироваться' : 'Продолжить')+" ")]),_c('Button',{staticClass:"send-button",attrs:{"design":"ghost","size":"big"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Закрыть")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }