<template>
	<Modal
		:open="true"
		:maximumWidth="600"
		:widthPageMargin="30"
		key="commentWordsModal"
		@paranjaClick="$emit('close')"
	>
		<div class="modal-content">
			<div class="top">
				<TextField
					style="text-align: center"
					:value="searchWord"
					@input="
						searchWord = $event;
					"
					:placeholder="i18n('editCourse', 'searchWordPlaceholder')"
					ref="searchField"
				/>
			</div>
			<div
				v-if="loading"
				style="padding: 30px; text-align: center; padding-bottom: 10px; font-style: italic"
			>
				<Spinner size="medium" />
			</div>

			<!-- todo: в идеале на onMouseDown мы его выделяем, на onMouseUp на том же слове выбираем-->
			<!-- тогда можно типа mouseDown и увести мышку если передумал-->
			<WordsList
				v-else-if="wordsFound && wordsFound.length > 0"
				:words="wordsFound"
				:isSelected="() => {}"
				@wordClick="$emit('wordChosen', $event)"
				:selectPart="searchWord"
			/>

			<div
				style="padding: 30px; text-align: center; padding-bottom: 10px; font-style: italic"
				v-else-if="wordsFound && wordsFound.length === 0"
			>
				{{ i18n('editCourse', 'noWordsFound') }}
			</div>

			<div
				style="padding: 30px; text-align: center; padding-bottom: 10px; font-style: italic"
				v-else
			>
				<span v-html="i18n('editCourse', 'searchDescription')" />
			</div>
		</div>
		<div class="modal-content-buttons">
			<Button
				design="ghost"
				style="width: 200px"
				@click="$emit('close')"
				>{{ i18n('editCourse', 'cancelButtonLabel') }}</Button
			>
		</div>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import TextField from '@/components/TextField';
import Spinner from '@/components/Spinner';
import WordsList from '@/components/WordsList';

import { searchWords } from '@/lib/api';
import { debounceAfter } from '@/lib/utils';

const searchWordsDebounced = debounceAfter(searchWords, 500);

export default {
	name: 'WordCommentsChooseWord',

	inject: ['i18n'],

	data() {
		return {
			searchWord: '',
			loading: false,
			wordsFound: null,
		};
	},

	mounted() {
		this.$nextTick(() => {
			this.$refs.searchField?.focus();
		});

		window.addEventListener('focus', this.onWindowFocus);
	},

	unmounted: function() {
		window.removeEventListener('focus', this.onWindowFocus);
	},

	methods: {
		onWindowFocus: function() {
			this.$nextTick(() => this.$refs.searchField?.focus());
		}
	},

	watch: {
		searchWord: async function(newVal) {
			if(newVal === ''){
				this.wordsFound = null;
				return;
			}

			this.wordsFound = null;
			this.loading = true;
			try {
				const data = await searchWordsDebounced(newVal);
				// когда запрос завершён, уже всё могло измениться
				if (this.searchWord === newVal) {
					this.wordsFound = data;
				}
			} catch (e) {
				if (this.searchWord === newVal) {
					this.wordsFound = [];
				}
			} finally {
				this.loading = false;
			}
		}
	},

	components: {
		Button,
		TextField,
		Spinner,
		Modal,
		WordsList,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

.modal-content
	padding 30px
	padding-bottom 0 !important
	.top p
		margin-bottom 10px

.modal-content-buttons
	padding-bottom 20px
	padding-top 20px !important
	text-align center

</style>
