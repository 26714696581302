<template>
	<div id="youglish-widget-container">
		<div
			v-for="(widget, wordKey) in widgets"
			:key="wordKey"
			:id="wordKey"
		/>
		<!-- making sure their visibility is updated -->
		{{ styles }}
	</div>
</template>

<script>
const ERROR_YOUGLISH_NOT_LOADED = 'Youglish Widget is not loaded';
const ERROR_NO_VIDEOS_FOUND = 'No videos found';
const ERROR_YOUGLISH_ERROR = 'Youglish error';
export default {
	name: 'YouglishWidget',
	props: {
	},
	
	data: () => ({
		maximumReplays: 2,
		replayDelay: 500,

		state: 'play',
		activeWidget: null,
		widgets: {},
	}),

	computed: {
		styles: function() {
			let style = document.querySelector('#youglish-container-styles');
			if (!style) {
				style = document.createElement('style');
				style.id = 'youglish-container-styles';
				document.head.appendChild(style);
			}
			style.textContent = Object.keys(this.widgets).map(key => {
				return `#youglish-widget-container #${key} {display: ${this.activeWidget === this.widgets[key] ? 'block' : 'none'}}`
			}).join('\n');
			return null;
		}
	},

	mounted: function () {
		const YG = window.YG;
		if (!YG) {
			console.error('Youglish Widget isnt loaded');
			this.$emit('error', { reason: ERROR_YOUGLISH_NOT_LOADED });
			return;
		}
	},

	methods: {
		// public:
		searchAndPlay(query, language) {
			this.state = 'play';
			this.getWidget(query, language);
		},

		pause() {
			if (this.activeWidget) {
				this.activeWidget.widget.pause();
				this.state = 'pause';
			}
		},

		getWidget(query, language) {
			if (this.activeWidget && this.activeWidget.widget) {
				this.activeWidget.widget.pause();
			}

			const key = query + '_' + language;

			if (this.widgets[key]) {
				this.activeWidget = this.widgets[key];
				this.activeWidget.widget.next();
				// this.activeWidget.play();
				return this.activeWidget;
			}

			const widget = {
				views: 0,
				totalTracks: 0,
				currentTrack: 0,
			};

			this.$nextTick(() => this.$nextTick(() => {
				widget.widget = new YG.Widget(key, {
					width: 640,
					components: 2,
					backgroundColor: '#f4f4f4',
					events: {
						'onFetchDone': this.onFetchDoneCallback(key),
						'onVideoChange': this.onVideoChangeCallback(key),
						'onCaptionConsumed': this.onCaptionConsumedCallback(key),
						'onError': () => {
							this.$emit('error', { reason: ERROR_YOUGLISH_ERROR });
						}
					}
				});
				
				widget.widget.fetch(query, language);
			}));

			this.activeWidget = widget;

			this.$set(this.widgets, key, widget);
			return widget;
		},

		onFetchDoneCallback: function(key) {
			return event => {
				if (event.totalTracks === 0) {
					this.$emit('error', { reason: ERROR_NO_VIDEOS_FOUND });
					return;
				}
				this.widgets[key].totalTracks = event.totalTracks;
			};
		},

		onVideoChangeCallback: function(key) {
			return event => {
				const widget = this.widgets[key];
				widget.currentTrack = event.trackNumber;
				widget.views = 0;
			};
		},

		onCaptionConsumedCallback: function(key) {
			return event => {
				this.$emit('captionConsumed');
				setTimeout(() => {
					if (this.state === 'pause') {
						return;
					}

					const widget = this.widgets[key];
					if (widget !== this.activeWidget) {
						return;
					}

					if (++widget.views > this.maximumReplays) {
						widget.widget.next();
					} else {
						widget.widget.replay();
					}
				}, this.replayDelay);
			};
		},
	},
};
</script>
