<template>
	<div
		:class="['switcher', 'design_' + design]"
	>
		<div
			v-for="item in options"
			:key="item.value"
			:class="[
				'item',
				value === item.value ? 'active' : 'non-active'
			]"
			@click="$emit('input', item.value)"
		>
			<div class="real-text">{{item.title}}</div>
			<div class="text-placeholder">{{item.title}}</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Switcher',
	props: {
		options: {
			required: true
		},
		value: String,
		design: {
			validator: val =>
				['default', 'buttons', 'buttons-elevated'].includes(val),
			default: 'default',
		},
	},
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.switcher
	display inline-block

.item
	display inline-block
	cursor pointer
	text-align center // when font-weight changes, text doesn't shift right
	.real-text
		position absolute
	.text-placeholder
		opacity 0
		font-weight 600

.switcher.design_default
	box-shadow inset 0 -1px stroke
	user-select none
	.item
		color text-secondary
		font-size 14px
		line-height 20px
		padding 8px 4px
		position relative
		&::after
			content ''
			position absolute
			left 0
			bottom 0
			width 100%
			height 2px
			background transparent
			border-radius 2px
	.item.active
		color text-primary
		.real-text
			font-weight 500
		&::after
			background #5FAA44
	.item + .item
		margin-left 20px

.switcher.design_buttons, .switcher.design_buttons-elevated
	.item
		background #F6F6F6
		color text-primary
		line-height 20px
		font-size 14px
		font-weight 400
		padding 6px 14px
		border solid 2px #F6F6F6
		border-radius 8px
		position relative
	.item.active
		background white
		border-color main-color
		.real-text
			font-weight 500
	.item + .item
		margin-left 12px

.switcher.design_buttons-elevated .item
	background surface-elevation
</style>
