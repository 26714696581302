var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'course-block',
		`design_${_vm.design}`,
		_vm.avatarUrl ? 'with-avatar' : 'without-avatar',
		'v2',
	],on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.mode === 'author')?_c('div',{staticClass:"corner-icon",on:{"click":function($event){_vm.$emit('edit');
			$event.stopPropagation();}}},[_c('img',{attrs:{"src":_vm.icons.edit}})]):(_vm.mode === 'user')?_c('div',{staticClass:"corner-icon",on:{"click":function($event){_vm.$emit('show');
			$event.stopPropagation();}}},[_c('img',{attrs:{"src":_vm.icons.show}})]):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"slot-content"},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"words-count"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }