<template>
	<Page
		mode="with-sidebar"
		selectedItem="home"
		:pageName="i18n('menu', 'home')"
		:loading="myCoursesStatus === 'loading' && allCoursesStatus === 'loading'"
	>
		<PageSection :title="i18n('courseList', 'continueLearning')">
			<template #title>
				<Badge v-if="myCoursesStatus === 'success'" style="margin-left: 8px">{{myCoursesData.length}}</Badge>
			</template>
			<div class="courses linear">
				<div v-if="myCoursesStatus === 'loading'" class="spinner-container"><Spinner size="medium" /></div>
				<div v-else-if="myCoursesStatus === 'error'" class="error-container">
					<p>{{ myCoursesError }}</p>
					<Button design="ghost-action" @click="loadMyCourses()">{{ i18n('errors', 'retryButtonText') }}</Button>
				</div>
				<div v-else class="courses-list">
					<!-- todo: button for "create a collection of my words"-->
					<CourseBlock
						v-for="course in myCoursesData"
						:key="course.id"
						:title="course.title === '{{my_words}}' ? i18n('courseList', 'myWords') : course.title"
						:avatar="course.avatar"
						:mode="course.authorId === userId ? 'author' : 'user'"
						:subtitle="course.description/*i18n('courseList', 'courseSubtitle')(
							course.wordsCount,
							course.unknownWordsCount,
							+course.forgotCount,
							(+course.wordsCount) - (+course.learnedCount)
						) */"
						@click="goToTraining(course)"
						@edit="editCourse(course)"
						@show="showCourse(course)"
					>
						<div class="course-block-progress-bar">
							<CourseProgressBar
								:fullyLearned="Number(course.learnedCount - course.forgotCount)"
								:totalLearned="Number(course.learnedCount)"
								:total="Number(course.wordsCount)"
							/>
						</div>
					</CourseBlock>
				</div>
			</div>
		</PageSection>

		<PageSection :title="i18n('courseList', 'discoverCourses')">
			<template #sub>
				<div class="courses-filters">
					<div>
						<Selectbox
							:options="levelSwitcher.options"
							v-model="level"
						/>
						<div class="separator" />
						<Switcher
							:options="topicSwitcher.options"
							v-model="topic"
							design="buttons-elevated"
						/>
					</div>
					<div class="community-courses-switch">
						<span @click="communityCoursesOn = !communityCoursesOn">
							{{ i18n('courseList', 'showCommunityCourses') }}
						</span>
						<ToggleSwitch v-model="communityCoursesOn" size="small" @click.stop />
					</div>
				</div>
			</template>
			<div class="courses">
				<div v-if="allCoursesStatus === 'loading'" class="spinner-container" style="margin-top: 60px"><Spinner size="medium" /></div>
				<div v-else-if="allCoursesStatus === 'error'" class="error-container">
					<p>{{ allCoursesError }}</p>
					<Button design="ghost-action" @click="loadAllCourses([topic, level, communityCoursesOn])">{{ i18n('errors', 'retryButtonText') }}</Button>
				</div>
				<div v-else class="courses-list">
					<CourseBlock
						v-for="course in allCoursesData"
						:key="course.id"
						:title="course.title === '{{my_words}}' ? i18n('courseList', 'myWords') : course.title"
						:avatar="course.avatar"
						:mode="course.authorId === userId ? 'author' : 'user'"
						:subtitle="course.description /* i18n('courseList', 'courseSubtitle')(course.wordsCount, course.unknownWordsCount, +course.forgotCount, (+course.wordsCount) - (+course.learnedCount)) */"
						@click="goToTraining(course)"
						@edit="editCourse(course)"
						@show="showCourse(course)"
					/>
				</div>
			</div>
		</PageSection>

		<!-- Modals -->
		<ModalEditCourse
			v-if="$route.query.edit"
			:courseId="Number($route.query.edit)"
			@closeSuccess="$router.push({ path: $route.path, query: { update: true } })"
			@close="$router.push({ path: $route.path, query: { update: $route.query.update === 'true' } })"
		/>
		<ModalShowCourse
			v-if="$route.query.show"
			:courseId="Number($route.query.show)"
			@close="$router.push({ path: $route.path, query: {} })"
		/>
	</Page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Page from '@/components/Page';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Switcher from '@/components/Switcher';
import Spinner from '@/components/Spinner';
import PageSection from '@/components/PageSection';
import ToggleSwitch from '@/components/ToggleSwitch';
import Selectbox from '@/components/Selectbox';

import {
	ModalShowCourse,
	ModalEditCourse,
	CourseBlock,
} from '@/views/course';
import CourseProgressBar from '@/components/CourseProgressBar';

import { addCourseToFavorite } from '@/lib/api';

const DEFAULT_CATEGORY = 'mine';

export default {
	name: 'CourseListAllPage',

	data: function() {
		const i18n = this.i18n;
		return {
			editCourseUpdated: false,
			topic: 'all',
			level: -1,
			communityCoursesOn: true,

			levelSwitcher: {
				options: [{
					value: -1,
					title: i18n('courseList', 'courseLevelsAll')
				}, {
					value: 1,
					title: i18n('courseList', 'courseLevel1')
				}, {
					value: 3,
					title: i18n('courseList', 'courseLevel3')
				}, {
					value: 5,
					title: i18n('courseList', 'courseLevel5')
				}]
			},

			categorySwitcher: {
				options: [
					{
						value: 'favorite',
						title: i18n('courseList', 'favorite'),
					},
					{
						value: 'mine',
						title: i18n('courseList', 'mine'),
					},
					{
						value: 'all',
						title: i18n('courseList', 'all'),
					},
				],
			},

			topicSwitcher: {
				options: [
					{
						value: 'all',
						title: i18n('courseList', 'categoryAll'),
					},
					{
						value: 'topic',
						title: i18n('courseList', 'categoryTopic'),
						description: i18n('courseList', 'categoryTopicDescription'),
					},
					{
						value: 'content',
						title: i18n('courseList', 'categoryContent'),
						description: i18n('courseList', 'categoryContentDescription'),
					},
					{
						value: 'compare',
						title: i18n('courseList', 'categoryCompare'),
						description: i18n('courseList', 'categoryCompareDescription'),
					},
				],
			},

			markup: {
				leftBlockBorder: 0,
			},
		};
	},

	inject: ['i18n'],

	computed: {
		...mapState('courseListAll', {
			allCoursesStatus: state => state.status,
			allCoursesData: state => state.data,
			allCoursesError: state => state.error,
		}),

		...mapState('courseListMine', {
			myCoursesStatus: state => state.status,
			myCoursesData: state => state.data,
			myCoursesError: state => state.error,
		}),

		category: function() {
			const routeCategory = this.$route.params.category;
			const listNamesAvailable = {
				favorite: 'favorite',
				mine: 'mine',
				all: 'all',
			};
			return (
				listNamesAvailable[routeCategory] ||
				localStorage.getItem('mainPage') ||
				DEFAULT_CATEGORY
			);
		},

		userId: function() {
			return this.$store.state.user.user.id;
		},

		// слегка костыль: мы ждём, пока update будет true, и при
		// этом не будет открыто окно редактирования
		needToUpdate: function() {
			return (
				this.$route.query.update === 'true' &&
				this.$route.query.edit === undefined
			);
		},
	},

	mounted: function() {
		this.loadAllCourses(['all', this.level, this.communityCoursesOn]);
		this.loadMyCourses();

		this.calculateMarkup();
		window.addEventListener('resize', this.calculateMarkup);
		window.addEventListener('deviceorientation', this.calculateMarkup);
	},

	destroyed: function() {
		window.removeEventListener('resize', this.calculateMarkup);
		window.removeEventListener('deviceorientation', this.calculateMarkup);
	},

	watch: {
		topic: function(newVal) {
			this.loadAllCourses([newVal, this.level, this.communityCoursesOn]);
		},
		level: function(newVal) {
			this.loadAllCourses([this.topic, newVal, this.communityCoursesOn]);
		},
		communityCoursesOn: function(newVal) {
			this.loadAllCourses([this.topic, this.level, newVal]);
		},

		needToUpdate: function(val) {
			if (val) {
				this.loadAllCourses([this.topic, this.level, this.communityCoursesOn]);
				this.loadMyCourses();
			}
			this.$router.replace({
				path: this.$route.path,
				query: {},
			});
		},

		data: function() {
			this.calculateMarkup();
		},
	},

	methods: {
		...mapActions('courseListAll', {
			loadAllCourses: 'load',
		}),

		...mapActions('courseListMine', {
			loadMyCourses: 'load',
		}),

		goToTraining: function(course) {
			if (!course.favorite) {
				addCourseToFavorite(course.id, true);
			}
			this.$router.push(`/course/${course.id}/training`);
		},

		editCourse: function(course) {
			this.$router.push({ path: this.$route.path, query: { edit: course.id } });
		},

		showCourse: function(course) {
			this.$router.push({ path: this.$route.path, query: { show: course.id } });
		},

		switchCategory(category) {
			this.$router.push(`/${category}`);
		},

		switchAllCoursesCategory(category) {
			this.$router.push(category === 'all' ? '/all' : `/all/${category}`);
		},

		calculateMarkup() {
			this.$nextTick(() => {
				const list = this.$refs.list;
				const block = list && list.querySelectorAll('.course-block')[0];
				if (block) {
					this.markup.leftBlockBorder = block.getBoundingClientRect().left;
				}
			});
		},
	},

	components: {
		CourseBlock,
		CourseProgressBar,
		Switcher,
		Spinner,
		PageSection,
		ToggleSwitch,
		Selectbox,

		Page,
		Badge,
		Button,
		ModalEditCourse,
		ModalShowCourse,
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

@media only screen and (min-width: 445px) {
	.switcher-container .switcher {
		margin-left: -100px
	}
}

.spinner-container
	text-align center
	padding-top 20px

.error-container
	text-align center
	font-size 18px
	padding-top 20px
	color error-red

	.button
		width 150px
		margin-top 20px

.course-block-progress-bar
	padding 20px 24px

oneColumnModeMax = 664px
twoColumnModeMax = 950px
threeColumnModeMax = 1216px
fourColumnModeMax = 91232px
fiveColumnModeMax = 91514px
sixColumnModeMax = 91720px
rowGap = 25px
columnGap = 20px

.courses-list
	display grid
	grid-template-columns auto auto auto auto auto auto
	row-gap rowGap
	column-gap columnGap
	justify-content center
	// margin-bottom 250px

.courses.linear
	overflow-x auto
	overflow-y hidden
	padding-bottom 12px
	scrollbar-color transparent transparent
	transition 0.3s scrollbar-color
	.courses-list
		display block
		white-space nowrap
		.course-block
			width 376px
			display inline-block
			box-sizing border-box
			white-space normal
		.course-block + .course-block
			margin-left 24px

.courses.linear:hover
	scrollbar-color unset

/*
.courses.linear
	overflow scroll
	.courses-list
		display block
		white-space nowrap
		.course-block
			display inline-block
			width 376px
			white-space normal
			box-sizing border-box
		.course-block + .course-block
			margin-left 24px
*/

@media only screen and (max-width: oneColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr
	}
}

@media only screen and (min-width: oneColumnModeMax) and (max-width: twoColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr
	}
}

@media only screen and (min-width: twoColumnModeMax) and (max-width: threeColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr
	}
}

@media only screen and (min-width: threeColumnModeMax) and (max-width: fourColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr 1fr
	}
}

@media only screen and (min-width: fourColumnModeMax) and (max-width: fiveColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr 1fr 1fr
	}
}

@media only screen and (min-width: fiveColumnModeMax) and (max-width: sixColumnModeMax) {
	.courses-list {
		grid-template-columns 1fr 1fr 1fr 1fr 1fr 1fr 
	}
}

// categories
.section
	margin-top 40px
	margin-left 32px
	margin-right 32px
	
	.categoryTitle
		font-size 20px
		line-height 24px
		font-weight 600

	.section-menu
		margin-top 20px
		margin-bottom 32px

	.courses
		margin-top 24px
		.loading
			text-align center

	& + &
		margin-top calc(64px - 12px) // for scrollbar

.courses-filters
	display flex
	align-items center
	justify-content space-between
	.community-courses-switch
		cursor pointer
		font-size 14px
		font-weight 400
		line-height 20px
		color #292929
		.toggle-switch
			vertical-align middle
			margin-left 12px
			margin-top -1px
	.separator
		margin-left 20px
		margin-right 20px
		display inline-block
		width 0px
		height 36px
		vertical-align middle
		border solid 2px #DCDCDC
		border-radius 2px
</style>
