<template>
	<div id="app">
		<!-- <Header /> -->

		<!-- <div id="nav">
			<router-link to="/">Home</router-link> |
			<router-link to="/about">About</router-link>
		</div> -->
		<router-view />

		<portal-target name="portal-main" />
	</div>
</template>
<script>
import Header from './components/Header';
import i18n from './lib/i18n';

export default {
	name: 'App',
	components: {
		Header,
	},
	provide: {
		i18n
	}
};
</script>

<style lang="stylus">
@import "./assets/fonts.styl"
@import "./components/style.styl"

body
	background #F4F4F4
	font-family main-font
	&.paranja-open
		overflow hidden

*
	margin 0
	padding 0
	border-collapse collapse

h1, h2, h3, h4, h5, h6
	font-family main-font

h1
	font-size 22px
</style>
