<template>
	<div class="badge">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'Badge',
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.badge
    display inline-block
    background main-color
    color white
    border-radius 8px
    font-size 16px
    height 24px
    line-height 24px
    padding 0 8px
    font-weight 400
</style>
