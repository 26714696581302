import { render, staticRenderFns } from "./WordCommentsChooseWord.vue?vue&type=template&id=4ac13da6&scoped=true"
import script from "./WordCommentsChooseWord.vue?vue&type=script&lang=js"
export * from "./WordCommentsChooseWord.vue?vue&type=script&lang=js"
import style0 from "./WordCommentsChooseWord.vue?vue&type=style&index=0&id=4ac13da6&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac13da6",
  null
  
)

export default component.exports