<template>
	<div class="menu">
        <div
            class="item"
            v-for="item in options"
            :class="{ active: active && active.includes(item.value) }"
            @click="$emit('click', item.value)"
        >
			<Icon v-if="item.icon" v-bind="item.icon" />
            {{item.title}}
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon';

export default {
	name: 'Menu',
	props: {
		options: Array,
		active: Array
	},
	components: {
		Icon,
	}
};
</script>

<style lang="stylus" scoped>
@import "./style.styl";

.menu
	padding 4px
	box-sizing border-box
	overflow auto
	transition 0.3s height
	text-align left // need to override Menu's container styles
	.item
		font-size 14px
		line-height 36px
		height 36px
		padding 0 8px
		box-sizing border-box
		cursor pointer
		border-radius 6px
	.item:hover
		background #eee
	.item .icon
		vertical-align middle
		margin-top -4px
		margin-left 4px
		margin-right 8px
</style>
