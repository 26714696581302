<template>
    <a href="/" class="logo v2" @click="onClick">
        LangoCorner
    </a>
</template>

<script>
export default {
	name: 'Logo',

	methods: {
		onClick: function($event) {
            $event.preventDefault();
            this.$router.push('/')
		},
	}
};
</script>

<style lang="stylus">
@import "./style.styl";
.logo
    line-height 55px
    color main-color
    padding-left 24px
    cursor pointer
    text-decoration none
    &:hover
        color main-color-darker
    &.v1
        font-family logo-font-v1
        font-size 24px
    &.v2
        font-family logo-font
        font-size 18px
        font-weight 700
</style>
